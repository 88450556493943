import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PaginationEvent } from 'src/app/commons/interfaces';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { PreferencesService } from 'src/app/commons/_services/preferences.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { SearchService } from 'src/app/commons/_services/search.service';

@Component({
	selector: 'results-list-gbd',
	templateUrl: './results-list.component.html',
	styleUrls: ['./results-list.component.css'],
	// encapsulation: ViewEncapsulation.None, No need, I think?
})
export class GBDResultsListComponent implements OnInit {

	@Input() docs: any[] = []

	constructor(
		public ms: MechanicsService,
		public ss: SearchService,
		public ps: PreferencesService,
		private qs: QueryParamsService) { }

	ngOnInit(): void {

	}

	onPagination(pageChangeEvent: PaginationEvent) {

		const l = `resultList onPAgination() - `

		/*
			Receiving : 
			{
				currentPage: 2,
				skip: 10
			}
	
			I need : 
				- &start=10 ( = skip)


			*/

		// console.log(`${l}pageChangeEvent = `, pageChangeEvent)

		this.qs.setQP("start", pageChangeEvent.skip)

		this.qs.queryParamsObjectToUrl()

	}


	onSelectAllChecked($event) {

		const l = `onSelectAllChecked() - `

		const isChecked: boolean = $event.target.checked

		for (let doc of this.docs) {
			doc.selected = isChecked
			this.wasSelected(doc.st13, isChecked)
		}
	}

	get isAllSelected() {
		return this.docs?.every(doc => doc.selected)
	}

	wasSelected(st13, selected): void{
		if (selected){
			this.ms.addToCache(st13)
		}
		else{
			this.ms.removeFromCache(st13)
		}
	}

	details(doc, idx) {

		const l = `resultsComponent details() - `


		// console.log(`${l}idx=`, idx)


		let st13 = doc.st13
		this.ms.addToViewedCache(st13)
		this.docs[idx].viewed = 'viewed'
		this.ss.searchResult.setCursor(idx)
		// to be changes with next/prev buttons to force page reload
		// we do not change '_' here as it is being used as a query identifier
		this.qs.setQP('i', idx)

		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `brand/${st13}`, caller: l })

		this.qs.queryParamsObjectToUrl(route)
	}

	hasLogo(result): boolean {

		const hasRegularLogo: boolean = result.logo && result.logo[0] && result.logo[0].length > 0;
		const hasDummyLogo = result.dummyLogo && result.dummyLogo.length > 0

		return hasRegularLogo || hasDummyLogo;
	}

}
