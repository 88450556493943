import { Injectable } from '@angular/core';
import { RecentSearch } from '../interfaces';
import { deepClone, instanceType } from '../utils';
import { MechanicsService } from './mechanics.service';
import { SearchService } from './search.service';

@Injectable({
	providedIn: 'root'
})
export class RecentSearchesService {

	public recentSearches: RecentSearch[] = []

	public savedSearches: RecentSearch[] = []

	constructor(private ms: MechanicsService, public ss: SearchService,) { // Cannot inject QP (QueryParamsService) here because circular dependency
		// load from storage
		this.loadRecentSearches()
	}

	countRecentSearches() {
		return Object.keys(this.recentSearches).length
	}

	countSavedSearches() {
		return Object.keys(this.savedSearches).length
	}

	// save to local storage
	saveRecentSearches() {
		try{
			localStorage.setItem(`${instanceType()}.recentSearches`, JSON.stringify(this.recentSearches))
		}
		catch(err){
			console.log(err)
		}
	}

	// load from local storage
	async loadSavedSearches() {

		const l: string = `SearchService loadSavedSearches() - `
		this.savedSearches = []

		let res: any[] = await this.ss.getPersist('searches')

		// console.log(`${l}res = `, res)

		if(!res){
			// console.log(`${l}No saved searches to load.`)
			return
		}
		for(let i = 0; i < res.length; i++){

			let item = res[i]['payload'];

			if (item){
				item['name'] = res[i]['name']
				item['date'] = this.ms.dateFormatterHuman.format(new Date(res[i]['date']))
				this.savedSearches.push(item)
			}
		}
	}
	


	// load from local storage
	loadRecentSearches() {
		const l: string = `SearchService loadRecentSearches() - `

		const recentSearches: string = localStorage.getItem(`${instanceType()}.recentSearches`)

		if (!recentSearches) return;

		try {
			this.recentSearches = JSON.parse(recentSearches)

			if (!Array.isArray(this.recentSearches)) this.recentSearches = []
		}
		catch (err) {
			console.warn(`${l}Could not JSON.parse recent searches!`)
		}
	}

	addRecentSearch(searchObj: any, endpoint: string = this.ms.endpoint) {
		if(this.ms.isMobileView)
			return
		const l = `ss.addRecentSearch()`
		
		// let's not store explore in recent searches
		if (endpoint === 'explore') return
		// let's not store similar logo with image information is recent searches

		// similarity with an image => do not store in recent searches
		if (endpoint === 'similarlogo' || endpoint === 'advancedsearch') {
			searchObj['bases64'] = this.ms.bases64
			searchObj['strategy'] = searchObj.strategy
		}

		if (this.ms.officeCC) {
			searchObj['office'] = this.ms.officeCC
		}

		// console.log(`${l}48 this.recentSearches=`, deepClone(this.recentSearches))

		searchObj = deepClone(searchObj)

		searchObj.endpoint = endpoint

		// ignore page details index param
		delete searchObj['i']
		// ignore pagination param if any
		delete searchObj['start']
		// ignore rows param if any (keep using the one in prefs)
		delete searchObj['rows']
		// ignore sort param if any (keep using the one in prefs)
		delete searchObj['sort']

		let uid = this._serializeSearchObj(searchObj).substring(0,50); // uid = "quicksearch.bynumberv123456789"

		// upsert the recent search. Need to know if there's already one with the same uid
		let existing = this.recentSearches.find(rssObj => rssObj.uid === uid)
		let existing_save = this.savedSearches.find(rssObj => rssObj.uid === uid)

		if (existing) {

			existing._ = searchObj._
			existing.searchObj = searchObj

		} else {
			if(!existing_save){
				this.recentSearches.push({
					uid,
					_: searchObj._,
					searchObj
				})
			}
		}

		this.recentSearches.sort((a, b) => a._ < b._ ? 1 : -1);

		if (this.recentSearches.length > 10) this.recentSearches.pop()

		// console.log(`${l}85 this.recentSearches=`, deepClone(this.recentSearches))

		this.saveRecentSearches()
	}

	buildUid(searchObj: any, endpoint: string = this.ms.endpoint) {

		const l = `ss.addRecentSearch()`

		// let's not store explore in recent searches
		if (endpoint === 'explore') return null;
		// let's not store similar logo with image information is recent searches

		// similarity with an image => do not store in recent searches
		if (endpoint === 'similarlogo' && !!searchObj.strategy) {
			return null;
		}

		if (this.ms.officeCC) {
			searchObj['office'] = this.ms.officeCC
		}

		// console.log(`${l}48 this.recentSearches=`, deepClone(this.recentSearches))

		searchObj = deepClone(searchObj)

		searchObj.endpoint = endpoint

		// ignore page details index param
		delete searchObj['i']
		// ignore pagination param if any
		delete searchObj['start']
		// ignore rows param if any (keep using the one in prefs)
		delete searchObj['rows']
		// ignore sort param if any (keep using the one in prefs)
		delete searchObj['sort']

		let uid = this._serializeSearchObj(searchObj) // uid = "quicksearch.bynumberv123456789"
		return uid
	}

	async save(uid: string, searchName: string) {
		let existing = this.recentSearches.findIndex(rssObj => rssObj.uid === uid)
		let body = {
			payload: this.recentSearches[existing],
			name: searchName
		}
		let res:string = await this.ss.postPersist('searches', body)
		this.loadSavedSearches()
		this.removeRecentSearch(uid)
		// console.log(`rss.save = `, existing, searchName, res)
	}


	async removeSavedSearch(uid:string){
		let body = {
			type: 'searches',
			name: uid
		}
		let res:string = await this.ss.deletePersist(body)
		this.loadSavedSearches()
	}
	removeRecentSearch(uid: string) {
		let existing = this.recentSearches.findIndex(rssObj => rssObj.uid === uid)

		if (existing > -1) {
			this.recentSearches.splice(existing, 1)
		}

		this.saveRecentSearches()
	}

	// this method will serialize a searchObj to its unique identifier
	// which is determined by the endpoint and its search form fields =>
	//   faceting will update the same recent search
	//   sorting will update the same recent search
	//   pagination will have no effect
	_serializeSearchObj(searchObj: any): string {

		// start by endpoint
		let uid = `${searchObj.endpoint}.`

		Object.keys(searchObj)
			.filter(key => !["sort", "rows", "i", "_", "endpoint"].includes(key) && !/^fc/.test(key))
			.sort()
			.forEach(function (key) {
				// values of free input text
				if (typeof searchObj[key] === 'string') {
					uid += key + searchObj[key].toLowerCase().trim()
				}
				// values of multi-select
				else {
					uid += key
					for (let i in searchObj[key]) {
						uid += searchObj[key][i]
					}
				}
			});

		return uid
	}

}
