import { Component, OnInit } from '@angular/core';
import { ResultsHolderComponent } from '../../components/comp-results-holder/results-holder.component';

@Component({
	selector: 'page-results',
	templateUrl: './page-results.component.html',
	styleUrls: ['./page-results.component.css']
})
export class PageResultsComponent implements OnInit {
	contentComponent: any;

	constructor() { }

	ngOnInit() {
		this.contentComponent = ResultsHolderComponent;
	}


}

