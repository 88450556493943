import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';

@Component({
	selector: 'doc-field',
	templateUrl: './doc-field.component.html',
	styleUrls: ['./doc-field.component.css']

})
export class FieldComponent {

	@Input() field: any;

	public isExpanded: boolean = false; // Togglable ellipsis in case of long text

	constructor(public ms: MechanicsService) { }

	get hasEllipsis() {
		return this.field.content && this.field.content.length > 500
	}

}
