import { formatDate } from '@angular/common';
import { Component, OnInit, SimpleChanges, Input, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { WOption } from '@wipo/w-angular/shared';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { lastValueFrom, Observable, of, delay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';
import { PreferencesService } from 'src/app/commons/_services/preferences.service';
import { environment } from 'src/environments/environment';
import { instanceType } from '../../utils';

@Component({
	selector: 'chatbot',
	templateUrl: './chatbot.component.html',
	styleUrls: ['./chatbot.component.css',
	]
})


export class ChatbotComponent implements OnInit {
	@ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef;

	public isOpen = false;
	public isFileUploaderOpen = '';
	public isLoading = false;
	private session_id = '';
	private model = '';
	private chatSocket = null;
	public popup_report = false;
	private initial_pos = 'left'

	public asStructure: any // AdvancedSearch object, in case of an Advanced Search
	public localhistory = []
	public current :string = ""

	private convert_initial_pos(){
		if(this.initial_pos == 'right')
			this.initial_pos = 'left'
		else
			this.initial_pos = 'right'
		localStorage.setItem(`${instanceType()}.chatModelPos`, this.initial_pos)
		

	}

	public async report(outcome){
		this.popup_report = true;
		const showBody = () => {
			this.popup_report = false;
		}	
		setTimeout(showBody, 4000)

		const route = `${environment[instanceType()].backendUrl || ""}/feedback/`;

		let body = {
			payload: this.localhistory,
			outcome: outcome
		}
		let response // :SolrResponse
		try {
			response = await lastValueFrom(this.http.post(route, body))
		}
		catch (err) {
			// console.log(`${l}Caught error = `, err);
			console.log(`Caught error = `, err);
		}

		// console.log(`${l}Got postPersits: `, response)

	}

	public show_modal_call(){
		if(this.isFileUploaderOpen == '')
			this.isFileUploaderOpen = 'show-modal'
	}

	public hide_modal_call(){
		if(this.isFileUploaderOpen == 'show-modal')
			this.isFileUploaderOpen = ''
		if (this.ms.bases64.length == 0){
			let idx_to_remove = []
			for( let i =0; i< this.localhistory.length; i++){
				if(this.localhistory[i].system){
					idx_to_remove.push(i)
				}
			}
			idx_to_remove.forEach( (element) => {
				this.localhistory = this.localhistory.slice(0, element).concat(this.localhistory.slice(element + 2))
			});
			this.qs.queryParams.advancedsearch.sort = "score desc"
		}
	}

	public img_add_to_search(){
		var date = new Date;
		var minutes = date.getMinutes();
		var hour = date.getHours();
		let idx_to_remove = []
		for( let i =0; i< this.localhistory.length; i++){
			if(this.localhistory[i].system){
				idx_to_remove.push(i)
			}
		}
		idx_to_remove.forEach( (element) => {
			this.localhistory = this.localhistory.slice(0, element).concat(this.localhistory.slice(element + 2))
		});
		
		this.localhistory.push({
			'system': "This following image will be used in all future searches until you remove or change it:",
			'time': `${hour}:${minutes}`,
			'class': 'chat-box-body-receive'
		})
		this.localhistory.push({
			'img': true,
			'time': `${hour}:${minutes}`,
			'class': 'chat-box-body-send'
		})
		this.qs.queryParams.advancedsearch.sort = "image_similarity"
		this.isFileUploaderOpen = ''
	}

	private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;
		try{
			for (let i: number = 0; i < caLen; i += 1) {
				c = ca[i].replace(/^\s+/g, '');
				if (c.indexOf(cookieName) == 0) {
					return c.substring(cookieName.length, c.length);
				}
			}
		}
		catch(err){
			console.log(err)
		}
		let gbd = localStorage.getItem(`${instanceType()}.session`)
		if (!gbd){
			localStorage.setItme(`${instanceType()}.session`,uuidv4() )
			gbd = localStorage.getItem(`${instanceType()}.session`)
		}
        return gbd;
    }


	

	constructor(public ms: MechanicsService,
		public qs: QueryParamsService,
		public ps: PreferencesService,
		public http: HttpClient,
		private elem: ElementRef) {

		if (localStorage.getItem(`${instanceType()}.chatModelPos`))
			this.initial_pos = localStorage.getItem(`${instanceType()}.chatModelPos`)
		this.manage_socket();
	}

	post_processing(input:string){
		return input
		
	}
	async manage_socket(){
		console.log("+++++++++++++++++++++++ Socket connecting")
		let _tmp = this

		try{
			this.chatSocket = new WebSocket('wss://' + localStorage.getItem(`${instanceType()}.chatAdr`) + '/ws/invoke/');
		} 
		catch(err){
			console.log("+++++++++++++++++++++++ Socket connection error")
			this.chatSocket = null
			return
		}

		this.chatSocket.onclose = function(e) {
			console.log("+++++++++++++++++++++++ Chat socket closed unexpectedly");
			_tmp.chatSocket = null
		};

		this.chatSocket.onmessage = function(e) {

			const l: string = `Bot search() - `;
			const resp = JSON.parse(e.data);

			var date = new Date;
			var minutes = ('0' + date.getMinutes()).slice(-2)
			var hour = ('0' +date.getHours()).slice(-2)
			
			if (resp.text) {
				_tmp.isLoading = false
				if (resp.place_holder){
					let new_element = true
					_tmp.localhistory.forEach( (element) => {
						if(element.place_holder == resp.place_holder){
							element.payload = _tmp.post_processing(element.payload + resp.text)
							element.time = `${hour}:${minutes}`
							new_element = false
						}
					});
					if (new_element){
						_tmp.localhistory.push({
							'suggestion': resp.suggestion,
							'payload': _tmp.post_processing(resp.text),
							'time': `${hour}:${minutes}`,
							'place_holder': resp.place_holder,
							'class': 'chat-box-body-receive'
						})
					}
					
				}
				else{
					_tmp.localhistory.push({
						'payload': _tmp.post_processing(resp.text),
						'time': `${hour}:${minutes}`,
						'class': 'chat-box-body-receive'
					})
				}
				_tmp.goToLastChat()
			}
			
			if(resp.output) {
				_tmp.ms.setEndpoint('advancedsearch')
				_tmp.qs.setQP("_", Date.now());
				_tmp.qs.setQP("asStructure", JSON.stringify(resp.output)) 
				_tmp.qs.setQP("fg", "_void_")

				const route: string = _tmp.ms.makeRoute({ path: 'advancedsearch', subpath: 'results', caller: l })
				_tmp.qs.queryParamsObjectToUrl(route, true)
			}
		};

		const greet = () => {
			this.invokeModel("Hello")		
		}	

		if(this.localhistory.length == 0){
			setTimeout(greet, 1000)

		}
	}

	do_suggestion(action){
		console.log(action)
		let element = this.localhistory[this.localhistory.length-1]
		if(action == 'yes'){
			this.current = 'Yes'
			this.sendToChat(element.payload)
		}
		else{
			this.localhistory = this.localhistory.slice(0, this.localhistory.length-1)
		}
	}

	parse_history(local_history){
		let to_return = []
		local_history.forEach( (element) => {
			let type = 'HumanMessage'
			if(element.class == 'chat-box-body-receive'){
				type = 'AIMessage'
			}
			if(element.payload){
				to_return.push({
					type: type,
					message: element.payload
				})
			}
		});

		return to_return
	}

	// Send message to server
	async invokeModel(qustion:string) {
		console.log("+++++++++++++++++++++++ Sending data");
		console.log(this.chatSocket)
		if(this.chatSocket == null){
			console.log("+++++++++++++++++++++++ Socket is null");
			await this.manage_socket()
			await new Promise(r => setTimeout(r, 1000));
			console.log(this.chatSocket)
			this.chatSocket.send(JSON.stringify({
				'uid': this.session_id,
				'model': this.model,
				'history': this.parse_history(this.localhistory)
			}
			));
		}
		this.chatSocket.send(JSON.stringify({
			'uid': this.session_id,
			'model': this.model,
			'input': qustion,
			'sort': this.ps.getPref("sort", 'advancedsearch'),
			'base64': this.ms.bases64[0]
		}
		));
	}

	ngOnInit() {
		this.session_id = 'localhost-testing'
		try{
			this.session_id = this.getCookie('_pk_uid')
		}
		catch(error){
			this.session_id = 'localhost-testing'
		}
		this.model = localStorage.getItem(`${instanceType()}.chatModel`) || 'Claude3Sonnet'
	}

	async goToLastChat(){
		await new Promise(r => setTimeout(r, 10));
		this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;

	}

		
	async sendToChat(predined_payload) {
		
		if (this.current == "")
			return
		var date = new Date;
		var minutes = ('0' + date.getMinutes()).slice(-2)
		var hour = ('0' +date.getHours()).slice(-2)
		this.localhistory.push({
			'payload': this.current,
			'time': `${hour}:${minutes}`,
			'class': 'chat-box-body-send'
		})
		this.goToLastChat()
		this.isLoading = true
		let tmp = predined_payload !== undefined ? predined_payload : this.current
		this.current = ""
		this.invokeModel(tmp)		
		setTimeout(() => (this.isLoading=false), 20000)
	}

}
