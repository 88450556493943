import { Component, Input, ViewEncapsulation , SimpleChanges, OnChanges} from '@angular/core';
import { MechanicsService } from '../../_services/mechanics.service';

@Component({
	selector: 'address-list',
	templateUrl: './address-list.component.html',
	styleUrls: ['./address-list.component.css']

})
export class AddressListComponent implements OnChanges{

	@Input() addresses: any;
	@Input() inid: any;
	inidLabel: string;

	public isExpanded: boolean = false; // Togglable ellipsis in case of long text

	constructor(public ms: MechanicsService) { }

	get hasEllipsis() {
		return false
		// return this.field.content && this.field.content.length > 500
	}
	ngOnChanges(changes: SimpleChanges) {
		const l = `facetsSearchComponent ngOnChanges -`
		if (Object.keys(changes.inid)) {
			this.inidLabel = this.ms.translate(`inids.${changes.inid.currentValue}`)
		}
	}
}

