import { Injectable } from '@angular/core';
import { AddressLangTextField, DClassWrapper, Entity, GDDDocumentDesign, LangTextField, PIClass,  }from '../../commons/interfaces';
import { MechanicsService } from '../../commons/_services/mechanics.service';
import { SearchService } from '../../commons/_services/search.service';

const defaultMapping: any = {
	'productIndiction': { inid: '54', handler: 'langtext', multi: true },
	'designDescription':{ inid: '57', handler: 'langtext', multi: true },
	'registrationOfficeCode': {inid: '19', handler: 'text'},
	'collection': {code: 'coll', handler: 'text'},

	'designGrouping': {'inid': '28', handler: 'text'},
	'subtype': { inid: '27', handler: 'text' },
	'applicationNumber': { inid: '21', handler: 'text' },
	'registrationNumber': { inid: '11', handler: 'text' },
	'kind': { code: 'kind', handler: 'text' },
	'filingPlace': { inid: '23', handler: 'text' },
	'designatedCountries': { code: 'designation', handler: 'listcountries' },
	'officeStatus': { code: 'office_status', handler: 'text' },

	'statusDate': { code: 'status_date', handler: 'date' },
	'applicationDate': { inid: '22', handler: 'date' },
	'effectiveDate': { inid: '24', handler: 'date' },
	'registrationDate': { inid: '15', handler: 'date' },
	'expiryDate': { inid: '18', handler: 'date' },
	'publicationDate': { inid: '45', handler: 'date' },


	// complex type mapping
	'applicants': { handler: 'applicants' },
	//'designer': { handler: 'designer' },
	'representatives': { handler: 'representatives' },
	'priorities': { handler: 'priorities' },
	'reference': { handler: 'reference' },
	'designs': { handler: 'designs' ,multi: true},
	// 'locarnoClasses': {inid: '52', handler: 'locarnoClasses'},
	// 'nationalClasses': {inid: '53', handler: 'nationalClasses'}

}

const designMapping: any = {
	'productIndication': { inid: '54', handler: 'langtext', multi: true },
	'designDescription':{ inid: '57', handler: 'langtext', multi: true },
	'designatedCountries': { code: 'designation', handler: 'listcountries' },
	'officeStatus': { code: 'office_status', handler: 'text' },
	'gddStatus': { code: 'gdd_status', handler: 'text' },
	'logos': { code: 'logos', handler: 'text' , multi: true},
	'statusDate': { code: 'status_date', handler: 'date' },
	'applicationDate': { inid: '22', handler: 'date' },
	'effectiveDate': { inid: '24', handler: 'date' },
	'registrationDate': { inid: '15', handler: 'date' },
	'expiryDate': { inid: '18', handler: 'date' },
	'noveltyStatement':{ code: 'novelty', handler: 'langtext', multi: true },
	
	// complex type mapping
	'publications': { inid: '45', handler: 'publications' },
	'designer': { handler: 'designer' , multi: true},
	'priorities': { handler: 'priorities' },
	'reference': { handler: 'reference' },
	'productIndicationClasses': {inid: ['52','53'], handler: 'designClasses'}
}



@Injectable({
	providedIn: 'root'
})
export class GDDDoc2fieldsService {

	public doc: any;
	public fields: any;

	classificationsCache = {};

	constructor(public ms: MechanicsService,
		public ss: SearchService) {

	}

	buildFields(doc: any = this.doc): void {// Not sure what the actual type is

		const l = `pageDetails buildFields() - `

		if (!doc) {
			// console.log(`${l}No doc (yet?)`); // The "change language" event triggers too early, as the doc isn't loaded yet. I'm just skipping while there's no doc yet
			return
		}

		this.doc = doc;

		this.fields = this._toFields(doc, defaultMapping); // Recursive method that builds this.fields, which is then accessible in templates or as public JS object
		this.addExtraFieldsRoot(this.fields);

		// console.log(`${l}end - this.fields=`, this.fields)
	}


	addExtraFieldsRoot(fields: {}){
		fields["classesLocarno"] = this.get_class_nums(true);
		fields["classesNational"] = this.get_class_nums(false);
		
	}

	// One Entity to fields
	_toFields(entity: Entity, mappings: any): any {

		const l = `pageDetails _toFields - `

		/* arguments : 

			entity = {
				"markVerbalElement": [
					{
						"text": "FRAWEI FourmisRobot",
						"languageCode": "fr"
					},
					{
						"text": "HUAWEI AntRobot",
						"languageCode": "en"
					}
				]
			}


			mappings = {
				"markVerbalElement": {
					"inid": "540",
					"handler": "langtext",
					"multi": true
				},
				"markSignificantVerbalElement": {
					"inid": "541",
					"handler": "langtext"
				},
				"markTranslation": {
					"inid": "566",
					"handler": "langtext"
				},
				"markTransliteration": {
					"inid": "561",
					"handler": "text"
				}
			}
		*/

		// console.log(`${l}entity = `, entity, `mapping = `, mappings);

		let fields: any = {}
		// iterate over this.doc.
		// for every key, call _key (if found) and append lines to this.lines
		Object.entries(mappings).forEach(([key, mapping]) => {

			// key = 'fullAddress'
			// mapping = { code: 'address', handler: 'langtext' }
			try {
				// fields['fullAddress'] = this._text_handler( doc['fullAddress'] ,{ code: 'address', handler: 'langtext' })
				fields[key] = this[`_${mapping['handler']}_handler`](entity[key], mapping) // entity[key] : string | LangTextField[] | Object
			} catch (err) {
				console.error(`${l}`, err);
				// console.log(`${l}key=`, key)
				// console.log(`${l}mapping=`, mapping)
			}
		})

		/*
			returning = {
				"fullName": {
					"code": "731",
					"label": "Applicant information",
					"content": "GUERNET COMPRESSEURS, SARL"
				},
				"kind": {
					"label": "Kind"
				},
				"identifier": {
					"label": "Identifier"
				},
				"fullAddress": {
					"label": "Address",
					"content": "51 route de Montargis, 89300 JOIGNY, FR"
				},
				"countryCode": {
					"label": "Country",
					"content": "France"
				}
			}
		*/

		return fields
	}


	addExtraFieldsDesign(fields: {}, design: GDDDocumentDesign){
		fields["compact_date"] = this.get_compact_earliest_date(design)
		fields["compact_status"] = this.get_compact_status(design)
		fields["compact_text"] =  this.get_compact_design_text(design)
	}

	// One Entity to fields
	_toFieldsDesign(entity: Entity, mappings: any): any {

		const l = `pageDetails _toFields - `

		let fields: any = {}
		// iterate over this.doc.
		// for every key, call _key (if found) and append lines to this.lines
		Object.entries(mappings).forEach(([key, mapping]) => {
		
			try {
				// fields['fullAddress'] = this._text_handler( doc['fullAddress'] ,{ code: 'address', handler: 'langtext' })
				fields[key] = this[`_${mapping['handler']}_handler`](entity[key], mapping) // entity[key] : string | LangTextField[] | Object
			} catch (err) {
				console.error(`${l} for field [`+ key + "]", err);
				// console.log(`${l}key=`, key)
				// console.log(`${l}mapping=`, mapping)
			}
		})
		const x = entity as any
		const y = x as GDDDocumentDesign
		this.addExtraFieldsDesign(fields, y as GDDDocumentDesign);
		return fields
	}

	_applicants_handler(entities: Entity[] = []): any {
		// applicants: #inid (730)
		//   - identifier:
		//     kind: #(Natural Person|Legal Entity|Other)
		//     fullName:
		//       - languageCode:
		//         text:
		//     fullAddress:
		//       - languageCode:
		//         text:
		//         rasterized :
		//     countryCode:

		const l = `_applicants_handler - `

		let mapping = {
			'fullName': {
				code: 'name',
				handler: 'langtext'
			},
			'kind': { code: 'kind', handler: 'text' },
			'identifier': { code: 'identifier', handler: 'text' },
			'fullAddress': { code: 'address', handler: 'simplifyAddress'},
			'countryCode': { code: 'country', handler: 'country' }
		}

		return entities.map(entity => this._toFields(entity, mapping))
	}


	_designs_handler(entities: Entity[] = []): any {
		// applicants: #inid (730)
		//   - identifier:
		//     kind: #(Natural Person|Legal Entity|Other)
		//     fullName:
		//       - languageCode:
		//         text:
		//     fullAddress:
		//       - languageCode:
		//         text:
		//         rasterized :
		//     countryCode:

		const l = `_designs_handler - `
		console.log('designs entities: ', entities);
		return entities.map(entity => this._toFieldsDesign(entity, designMapping))	
	}



	_designer_handler(entities: Entity[] = []): any {
		// applicants: #inid (730)
		//   - identifier:
		//     kind: #(Natural Person|Legal Entity|Other)
		//     fullName:
		//       - languageCode:
		//         text:
		//     fullAddress:
		//       - languageCode:
		//         text:
		//         rasterized :
		//     countryCode:

		const l = `_designer_handler - `

		let mapping = {
			'fullName': {
				code: 'name',
				handler: 'langtext'
			},
			'kind': { code: 'kind', handler: 'text' },
			//'identifier': { code: 'identifier', handler: 'text' },
			'fullAddress': { code: 'address', handler: 'simplifyAddress' },
			'countryCode': { code: 'country', handler: 'country' }
		}

		return entities.map(entity => this._toFields(entity, mapping))
	}


	_representatives_handler(entities: Entity[] = []): any {
		//   representatives: #inid (740)
		//      - identifier:
		//        kind: #(Natural Person|Legal Entity|Other)
		//        fullName:
		//          - languageCode:
		//            text:
		//        fullAddress:
		//          - languageCode:
		//            text:
		//            rasterized:
		//        countryCode:
		let mapping = {
			'fullName': { code: 'name', handler: 'langtext' },
			'kind': { code: 'kind', handler: 'text' },
			'identifier': { code: 'identifier', handler: 'text' },
			'fullAddress': { code: 'address', handler: 'simplifyAddress' },
			'countryCode': { code: 'country', handler: 'country' }
		}

		return entities.map(entity => this._toFields(entity, mapping))
	}

	_correspondence_handler(entity: Entity): any {
		// correspondence: #inid (750)
		//   fullName:
		//     - languageCode:
		//       text:
		//   fullAddress:
		//     - languageCode:
		//       text:
		//   countryCode:
		let mapping = {
			'fullName': { code: 'name', handler: 'langtext' },
			'fullAddress': { code: 'address', handler: 'langtext' },
			'countryCode': { code: 'country', handler: 'country' }
		}

		return this._toFields(entity || {}, mapping)
	}


	_events_handler(entities: Entity[] = []): any {
		return entities.map(item => this._event_handler(item))
	}

	_event_handler(entity: Entity = {}): any {
		let content = entity['date']
		if (entity['country']) {
			content = entity['country'] + ' - ' + content
		}
		if (entity['doc']) {
			content = content + ' <a href="' + entity['doc'] + '" target="_blanc">PDF</a>'
		}
		let toReturn = {
			label: entity['officeKind'],
			content: content
		}

		return toReturn
	}

	_circular_handler(entity: Entity = {}): any {
		let content = entity['number']
		if (entity['country']) {
			content = entity['country'] + ' - ' + content
		}
		if (entity['pdf']) {
			content = '<a href="' + entity['pdf'] + '" target="_blanc">' + content + '</a>'
		}
		if (entity['date']) {
			content = content + ' - ' + this.__dateValue(entity['date'])
		}
		let toReturn = {
			label: this.__inidToLabel("circular"),
			content: content
		}

		return toReturn
	}

	
	_reference_handler(entity: Entity = {}): any {
		// referece:
		//   office:
		//   application:
		//     - number:
		//       date:
		//   registration:
		//     - number:
		//       date:


		let mapping = {
			'office': { code: 'office_of_authority', handler: 'text' },
			'application': { handler: 'reference_doc' },
			'registration': { handler: 'reference_doc' },
		}
		return this._toFields(entity, mapping)
	}


	_reference_doc_handler(entities: Entity[] = []): any {
		let mapping = {
			'number': { code: 'number', handler: 'text' },
			'date': { code: 'date', handler: 'date' }
		}
		return entities.map(item => this._toFields(item, mapping))
	}

	_designClasses_handler(entry: DClassWrapper[]): any {
		// nicolas: I don't get why this should be an array. I thought only the class codes could be an array???
		const l = `_designClasses_handler - `
		
		let codes = new Set<string>();
		var inid= ""; 
		var content: any[] =[];
		var	versionClassif = "";
		if (entry instanceof Array ){
			for (let e of entry){
				// this should only be locarno classes ? 
				inid = (e?.kind === 'locarno' || e?.kind === 'Locarno') ? '51' : '52'; // not 52 / 53 ??
				// console.log("bla bla bla ", entry)

				
				for(let cl of e.classes){
					codes.add(cl.code);
				}
			
				// let content: string = "";		
				// if (entry?.kind === 'locarno') {
				// 	let tmp : string[];
				// 	for (let code of codes){
				// 		tmp.add(code + " " + getLocarnoClassString(code)); // use both languages?
				// 	}
				// 	content = tmp.join("; ");
				// }else{ // national classes.

				// }
				const version = e.version

				if (e?.kind === 'locarno') {		
					for (let code of codes){
						const field = {
							label: code,
							content: this.__locarnoClassLabel(code),						
						}
						content.push(field);
					}
				}else{ // national classes.

				}
				const kind = (e.kind === 'locarno' || e.kind === "Locarno") ? 'Locarno' : `${e.kind} National code` || "";		
				versionClassif = kind + (e?.version ? ' (' + e?.version + ')': '');
			}
		}
		const toReturn = {
			code: inid,
			label: this.__inidToLabel(inid),
			content,
			compact: Array.from(codes).join(", "),
			versionClassif
		}

		// console.log(`${l}toReturn = `, toReturn)
		return toReturn
	}

	_productIndicationClasses_classes_handler(entry: { code?: string[], kind?: string, version?: string, classes?: {code: string, description?:string}[] } = {}): any {
		// console.log("Entry: ", entry)
		const l = `_productIndicationClasses_classes_handler - `
		//
		/*  {
               "classes" : [
                  {
                     "code" : "10-06",
                     "description" : "SIGNALLING APPARATUS AND DEVICES"
                  },
                  {
                     "code" : "10-06",
                     "description" : "[SIGNALLING APPARATUS AND DEVICES]"
                  },
                  {
                     "code" : "10-06",
                     "description" : "Signalling apparatus and devices"
                  },
                  {
                     "code" : "10-06",
                     "description" : "[Signalling apparatus and devices]"
                  }
               ],
               "kind" : "locarno",
               "version" : "10"
            } 
		*/
		

		let inid = (entry?.kind === 'locarno' || entry?.kind === 'Locarno') ? '51' : '52'; // not 52 / 53 ??
		let codes = new Set<string>();
		for(let cl of entry.classes){
			codes.add(cl.code);
		}
		// let content: string = "";		
		// if (entry?.kind === 'locarno') {
		// 	let tmp : string[];
		// 	for (let code of codes){
		// 		tmp.add(code + " " + getLocarnoClassString(code)); // use both languages?
		// 	}
		// 	content = tmp.join("; ");
		// }else{ // national classes.

		// }
		const version = entry.version
		let content: any[] =[];
		if (entry?.kind === 'locarno') {		
			for (let code of codes){
				const field = {
					label: code,
					content: this.__locarnoClassLabel(code),
				}
				content.push(field);
			}
		}else{ // national classes.

		}
		// content should a list of fields ???
		// let content = entry.classes_human.map(e => {
		// 	let content = e[`description_${this.ms.lang}`] || e.description_en;

		// 	content = content.replace(`${e.code} - `, "");

		// 	const field = {
		// 		label: e.code,
		// 		content
		// 	}

		// 	return field
		// })
		const kind = (entry.kind === 'locarno' || entry.kind === "Locarno") ? 'Locarno' : `${entry.kind} National code` || "";
		
		const versionClassif = kind + (entry?.version ? ' (' + entry?.version + ')': '');
		const toReturn = {
			code: inid,
			label: this.__inidToLabel(inid),
			content,
			versionClassif
		}

		console.log(`${l}toReturn = `, toReturn)

		return toReturn
	}


	_productIndicationClasses_handler(entities: Entity[] = []): any {
		// markImageDetails:
		//   - name:
		//     colourIndicator: #inid (000) Color Claimed
		//     colourClaimed: # inid (591)
		//       - languageCode:
		//         text:
		//     description: # inid (539)
		//       - languageCode:
		//         text:
		//     classification: #inid (531)
		//       kind:
		//       version:
		//       code:
		//         - string
		return entities.map(item => this._productIndicationClasses_classification_handler(item))
	}

	_productIndicationClasses_classification_handler(entry: { code?: string[], kind?: string, version?: string, classes_human?: any[] } = {}): any {

		const l = `_markimagedetails_classification_handler - `

		// console.log(`${l}entry = `, entry)

		/*
			LOG entry = {
				"code": [
					"04.05.14",	"27.05.09"
				],
				"kind": "Vienna",
				"version": "7",
				"classes_human": [ // added server-side in docsgbs.ts
					{
						"code": "04.05.14",
						"description_fr": "04.05.14 - Robots ayant l'aspect d'un animal",
						"kind": "Vienna7",
						"description_en": "04.05.14 - Robots having the appearance of animals",
						"id": "Vienna_7-04.05.14",
						"version": "7",
						"_version_": "1750746039684431882"
					},
					{
						"code": "27.05.09",
						"description_fr": "27.05.09 - Suites de lettres présentant des graphismes différents",
						"kind": "Vienna7",
						"description_en": "27.05.09 - Series of letters presenting different forms of writing",
						"id": "Vienna_7-27.05.09",
						"version": "7",
						"_version_": "1750746039894147082"
					}
				]
			}
		*/

		let inid = entry?.kind === 'Locarno' ? '51' : '52';

		entry.classes_human = entry?.classes_human || [];

		entry.classes_human.sort((a, b) => a.code < b.code ? -1 : a.code > b.code ? 1 : 0)
		let content = entry.classes_human.map(e => {
			let content = e[`description_${this.ms.lang}`] || e.description_en;

			content = content.replace(`${e.code} - `, "");

			const field = {
				label: e.code,
				content
			}

			return field
		})
		const kind = entry.kind === 'Locarno' ? 'Locarno' : `${entry.kind} National code` || "";
		
		const version = kind + (entry?.version ? ' (' + entry?.version + ')': '');
		const toReturn = {
			code: inid,
			label: this.__inidToLabel(inid),
			content,
			version
		}

		// console.log(`${l}toReturn = `, toReturn)

		return toReturn
	}


	_priorities_handler(entities: Entity[] = []): any[] {
		// priorities: #inid (300)
		//      - countryCode:
		//        countryName:
		//        number:
		//        date:
		//        comment:
		let mapping = {
			'countryCode': { inid: '33', handler: 'text' },
			'number': { inid: '31', handler: 'text' },
			'date': { inid: '32', handler: 'date' },
		}

		return entities.map(item => this._toFields(item, mapping))
	}


	_simplifyAddress_handler(content: AddressLangTextField[], mapping: any): any {
		if (content && content.length == 1){
			let one = content[0]
			let countryCode = one.countryCode
			var location = ""
			var next = ""
			if (one.cityName){
				location += one.cityName
				next = ", "
			}
			if (one.geographicRegionName){
				location += next + one.geographicRegionName
				next = ", "
			}
			if (one.countryCode){
				// need to translation the country code to the country name
				location += next + one.countryCode 
			}
		}
	// 	cityName: "Jiaxing"
	// countryCode: "CN"
	// geographicRegionName: "Zhejiang"
	// languageCode: "en"
	// postalCode: "314031"



		let toReturn = {
			code: mapping['inid'], // "731"
			label: this.__inidToLabel(mapping['inid'] || mapping['code']), // returns: string (translates)
			content: location
		}
		return toReturn;
	}

	// ----------------------------------------------------
	// generic handlers here
	// ----------------------------------------------------
	_langtext_handler(content: LangTextField[], mapping: any): any {

		const l = `_langtext_handler - `

		/*
			content = [
				{
					"text": "51 route de Montargis, 89300 JOIGNY, FR",
					"languageCode": "fr",
					"rasterized": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARQAAAAOCAYAAADuSuMwAAAABmJLR0QA/wD/AP+gvaeTAAAIGUlEQVRoge2aeYzWxRnHP8uyF7uLsLtaomJZlcPGVmyx9SKKaAgeoH+I1VpNK63xijViW4lt2lSjrRYlxjuIF6HGemCpBbUtao9URURKRGhFa+1SxEiXBVcLrn98Z3xn5v3N79jdtxr7fpNf4J1n5pmZZ+Y5Z+H/G30f9wI+YajKo4pBwcPoMrnffxP6fR3Y9T9cl4vHK8BzIArUB8yM0I4aIO8kVGL/lcRn0b1623xLgM6gz5nAv4AVwJcTxi8BtgLvAMuA8UGf2cAb5jsnYQ1Z9CS4OhCiAfgu8CKw3XzPAadF+CThVGAN0Au8hfZ+QkK/XcDUlDW6+B1wXtA227TnQaj7STJIou0A/gbMA0aEC/p8xqQnA/fz8Xmx1yrAcyB7eR9d+p2Jsxh0Y688mbz3Ytf1Nzf8O9F0cSsYHwnKi5uQanSIvwIE+BrKDXfSPIfl2XRLRqRwXvLfAvQE3uIBuQE3D9sW4mMXV3QN6YfZ6ICfS/a1wr0R3dp9TMX0+lfypN2VgMxKCBnWSQdruJTglZ8TzIK1X/6g/2QQajik4/qWVVREaxCdYAWZEyuRKlXf/AL4PhBWlcVlUX1rKqoCCagGkAPKlIvoNjfRlRRRSo+BIk3eB8dltFVAAAAAElFTkSuQmCC"
				}
			]
	
			mapping = {
				"inid": "731",
				"handler": "_langtext_handler",
				multi? : boolean --> Defaults to false. If true, concatenates all found values in one string, separated with commas
			}
		*/

		if (!content || !content.map) {
			// console.log(`${l}No "content", skipping`)
			return null
		}

		// console.log(`${l}content = `, content)
		// console.log(`${l}mapping = `, mapping)

		let text: string = ""

		if (mapping.multi && content.length > 1) {
			text = content.map(obj => `(${obj.languageCode}) ${obj.text}`).join(", ")
		} else {
			text = this.__langTextValue(content) // returns : "51 route de Montargis, 89300 JOIGNY, FR"
		}

		let toReturn = {
			code: mapping['inid'], // "731"
			label: this.__inidToLabel(mapping['inid'] || mapping['code']), // returns: string (translates)
			content: text
		}

		const rasterized: string = this._extractRasterized(content);

		if (rasterized) {
			toReturn["rasterized"] = rasterized;
		}

		// console.log(`${l}Returning toReturn = `, toReturn)

		return toReturn
	}
	_country_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__countryValue(content)
		}
	}
	_office_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__officeValue(content)
		}
	}
	_text_handler(content: string, mapping: any): any {
		let label_code = mapping['inid'] || mapping['code']
		if (mapping['code'] && label_code != mapping['code']) {
			label_code = mapping['code']
		}
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(label_code),
			content: content
		}
	}

	_date_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__dateValue(content)
		}
	}

	_publications_handler(content: any[], mapping: any): any {				
		// figure out latest date
		let earliest = "9999-99-99";
		let ident = "";
		console.log("-- -- -- -- ", content)
		if (!content){return;}
		for (var x of content){
			if (x.date && x.date < earliest){
				earliest = x.date;
				ident = x.identifier;
			}
		}
		if (earliest != "9999-99-99"){
			return {
				code: mapping['inid'],
				label: this.__inidToLabel(mapping['inid'] || mapping['code']),
				content: this.__dateValue(earliest) + " - " + ident,
				compact: this.__dateValue(earliest)
			}			
		}else {
			return {
				code: mapping['inid'],
				label: this.__inidToLabel(mapping['inid'] || mapping['code']),
				content: ""
			}
		}
	}

	_link_handler(content: string, mapping: any): any {
		return {
			label: this.__inidToLabel('view_document'),
			content: '<a href="' + content + '" target="_blanc">PDF</a>'
		}
	}

	_list_handler(content: [], mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__joinArray(content, mapping['separator'])
		}
	}
	_terms_handler(content: [], mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__termsValue(content, mapping['separator'])
		}
	}
	_listcountries_handler(content: [], mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__listCountryValue(content)
		}
	}


	// ----------------
	// value helpers
	// ----------------

	// getting value of content based on type
	__textValue(content: string): string {
		return content
	}
	__joinArray(content: any[], separator: string = ', '): string {
		return (content || []).join(separator)
	}
	__dateValue(content: string): any {
		return this.ms.dateToHuman(content)
	}
	__termsValue(content: any, separator: string = ' \u2022 '): string {

		const l = `doc2fields __termsValue() - `

		for (let key in content) {

			// console.log(`${l}content = `, content, `key = `, key)

			const terms = content[key]
			return terms.join(separator)
		}
	}
	__countryValue(content: string): any {
		if (content !== undefined) {
			return this.ms.translate(`designation.${content}`)
		}
	}
	__listCountryValue(content: any[], separator: string = ', '): string {
		return (content || []).map(country => this.__countryValue(country)).join(separator)
	}
	__officeValue(content: string): any {
		if (content !== undefined) {
			return this.ms.translate(`office.${content}`)
		}
	}

	__langTextValue(langTextList: LangTextField[]): string { // returning : "51 route de Montargis, 89300 JOIGNY, FR" in the preferred language of the user if available

		const l = `__langTextValue`

		/*
			langTextList = [
				{
					"text": "51 route de Montargis, 89300 JOIGNY, FR",
					"languageCode": "fr",
					"rasterized": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQIAAAANCAYAAABCbmAxAAAABmJLR0QA/wD/AP+gvaeTAAAFpElEQVRoge3aZ4hdRRQH8N/GsrtplsSCDSsRrKCCRiREEVHEgmJFEWwfFAu2T4KioGKviGDFigRFExsWLPhBjV2MBQUVrGA0msRojB/OfeS+u3PLeze7Qd0/XHhzT5lzZs6cO3PmMY46rBgj+Sn4ADe37K9ftPVzHP8hnIDlJbRT8HX2nDxmFpVjReH5A1/3iDroJ+omntvQqGyZkckeKbfmjuhdEnvdacezaRhwhbhS+59G5eXheJOGJ2bOXGNdndO/SUuOxsSh2po7CVfPQbyIgbjtuk7jtqCs6HI8f8aX0HyXqOp8piiNLxFZstrga65xf18TVmf7fxODu3GMfKcwWxbHFopo+AxdbOeh1do12Iqjzu06+zr+U/DQ8aeX14aFZ34M5nqrjyHLpeMnvbNrGU1t6HkU7fsPr4o9IVX8oGsLtWT8/4k7xv4kiBnGB7j8Uzcc5Rm7By+LkQOmbm6p5aJMIiI/OFQ10jON/im3w6uo2Yhzj8zCO1YuHcdDqNmIcYzcP/wBY1De4XzknGwAAAABJRU5ErkJggg=="
				}
			]
	
		*/

		if (langTextList === undefined || langTextList.length === 0) return "";

		for (let pair of langTextList) {
			if (pair.languageCode === this.ms.lang) {
				return pair.text
			}
		}

		// fall back to first item in list
		return langTextList[0].text || null
	}

	_extractRasterized(langTextList: LangTextField[]): string { // returning Base64 rasterized address, or null

		const l = `_extractRasterized`

		/*
			langTextList = [
				{
					"text": "51 route de Montargis, 89300 JOIGNY, FR",
					"languageCode": "fr",
					"rasterized": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQIAAAANCAYAAABCbmAxAAAABmJLR0QA/wD/AP+gvaeTAAAFpElEQVRoge3aZ4hdRRQH8N/GsrtplsSCDSsRrKCCRiREEVHEgmJFEWwfFAu2T4KioGKviGDFigRFExsWLPhBjV2MBQUVrGA0msRojB/OfeS+u3PLeze7Qd0/XHhzT5lzZs6cO3PmMY46rBgj+Sn4ADe37K9ftPVzHP8hnIDlJbRT8HX2nDxmFpVjReH5A1/3iDroJ+omntvQqGyZkckeKbfmjuhdEnvdacezaRhwhbhS+59G5eXheJOGJ2bOXGNdndO/SUuOxsSh2po7CVfPQbyIgbjtuk7jtqCs6HI8f8aX0HyXqOp8piiNLxFZstrga65xf18TVmf7fxODu3GMfKcwWxbHFopo+AxdbOeh1do12Iqjzu06+zr+U/DQ8aeX14aFZ34M5nqrjyHLpeMnvbNrGU1t6HkU7fsPr4o9IVX8oGsLtWT8/4k7xv4kiBnGB7j8Uzcc5Rm7By+LkQOmbm6p5aJMIiI/OFQ10jON/im3w6uo2Yhzj8zCO1YuHcdDqNmIcYzcP/wBY1De4XzknGwAAAABJRU5ErkJggg=="
				}
			]
	
		*/

		if (langTextList === undefined || langTextList.length === 0) return "";

		for (let pair of langTextList) {
			if (pair.languageCode === this.ms.lang) {
				return pair.rasterized || null
			}
		}

		// fall back to first item in list
		return langTextList[0].rasterized || null
	}


	// Extra fields so calling the function from the frontend does not call an infinite loop.
	get_compact_earliest_date(design:any): any{
		console.log("---- compact date -------", design);
		if (design.earliest){
			return {"label": design.earliest_label, "content":  design.earliest}
		}
		if (design.publications){
			let content = design.publications
			let earliest = "9999-12-31";
			let ident ="";
			for (var x of content){
					if (x.date && x.date < earliest){
						earliest = x.date;
						ident = x.identifier;
					}
				}
				if (earliest != "9999-12-31"){
					// if (ident.length > 0){
					// 	return {"label": "Publication Date", "content":  this.__dateValue(earliest) + " - "+ ident}				
					// }
					// else{
						return {"label": "Publication Date", "content":  this.__dateValue(earliest)}				
					// }
				}else{
					null
				}
		}
		if (design.status_date){
			return {"label": "Status Date", "content":  this.__dateValue(design.status_date) }
		}
		if (design.statusDate){
			return {"label": "Status Date", "content":  this.__dateValue(design.statusDate) }
		}
		return null
	}

	get_compact_status(design:GDDDocumentDesign): any{
		if (design.gddStatus){
			return {"label": "Status", "content": design.gddStatus};
		}
		return {"label": "Status", "content": "????"};
	}

	get_first_date(design:any): any{
		console.log("---- first_date ----", design);
		return {"label": design.earliest_label, "content":  design.earliest}
	}



	get_compact_design_text(design:GDDDocumentDesign): any{
		var label = "";
		var content = "";
		if (design.designDescription){
			label = "Design Description";
			content = design.designDescription[0].text;
		}
		
		if (design.productIndication){
			label = "Product Indication"
			if (design.productIndication instanceof Array && design.productIndication.length > 0 ){
				content = design.productIndication[0].text
			}
		}
		return {"label": label, "content":  content}
	}

	get_locarno(): any{
		return this.get_class_nums(true);
	}
	get_national(): any{
		return this.get_class_nums(false);
	}

	get_class_nums(locarno: boolean): any{

		const l = `_designClasses_handler - `
		
		let codes = new Set<string>();
		var inid= ""; 
		var content: any[] =[];
		var	versionClassif = "";

		for (let d of this.doc.designs){
			if(d.productIndicationClasses instanceof Object){ // Hague
				let x =  d.productIndicationClasses as unknown ;
				let c = x as PIClass;
				// console.log("--- --- ---" , c," has a kind property: ",  c.hasOwnProperty("kind"), c.kind, c.kind.toLowerCase() === 'locarno', locarno);
				if (c.hasOwnProperty("kind") && c.kind.toLowerCase() === 'locarno' && locarno){
					// this should only be locarno classes ? 
					inid = '51' ;
					for(let cl of c.classes){
						codes.add(cl.code);
					}
					versionClassif = c.version;
				}
				if (c.hasOwnProperty("kind") && c.kind.toLowerCase() != 'locarno' && !locarno){
						inid = '52';
					for(let cl of c.classes){
						codes.add(cl.code);
					}
					versionClassif = c.version;				
				}				
			}

			if(d.productIndicationClasses instanceof Array){ // Not Hague ?
				for (let c of d.productIndicationClasses){
					if (c.kind.toLocaleLowerCase() === 'locarno' && locarno){
						// this should only be locarno classes ? 
						inid = '51' ;
						for(let cl of c.classes){
							codes.add(cl.code);
						}
						versionClassif = c.version;
					}
					if (c.kind.toLocaleLowerCase() != 'locarno' && !locarno){
							inid = '52';
						for(let cl of c.classes){
							codes.add(cl.code);
						}
						versionClassif = c.version;				
					}
				}
			}
		}
		if (locarno && codes.size > 0 ){
			const toReturn = {
				code: inid,
				label: this.__inidToLabel(inid),
				content: Array.from(codes).join(", "),
				versionClassif
			}
			return toReturn;
		}
		if (locarno == false && codes.size > 0){
			const toReturn = {
				code: inid,
				label: this.__inidToLabel(inid),
				content: Array.from(codes).join(", "),
				versionClassif
			}

			return toReturn;
		}
		return null
	}

	__inidToLabel(inid: string): string {

		// first try to find the translation specific to the office
		const inidTranslationKey = `inids_${this.doc.registrationOfficeCode}.${inid}`
		let inidLabel: string = this.ms.translate(inidTranslationKey)

		// translation returns value in ? => no translation found
		// failed to find office specific => go for default
		if (inidLabel === inidTranslationKey) {
			inidLabel = this.ms.translate(`inids.${inid}`)
		}

		return inidLabel
	}

	__locarnoClassLabel(code: string, version?: string) : string{		
		const locTranslationKey = version? `locarno.${version}_${code}`: `locarno.${code}`
		let locLabel = this.ms.translate(locTranslationKey);
		if (locLabel === locTranslationKey){
			locLabel = this.ms.translate(`locarno.${code}`);
		}
		// console.log("Should translate locarno class from key : ", locTranslationKey, " or ", `locarno.${code}`, "got : ", locLabel );		
		return locLabel;
	}

}
