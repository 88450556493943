import { Component, Input } from '@angular/core';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { instanceType, resizeImage } from 'src/app/commons/utils';


@Component({
	selector: 'imagedrop',
	templateUrl: './imagedrop.component.html',
	styleUrls: ['./imagedrop.component.css']
})
export class ImagedropComponent {

	@Input() maxImagesCount: number;
	@Input() callback: any;

	isHovering: boolean = false;
	error: string = null

	public imageInfo = {
		fileName: "",
		height: 0,
		width: 0
	}

	constructor(public ms: MechanicsService,
		public qs: QueryParamsService
	) {
		const l = `imageDrop constructor - `

	}

	ngOnInit() {
		const l = `imageDrop ngOnInit() - `

		if (!this.maxImagesCount) {
			console.warn(`${l}maxImagesCount is undefined! Defaulting to 1`)
			this.maxImagesCount = 1;
		}
	}


	displayError(message: string) {
		setTimeout(() => this.error = "", 3000);
		this.error = message;
	}

	removeImage(index: number) {
		this.ms.bases64.splice(index, 1)
		this.ms.bases64resized.splice(index, 1)
		this.saveToStorage()
		if (this.callback){
			this.callback()
		}
	}

	preventDefaultAndStopPropagation($event) {
		$event.preventDefault();
		$event.stopPropagation();
	}

	onDragOver($event: any) {
		// console.log(`onDragOver()`);
		if (!this.isHovering) {
			this.isHovering = true;
		}
		this.preventDefaultAndStopPropagation($event);
		return false;
	};

	onDragLeave($event: any) {
		// console.log(`onDragLeave()`);
		this.isHovering = false
		this.preventDefaultAndStopPropagation($event);
		return false;
	}

	onDrop($event: any) {
		// console.log(`onDrop()`);
		this.preventDefaultAndStopPropagation($event);
		this.FileSelectHandler($event);
	}


	// file selection handler (can be called from drop, or from a file-requestor select box)
	async FileSelectHandler($event: any) {

		const l = `imageDrop FileSelectHandler - `

		this.isHovering = false
		const files = $event.target.files || $event.dataTransfer.files

		for (let file of files) {

			// console.log(`${l}For loop start - ms.bases64?.length='${this.ms.bases64?.length}'`)

			if (this.maxImagesCount === 1) {
				// console.log(`${l}Reseting this.ms.bases64`)
				this.ms.bases64 = [] // So the image we drop just replaces the previous one
				this.ms.bases64resized = [];
				// console.log(`${l}Now this.ms.bases64?.length='${this.ms.bases64?.length}'`)
			}

			if ((this.maxImagesCount > 1) && (this.ms.bases64?.length >= this.maxImagesCount)) {
				this.displayError(this.ms.translate("page_similarlogo.too_many_images") + ` (${this.maxImagesCount})`);
				break;
			}

			// console.log(`${l}For loop OK, reading file`)

			try {
				await this.readFile(file);
			} catch (err) {
				// console.log(`${l}Caught error : `, err)
				this.displayError(err)
			}
		}

		this.saveToStorage()
	}

	async readFile(file: File): Promise<string> {

		const l = `imagedrop readFile() - `

		let ext = file.name.split(".").pop(),
			name = file.name;

		const accepted = ["jpg", "jpeg", "png", "webp"];

		if (!accepted.includes(ext.toLowerCase())) {

			return Promise.reject(`Unsupported file type : ${ext} - Accepting : ${accepted.join(", ")}`)
		}


		this.ms.currentFileName = name;

		const reader = new FileReader();

		return new Promise((resolve, reject) => {

			reader.onload = e => {
				// console.log(`RAW File reader output length = `, (reader.result as string).length)

				let image: HTMLImageElement = new Image();

				image.onload = async () => {

					const base64full: string = await resizeImage(image); // Converts an HTMLImageElement into base64, optional resize
					const base64resized: string = await resizeImage(image, 1024);

					// console.log(`${l}Pushing base64 to ms.bases64... this.ms.bases64?.length='${this.ms.bases64?.length}', this.ms.bases64resized.length='${this.ms.bases64resized.length}'`)
					this.ms.bases64.push(base64full);
					this.ms.bases64resized.push(base64resized);
					// console.log(`${l}Now this.ms.bases64?.length='${this.ms.bases64?.length}', this.ms.bases64resized.length='${this.ms.bases64resized.length}'`)

					this.imageInfo = {
						fileName: file.name,
						height: image.height,
						width: image.width
					}

					resolve(base64full)
				}

				image.src = <string>reader.result
			}

			reader.onerror = err => {
				reader.abort()

				this.imageInfo = {
					fileName: "",
					height: 0,
					width: 0
				}

				reject(`File reader error : ${reader.error.message}`)
			}

			reader.readAsDataURL(file);
		})
	}

	/*

		reset() { // Not using it (no button to revert to the original dropped image)
			this.ms.bases64 = deepClone(this.ms.originalBases64);
			this.ms.originalBases64 = [];
		}

	*/

	saveToStorage() {
		const l = `imagedrop saveToStorage() - `
		try {
			if(!Array.isArray(this.ms.bases64)){
				// console.log(`${l}ms.bases64 = `, this.ms.bases64)
				throw `ms.bases64 is not an array, not writing it to sessionStorage`
			}
			// console.log(`${l}Saving this.ms.bases64 to sessionStorage = `, this.ms.bases64)
			sessionStorage.setItem(`${instanceType()}.ms.bases64`, JSON.stringify(this.ms.bases64))
		} catch (err) {
			// console.log(`${l}Caught error : `, err)
			sessionStorage.removeItem(`${instanceType()}.ms.bases64`)
		}
		// // console.log(`${l} session images = `, sessionStorage.getItem(`${instanceType()}.ms.bases64`))
	}

}
