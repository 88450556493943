import { Component, DoCheck, KeyValueDiffer, KeyValueDiffers, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WOption } from '@wipo/w-angular/shared';



import { SearchService } from 'src/app/commons/_services/search.service';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { asStructure2bricks, bricks2AsStructure, canSearch, deepClone, generateId } from 'src/app/commons/utils';
import { OptionsListService } from 'src/app/commons/_services/options-list.service';


@Component({
	selector: 'page-similarname',
	templateUrl: './page-similarname.component.html',
	styleUrls: ['./page-similarname.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class GDDPageSimilarNameComponent implements OnInit, DoCheck {

	public design_classes: WOption[] = []
	public classLabel:String = ""
	public bricks;
	public structure
	public show_info = false;
	differ: KeyValueDiffer<string, any>;
	public recordsCount: string = null
	public dataSourcesCount: string = null
	public ev1 = false;
	public canSerachClass:Boolean = false


	constructor(private ar: ActivatedRoute,
		public ss: SearchService,
		public ms: MechanicsService,
		public qs: QueryParamsService,
		public ols: OptionsListService,
		private differs: KeyValueDiffers) {

		const l = `page-similarName constructor - `

		this.ms.setEndpoint("similarname")
		this.ms.setOffice(this.ar.snapshot.params.office)
		this.differ = this.differs.find({ bricks: this.bricks }).create();
		this.classLabel = this.ms.translate(`page_similarname.search_class_value`) + " " + this.ms.translate(`page_similarname.value`) ;

		// this.qs.resetQP("similarname");
	}

	ngDoCheck() {
		let change = this.differ.diff(this.bricks);
		if (change) {
			this.structure.bricks = bricks2AsStructure(this.bricks, this.preprocessBricks).bricks
		}
	}

	async ngOnInit(): Promise<void> {

		const l = `pageSimilarName ngOnInit() - `
		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC
		this.qs.keepOnlySearchParams();

		this.buildStuff()
		this.ms.show_news = true
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem("gdd.hashSearches")
	}


	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}	


	get defaultBricks() {
		return {
			productIndication: "",
			description: "",
			applicant: "",
			designer: "",
			designation: [],
			designClassType: "",
			designClassValue: ""
		}
	}

	classChange(){
		this.classLabel = this.ms.translate(`page_similarname.${this.bricks.designClassType}`) + " " + this.ms.translate(`page_similarname.value`) ;
		this.canSerachClass = true
	}

	preprocessBricks(orig_bricks) {
		let bricks = deepClone(orig_bricks);
		if (bricks.designClassType && bricks.designClassValue){
			bricks['class_'+bricks.designClassType] = bricks.designClassValue
		}
		console.log(bricks)
		delete bricks.designClassType
		delete bricks.designClassValue
		return bricks
	}


	buildStuff() {

		const l = `page-similarName buildStuff() - `

		this.bricks = this.defaultBricks;
		this.design_classes = [
			{ value: "locarno", label: "" }, // Displayed as "Exact similarity" but in fact it acts as a "contains". Leaving the value "Exact", but changing the displayed text to "Contains"
			{ value: "ca", label: "" },
			{ value: "jp", label: "" },
			{ value: "us", label: "" },
		].map(obj => {
			obj.label = this.ms.translate(`page_similarname.${obj.value}`);
			return obj
		})

		if(this.bricks.designClassType){
			this.classLabel = this.ms.translate(`page_similarname.${this.bricks.designClassType}`) + " " + this.ms.translate(`page_similarname.value`) ;
			this.canSerachClass = true
		}
		else{
			this.classLabel = this.ms.translate(`page_similarname.search_class_value`) + " " + this.ms.translate(`page_similarname.value`) ;
		}
		this.buildBricksFromQP()
		this.structure = bricks2AsStructure(this.bricks, this.preprocessBricks);
	}

	get windowWidth(): number {
		return window.innerWidth
	}

	get parent() {
		return this
	}

	buildBricksFromQP() {
		const l = `page-similarName buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")

		let asStructure
		try {
			asStructure = JSON.parse(qpAsStructureString)
		}
		catch (err) {
			return
		}
		

		// console.log(`${l}type=${typeof qpAsStructure } - qpAsStructure = `, qpAsStructure)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			this.bricks = Object.assign(this.bricks, asStructure2bricks(JSON.parse(qpAsStructureString)))
		}
	}


	get canSearch(): boolean {
		return canSearch(this.bricks.productIndication) || canSearch(this.bricks.description) || canSearch(this.bricks.applicant) || canSearch(this.bricks.designer) || canSearch(this.bricks.designation) || (canSearch(this.bricks.designClassType) && canSearch(this.bricks.designClassValue))
	}

	async search() {

		const l: string = `PageSimilarName search() - `

		if (!this.canSearch) return;

		const asStructure = bricks2AsStructure(this.bricks, this.preprocessBricks);

		// this.qs.setQP("asStructure", JSON.stringify(asStructure)) // Why the hell was I stringifying the asStructure??
		this.qs.setQP("asStructure", asStructure)
		this.qs.setQP("fg", "_void_")

		// console.log(`01 - ${l}HAve set QP.asStructure =`, asStructure)

		const route: string = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })

		// Updates the URL accordingly without reloading the page.
		// Changing the query params in the URL will trigger a subscription in HOCSearchComponent ,
		// which in turn will automatically call SearchService.search() and refresh the results.
		await this.qs.queryParamsObjectToUrl(route)
	}

	doReset() {
		const l = `page-similarname doReset() - `
		this.bricks = this.defaultBricks;
	}

}
