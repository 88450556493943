import { Component, ViewChild, ElementRef } from '@angular/core';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { instanceType, resizeImage } from 'src/app/commons/utils';


interface Handles {
	center: Array<number>, // used only for the ellipse
	outline: Array<Array<number>>
}

@Component({
	selector: 'image-editor',
	templateUrl: './image-editor.component.html',
	styleUrls: ['./image-editor.component.css']
})
export class ImageEditorComponent {

	@ViewChild('sourceImgElem', { static: false }) sourceImgElem: ElementRef;
	@ViewChild('canvasElem1', { static: false }) canvasElem1: ElementRef; // Displayed, image + outline
	@ViewChild('canvasElem2', { static: false }) canvasElem2: ElementRef; // Hidden, image as is for undo
	@ViewChild('canvasElem3', { static: false }) canvasElem3: ElementRef; // Hidden, transparent/white background + image inside outline, before composite
	@ViewChild('canvasElem4', { static: false }) canvasElem4: ElementRef; // Displayed on hover, with fill inside the outline and original image around
	@ViewChild('canvasElem5', { static: false }) canvasElem5: ElementRef; // Displayed on hover, composite (outside background + image inside the outline)
	@ViewChild('canvasElem6', { static: false }) canvasElem6: ElementRef; // Final choice displayed on page 3 (auto-trimmed copy of canvas4 or canvas5)

	canvasDimensions: number[] = [0, 0]
	ctx1: any
	ctx2: any
	ctx3: any
	ctx4: any
	ctx5: any
	ctx6: any
	currentHandleIndex: string = "0" // can be "center"
	currentPage: number = 1
	eyeDropperMode: boolean = false
	handles: Handles
	handlesBackup: Handles
	hasCropped: boolean = false // for undo(). If hasCropped, back to page 1, otherwise back to page 2
	intermediateBase64: string
	intervalComputePolygonLayerOffset: any
	pickedColor: string = "#ffffff"
	pickedFill: string // "inside" or "outside"
	polygonLayerOffset: number[] // coordinates of the polygon layer in the DOM, to compute the relatve coordinates of the click
	shape: string = "rectangle" // "polygon" || "ellipse" || "rectangle"
	showPreview: string = "" // which preview canvas to show on hover
	working: boolean = false


	constructor(public ms: MechanicsService) {
		this.init()
	}

	init() {
		// this.resetHandles() --> already done by resetEditor()
		this.working = false
		this.goToPage(1)
		this.resetEditor()
	}

	autoTrim(): void {

		let canvas6: HTMLCanvasElement = this.canvasElem6.nativeElement,
			w = canvas6.width,
			h = canvas6.height,
			pix = { x: [], y: [] },
			imageData = this.ctx6.getImageData(0, 0, canvas6.width, canvas6.height).data,
			x, y, index;

		for (y = 0; y < h; y++) {
			for (x = 0; x < w; x++) {

				index = (y * w + x) * 4;

				if (imageData[index] !== imageData[0]) {
					pix.x.push(x);
					pix.y.push(y);
				}
			}
		}
		pix.x.sort(function (a, b) { return a - b });
		pix.y.sort(function (a, b) { return a - b });
		let n = pix.x.length - 1;

		w = pix.x[n] - pix.x[0];
		h = pix.y[n] - pix.y[0];
		let cut = this.ctx6.getImageData(pix.x[0], pix.y[0], w, h);

		canvas6.width = w;
		canvas6.height = h;
		this.ctx6.putImageData(cut, 0, 0);

		// return canvas.toDataURL();
	}

	backupHandles() {
		this.handlesBackup = JSON.parse(JSON.stringify(this.handles))
	}

	clearAllCanvases() {
		for (let i of [1, 2, 3, 4, 5, 6]) {
			// hack to reset the canvas
			this["canvasElem" + i].nativeElement.width += 1;
			this["canvasElem" + i].nativeElement.width -= 1;
		}
	}

	closeEditor() {
		this.ms.editingImageIndex = null;
		setTimeout(() => this.resetEditor.bind(this), 500)
	}

	computeCanvasDimensions() { // applies to all canvases
		this.canvasDimensions = [this.sourceImgElem.nativeElement.offsetWidth, this.sourceImgElem.nativeElement.offsetHeight]
	}

	computePolygonLayerOffset() {

		// console.log(`computePolygonLayerOffset() - currentPage = ${this.currentPage}`)

		let elemToMeasure

		switch (this.currentPage) {
			case 1:
				elemToMeasure = this.sourceImgElem.nativeElement
				break;
			case 2:
				elemToMeasure = this.canvasElem1.nativeElement
				break;
			default:
				return;
		}

		// hack / workaround to ensure the offset is not completely out of bounds for some reason (sometimes it's like -2816 ??)
		clearInterval(this.intervalComputePolygonLayerOffset)

		this.intervalComputePolygonLayerOffset = setInterval(() => {

			let rect = elemToMeasure.getBoundingClientRect()
			this.polygonLayerOffset = [+rect.left.toFixed(1), +rect.top.toFixed(1)]

			if (this.isValidPolygonOffset()) clearInterval(this.intervalComputePolygonLayerOffset)
		}, 200)
	}

	crop() { // Shortcut from page 1 to page 3
		this.hasCropped = true // for undo()
		this.drawAllCanvases()
		this.pickFill(5)
	}

	drawCanvas1() { // Displayed, image + outline
		this.ctx1 = this.ctx1 || this.canvasElem1.nativeElement.getContext('2d', { willReadFrequently : true })
		this.drawImageToCanvas(1)
		this.drawOutlineToCanvas(1)
		this.ctx1.lineWidth = 2;

		let invertedPickedColor = "#" + (Number(`0x1${this.pickedColor.replace("#", "")}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()

		this.ctx1.strokeStyle = invertedPickedColor

		this.ctx1.stroke();
	}

	drawCanvas2() { // Hidden, image as is for undo
		this.ctx2 = this.ctx2 || this.canvasElem2.nativeElement.getContext('2d', { willReadFrequently : true })
		this.drawImageToCanvas(2)
	}

	drawCanvas3() { // Displayed on hover, with fill outside (fill)
		this.ctx3 = this.ctx3 || this.canvasElem3.nativeElement.getContext('2d', { willReadFrequently : true })
		this.ctx5 = this.ctx5 || this.canvasElem5.nativeElement.getContext('2d', { willReadFrequently : true })

		this.ctx5.fillStyle = this.pickedColor;
		this.ctx5.fillRect(0, 0, this.canvasDimensions[0], this.canvasDimensions[1])

		this.drawOutlineToCanvas(3) // first define the clip area, then draw the image inside
		this.ctx3.clip();

		this.drawImageToCanvas(3)

		//draw ctx3 over ctx5
		this.ctx5.drawImage(this.canvasElem3.nativeElement, 0, 0);
	}

	drawCanvas4() { // Displayed on hover, with fill inside and image outside (clip)
		this.ctx4 = this.ctx4 || this.canvasElem4.nativeElement.getContext('2d', { willReadFrequently : true })
		this.drawImageToCanvas(4)
		this.drawOutlineToCanvas(4)
		this.ctx4.fillStyle = this.pickedColor;
		this.ctx4.fill();
	}

	drawAllCanvases() {

		this.clearAllCanvases()

		for (let i of [1, 2, 3, 4]) {
			this[`drawCanvas${i}`]()
		}
		this.working = false
	}

	drawImageToCanvas(canvasNum: number) {
		this[`ctx${canvasNum}`].drawImage(this.sourceImgElem.nativeElement, 0, 0, this.canvasDimensions[0], this.canvasDimensions[1])

	}

	drawOutlineToCanvas(canvasNum: number) {

		let ctx = this[`ctx${canvasNum}`],
			w = this.canvasDimensions[0],
			h = this.canvasDimensions[1],
			center = this.handles.center,
			outline = this.handles.outline,
			xPercent, yPercent, xPx, yPx

		ctx.beginPath();

		switch (this.shape) {
			case "polygon":

				for (let handle of this.handles.outline) {
					ctx.lineTo(handle[0] * w / 100, handle[1] * h / 100);
				}
				break;

			case "ellipse":
				// ctx.ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle [, anticlockwise]);
				let r1Percent = 100 - 50 - (100 - outline[1][0]),
					r2Percent = 100 - outline[0][1] - 50,
					r1Px = r1Percent / 100 * w,
					r2Px = r2Percent / 100 * h;

				xPercent = center[0]
				yPercent = center[1]
				xPx = xPercent / 100 * w
				yPx = yPercent / 100 * h

				ctx.ellipse(xPx, yPx, r1Px, r2Px, 0, 0, 360);
				break;

			case "rectangle":

				let widthPercent = outline[1][0] - outline[0][0], // 100 - fromRight - fromLeft
					heightPercent = outline[1][1] - outline[0][1], // 100 - fromTop - fromBottom
					wPx = ~~(widthPercent / 100 * w),
					hPx = ~~(heightPercent / 100 * h);

				xPercent = outline[0][0]
				yPercent = outline[0][1]
				xPx = ~~(xPercent / 100 * w)
				yPx = ~~(yPercent / 100 * h)

				// console.log(`drawOutlineToCanvas - xPercent=${xPercent}, yPercent=${yPercent}, widthPercent=${widthPercent}, heightPercent=${heightPercent}`)

				ctx.rect(xPx, yPx, wPx, hPx) // top, left, width, height
				break;

			default:
				console.error(`drawOutlineToCanvas : don't know what to do with shape ${this.shape}`)
		}

		ctx.closePath();
	}

	getCSSClipPath() {

		let leString: string = "polygon(0% 0%, 0% 0%, 0% 0%)" // so the image is all white by default

		if (!this.shape) return leString

		try { // Easy way to avoid "handles not yet initialized" or "not enough handles yet"
			let center = this.handles.center,
				outline = this.handles.outline

			switch (this.shape) {
				case "ellipse":
					let n1 = (100 - 50 - (100 - outline[1][0])).toFixed(1),
						n2 = (100 - outline[0][1] - 50).toFixed(1),
						n3 = (center[0]).toFixed(1),
						n4 = (center[1]).toFixed(1);

					leString = `ellipse(${n1}% ${n2}% at ${n3}% ${n4}%)` // ellipse(36% 25% at 48% 47%)
					break;

				case "polygon":

					if (!outline || outline.length < 3) return leString // so the image is all white by default

					let joined = outline
						.map((handle: number[]) => `${handle[0]}% ${handle[1]}%`)
						.join(",")

					leString = `polygon(${joined})` // polygon(50% 0%, 0% 100%, 100% 100%)
					break;

				case "rectangle":

					/*    inset(10px 20px 30px 40px); values are from-top, from-right, from-bottom, from-left */
					let fromTop = outline[0][1].toFixed(1),
						fromRight = (100 - outline[1][0]).toFixed(1),
						fromBottom = (100 - outline[1][1]).toFixed(1),
						fromLeft = outline[0][0].toFixed(1)

					leString = `inset(${fromTop}% ${fromRight}% ${fromBottom}% ${fromLeft}%)`
					break;

				default:
					console.error(`getCSSClipPath : don't know what to do with shape '${this.shape}'`)
			}

		} catch (err) {
			return leString // so the image is all white by default
		}

		// console.log(`getCSSClipPath - returning ${leString}`)

		return leString

	}

	getPixelColor(x, y): string {

		// console.log(`getPixelColor - x,y = `, x,y)

		let p = this.ctx1.getImageData(x, y, 1, 1).data,
			hex = "#" + ("000000" + this.rgbToHex(p[0], p[1], p[2])).slice(-6)


		// console.log(`getPixelColor : p = `, p)
		// console.log(`hex = `, hex)

		return hex
	}

	goToPage(pageNum: number) {

		let keepEditing = this.currentPage === 3 // Clicked "keep editing"

		this.currentPage = pageNum

		switch (pageNum) {
			case 1:
				if (keepEditing) {
					this.resetHandles()
					this.intermediateBase64 = this.canvasElem6.nativeElement.toDataURL()

					if (this.intermediateBase64 === "data:," || this.intermediateBase64.length < 10) this.intermediateBase64 = "" // with an empty canvas6 on editor re-open. The image will then fall back to qs.croppedImage

					setTimeout(() => {
						this.computeCanvasDimensions()
						this.computePolygonLayerOffset()
					}, 100)
				}
				break;
			case 2:
				this.working = true
				setTimeout(this.drawAllCanvases.bind(this));
				break;
		}

	}

	isValidPolygonOffset(): boolean {
		return this.polygonLayerOffset[0] > 0
			&& this.polygonLayerOffset[0] < 3000
			&& this.polygonLayerOffset[1] > 0
			&& this.polygonLayerOffset[1] < 3000
	}

	onCanvas1Clicked() {
		this.eyeDropperMode = false
		this.drawAllCanvases()
	}

	onCanvas1Hovered($event) {

		if (!this.eyeDropperMode) {
			return false
		}

		let e = $event,
			mouseX = (e.clientX - this.polygonLayerOffset[0]) << 0,
			mouseY = (e.clientY - this.polygonLayerOffset[1]) << 0

		this.pickedColor = this.getPixelColor(mouseX, mouseY)

	}

	onHandleDragStart($event) {
		this.currentHandleIndex = "" + $event.attributes.target.value
		if (!this.canvasDimensions[0]) this.computeCanvasDimensions() // after editor close then re-open, for instance
	}

	onHandleMoving($event) {

		// computing the handle coordinates and storing them in {handles}
		// console.log(`onHandleMoving()`)
		let backupHandle = this.currentHandleIndex === "center" ? this.handlesBackup.center : this.handlesBackup.outline[this.currentHandleIndex],
			inPixels = [
				backupHandle[0] / 100 * this.canvasDimensions[0] + $event.x, // 601 (px)
				backupHandle[1] / 100 * this.canvasDimensions[1] + $event.y, // 301 (px)
			]

		// back to percentages
		let percentX = +(inPixels[0] * 100 / this.canvasDimensions[0]).toFixed(1),
			percentY = +(inPixels[1] * 100 / this.canvasDimensions[1]).toFixed(1)

		// console.log(`[percentX, percentY] = `, [percentX, percentY])

		if (this.currentHandleIndex === "center") {
			this.handles.center = [percentX, percentY]
		} else {
			this.handles.outline[this.currentHandleIndex] = [percentX, percentY]
		}

	}

	onHandleRightClick($event, index): boolean {

		const l = `onHandleRightClick() - `

		// console.log(`${l}`)

		if (this.shape !== "polygon") return true

		this.handles.outline.splice(index, 1)
		this.backupHandles()
		return false


	}

	onImageLoad() {
		this.computeCanvasDimensions()
		this.computePolygonLayerOffset()
	}

	onPolygonLayerClicked($event) {

		if (!$event.target.className.includes("polygonLayer")) return // prevents children bubbling

		if (!this.canvasDimensions[0]) this.computeCanvasDimensions() // after editor close then re-open, for instance
		if (!this.polygonLayerOffset) this.computePolygonLayerOffset()

		// console.log(`onPolygonLayerClicked - event = `, $event)

		// Adding a handle

		let e = $event,
			imgDimensions = [this.sourceImgElem.nativeElement.offsetWidth, this.sourceImgElem.nativeElement.offsetHeight],
			xPixels = e.clientX - this.polygonLayerOffset[0],
			yPixels = e.clientY - this.polygonLayerOffset[1],
			xPercent = (xPixels * 100 / imgDimensions[0]) << 0,
			yPercent = (yPixels * 100 / imgDimensions[1]) << 0

		this.handles.outline.push([xPercent, yPercent])
		this.backupHandles()
	}


	pickFill(which: number) {

		this.currentPage = 3

		this.ctx6 = this.ctx6 || this.canvasElem6.nativeElement.getContext('2d', { willReadFrequently : true })

		let pickedCanvas = this[`canvasElem${which}`].nativeElement

		this.canvasElem6.nativeElement.width = pickedCanvas.width
		this.canvasElem6.nativeElement.height = pickedCanvas.height

		this.working = true

		setTimeout(() => {
			// Copying the picked canvas to canvas6
			this.ctx6.drawImage(pickedCanvas, 0, 0, this.canvasDimensions[0], this.canvasDimensions[1])
			this.autoTrim()
			this.working = false
		})
	}

	resetEditor() {
		this.resetHandles()
		this.hasCropped = false
		this.polygonLayerOffset = [] // so it will be re-computed if the editor is open again
		this.canvasDimensions = [0, 0]
	}

	resetHandles() {

		this.shape = this.shape || "rectangle"

		switch (this.shape) {
			case "ellipse":
				this.handles = {
					center: [50, 50],
					outline: [[-1, 30], [90, -1]]
				}
				break;

			case "rectangle":
				this.handles = {
					center: null,
					outline: [[20, 20], [80, 80]]
				}
				break;

			case "polygon":
				this.handles = {
					center: null,
					outline: []
				}
				break;

			default:
				console.error(`setHandles : don't know what to do with shape ${this.shape}`)
		}

		// hack to visually reset the handles (reset ng2-draggable)
		let backupShape = "" + this.shape
		this.shape = "" // clears all polygon / ellipse / rectangle layers from the DOM
		setTimeout(() => {
			this.shape = "" + backupShape // Redraws polygon / ellipse / rectangle layers
		})

		this.backupHandles()
	}

	rgbToHex(r, g, b) {
		return ((r << 16) | (g << 8) | b).toString(16);
	}

	switchMode(shape: string) {
		this.shape = shape
		this.computePolygonLayerOffset()
		this.resetHandles()
	}

	undo() {
		if (this.hasCropped) {
			this.hasCropped = false
			this.currentPage = 1
		} else {
			this.currentPage = 2
		}
	}

	async useThisImage() {

		const l = `useThisImage() - `

		/* Not using it (no button to revert to the original dropped image)
			if (!this.ms.originalBases64[this.ms.editingImageIndex]) {
				this.ms.originalBases64[this.ms.editingImageIndex] = "" + this.ms.bases64[this.ms.editingImageIndex];
			}
		*/

		const base64full = this.canvasElem6.nativeElement.toDataURL('image/png');

		this.ms.bases64[this.ms.editingImageIndex] = base64full
		this.ms.bases64resized[this.ms.editingImageIndex] = await resizeImage(base64full, 1024);

		this.closeEditor()

		this.intermediateBase64 = null  // Ready to re-open the editor and edit again

		try {
			sessionStorage.setItem(`${instanceType()}.ms.bases64`, JSON.stringify(this.ms.bases64))
		} catch (err) {
			// console.log(`${l}Caught error : `, err)
			sessionStorage.removeItem(`${instanceType()}.ms.bases64`)
		}

	}

}
