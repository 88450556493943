
import { Component, OnChanges, SimpleChanges, ViewEncapsulation, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { WOption, WOptionEvent } from '@wipo/w-angular/shared';

import { FacetGroup } from 'src/app/commons/interfaces';
import { SearchService } from 'src/app/commons/_services/search.service';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesService } from 'src/app/commons/_services/preferences.service';
import { deduplicateStringArray } from 'src/app/commons/utils';

@Component({
	selector: 'facets-search',
	templateUrl: './facets-search.component.html',
	styleUrls: ['./facets-search.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class CompFacetsSearch implements OnChanges {

	// facets as recieved from solr response
	@Input() solrFacets: any;
	// cache facets here in case of pagination
	private solrFacetsCache: any = {}


	// whether the side bar is collapsed
	public isCollapsed: boolean = true;

	public facetGroups: FacetGroup[] = []; // see this.buildFacets()

	get windowWidth(): number {
		return window.innerWidth
	}

	public facetsFilters: any = {};

	private facetsCount: number = 0

	private lastFacetSelected: WOption; // Keeping track of the last checked facet, so I can implement a shift+click multiselect

	private timeoutDebounce: any = null;
	private canSearch: boolean = false;

	constructor(public ss: SearchService,
		public ms: MechanicsService,
		public qs: QueryParamsService,
		private ts: TranslateService,
		public ps: PreferencesService
	) {
		const l = `SearchResultsFacets constructor() -`
	}

	// commented for the benefit of ngOnChanges
	// ngOnInit(): void {
	//     this.buildStuff()
	//	}

	// rebuild when the input solrFacets change
	ngOnChanges(changes: SimpleChanges) {
		const l = `facetsSearchComponent ngOnChanges -`
		if (Object.keys(changes.solrFacets?.currentValue).length !== 0) {
			// check if the count changed => recache and rebuild
			let currCount = changes.solrFacets.currentValue.count
			if (currCount !== this.facetsCount) {
				this.facetsCount = currCount
				this.solrFacetsCache = this.solrFacets
				this.buildFacets()
			}
		}
	}

	get anyFacetSelected(): boolean {
		for (let key of this.qs.keys) {
			if (/^fc/.test(key) && this.qs.getQP(key)?.length) return true
		}
		return false
	}

	isFacetSelected(key): boolean {
		return (this.qs.getQP(`fc${key}`)?.length)
	}

	async clearAllFacets(): Promise<void> {
		// start from the beginning
		this.qs.rmQP('start')

		this.qs.clearAllFacets()
		await this.qs.queryParamsObjectToUrl();
	}

	async loadFacets(): Promise<void> {
		if (Object.keys(this.solrFacets).length === 0) {
			this.qs.rmQP('fg')
			await this.qs.queryParamsObjectToUrl();
		}
	}

	facetSelection(facetGroup: FacetGroup): string {
		// returns "(nb_selected/nb_total)"

		const l = `facets-search facetSelection() - `

		if (facetGroup.onlyOption) return ''

		let selectionCount: number = (this.qs.getQP(`fc${facetGroup.variable}`) || []).length
		let totalCount: number;

		if (facetGroup.variable.includes("Date")) {
			// Special count for dates, because we only want to count and display years. But each bucket contains a distinct date, so each date was counted as one
			totalCount = deduplicateStringArray(
				this.solrFacetsCache[facetGroup.variable]?.buckets
					.map(b => b.val.substring(0, 4)) // Taking only the "2004" part (year)
			).length;

		} else {
			totalCount = this.solrFacetsCache[facetGroup.variable]?.buckets.filter(bucket => bucket.count > 0).length
		}

		if (selectionCount > 0) {
			return `(${selectionCount}/${totalCount})`
		}

		return `(${totalCount})`
	}

	onNiceOrderClicked(order: string) {

		const l = `CompFacetsSearch onNiceOrderClicked() - `

		// console.log(`${l}sorting = `, this.facetGroups)

		let array = this.facetGroups.find(fg => fg.variable === "niceClass")?.options;

		if (!array) return;

		this.ps.setPref("niceClassOrder", order)

		if (order === "label2") { // relevance
			array = array.sort((a, b) => +a["label2"] > +b["label2"] ? -1 : 1)
		} else { // "value" in the Nice classification list (1, 2, 3...) but these are strings... and strings are not naturally sorted. "1" < "10" < "2"
			array = array.sort((a, b) => +a["value"] > +b["value"] ? 1 : -1)
		}
	}


	buildFacets(): void {

		const l: string = `SearchResultsFacets buildFacets() - `

		// console.log(`${l}this.solrFacets=`, this.solrFacets)

		// Receives : SolrFacets from the server
		// Transforms them into what w-input-checkbox-many expects :

		/*

			Input : SolrFacets {
				office?: {
					buckets: Bucket[];
				};
				markFeature: {
					buckets: [
						{ val: 'Word', count: 3543 },
						{ val: 'Combined', count: 2630 },
						{ val: 'Figurative', count: 146 },
						{ val: 'Three dimensional', count: 5 }
					]
				},
				niceClass?: {
					buckets: Bucket[];
				};
			}

			Output :

			 [{
				title: "office",
				variable: "office",
				"collapsed": false,
				"col": 1,
				"options": [{
						"label": "INPI (France)",
						"label2": 6324,
						"value": "FR",
					}]
			}]
		*/

		// if (!this.solrFacets) return;

		// First, building the facets that will be displayed in the left column
		this.facetGroups = [];
		const allKeys = Object.keys(this.solrFacets);

		for (const index in allKeys) {

			const variable = allKeys[+index]; // "applicant", "applicantCountryCode", "markFeature" etc. All facets

			if (["count"].includes(variable)) { // count is not a facet
				continue
			}

			let toPush = {
				variable, // "office" in QueryParams, and "general_words.office" in translations
				collapsed: index !== "0",
				options: [],
				label: this.ms.translate(`general_words.${variable}`)
			};

			if (["applicationDate"].includes(variable)) {
				toPush["col"] = 2;
			}

			this.facetGroups.push(toPush)
		}

		this.facetGroups.sort((a, b) => a.label > b.label ? 1 : -1)

		// console.log(`${l}this.facetGroups = `, this.facetGroups);

		for (let facet of this.facetGroups) { // now enriching it

			const solrFacet = this.solrFacetsCache[facet.variable] // { buckets : [{ val: 'Three dimensional', count: 5 }] }

			// console.log(`${l}solrFacet=`, solrFacet)
			// console.log(`${l}facet.variable = `, facet.variable)

			if (!solrFacet) {
				continue;
			}

			// filter out those facet years with '0' hits
			if (facet.variable === 'applicationDate') {
				solrFacet.buckets = solrFacet.buckets.filter(bucket => bucket.count > 0)
			}

			// special treatement for facets with only one bucket
			if (solrFacet.buckets.length === 1) {
				let bucket = solrFacet.buckets[0] // the only bucket

				let label: string = this.ms.translate(`${facet.variable}.${bucket.val}`);

				switch (facet.variable) {
					case "niceClass":
						label = bucket.val + " - " + this.ms.translate(`niceClass.${bucket.val}`) // 35 - Advertising; business management
						break;
					case "applicationDate":
					case "expiryDate":
					case "registrationDate":
						label = bucket.val.substring(0, 4); // "2013-01-01T00:00:00Z" --> "2013"
						break;
					case "office":
						label = "(" + bucket.val + ") " + label;
						break;
					case "applicantCountryCode":
						label = "(" + bucket.val + ") " + this.ms.translate(`designation.${bucket.val}`);
						break;
					case "designation":
						label = "(" + bucket.val + ") " + label;
						break;
					case "applicant":
					case "representative":
						label = bucket.val
						break;
				}
				facet.onlyOption = label
			} else

				if (solrFacet.buckets.length === 0) {
					facet.onlyOption = 'N/A'
				} else {
					delete facet.onlyOption
				}

			facet.options = solrFacet.buckets.map(bucket => {

				// console.log(`${l}bucket = `, bucket)

				if (!bucket.count) {
					return null;
				}

				let label: string = this.ms.translate(`${facet.variable}.${bucket.val}`);
				let value = bucket.val + "" // cast values to string so that the component works

				switch (facet.variable) {
					case "niceClass":
						label = bucket.val + " - <em>" + this.ms.translate(`niceClass.${bucket.val}`) // 35 - Advertising; business management
						break;
					case "applicationDate":
					case "expiryDate":
					case "registrationDate":
						label = bucket.val.substring(0, 4); // "2013-01-01T00:00:00Z" --> "2013"
						value = label
						break;
					case "office":
						label = "(" + bucket.val + ") " + label;
						break;
					case "applicantCountryCode":
						label = "(" + bucket.val + ") " + this.ms.translate(`designation.${bucket.val}`);
						break;
					case "designation":
						label = "(" + bucket.val + ") " + label;
						break;
					case "applicant":
					case "representative":
						label = bucket.val
						break;
				}

				return {
					label,
					label2: this.ms.numberFormatter.format(bucket.count),
					value, // Variable used in queryParams : "Pending" --> fcstatusPending
					lastSelected: false
				}
			})
				.filter(facet => facet != null);

			// console.log(`${l}facet = `, facet)

			if (!/Date$/.test(facet.variable)) continue;

			/* Deduplication : many 2003, many 2004 etc because dates are received by day

				transforming
					{label: '2003', label2: '3', value: '2003', lastSelected: false},
					{label: '2003', label2: '6', value: '2003', lastSelected: false},
					{label: '2003', label2: '7', value: '2003', lastSelected: false},
					{label: '2003', label2: '3', value: '2003', lastSelected: false},
					{label: '2003', label2: '2', value: '2003', lastSelected: false},

				into
					{label: '2003', label2: '21', value: '2003', lastSelected: false}

			*/
			let aggregatedOptions = {};

			for (let option of facet.options) {
				// console.log(`${l}option=`,option)
				aggregatedOptions[option.label] = +(aggregatedOptions[option.label] || "0");

				aggregatedOptions[option.label] += +(option.label2.replace(/,/g, ""));
			}

			// console.log(`${l}aggregatedOptions=`, aggregatedOptions)

			facet.options = Object.keys(aggregatedOptions).map(key =>  // key = "2003", "2004" , "2005"
				({ label: key, label2: aggregatedOptions[key], value: key, lastSelected: false })
			)

			// console.log(`${l}facet.options = `, facet.options)

		} // end for


		// Ordering the Nice classification either by relevance or by natural order, depending on local preferences
		this.onNiceOrderClicked(this.qs.getQP("niceClassOrder") || "label2")

		// console.log(`${l}Facets after transform = `, this.facetGroups);
	}

	async onFacetGroupChecked($event: WOptionEvent, facetGroup: FacetGroup): Promise<void> {

		const l: string = `search-results-facets onFacetGroupChecked() - `

		// Adding multiselect on click
		const shiftKey: boolean = $event.event["shiftKey"];
		const clicked: WOption = <WOption>$event.source;
		// console.log(`${l}shiftKey?`, shiftKey);

		if (shiftKey && this.lastFacetSelected) {
			const lastCheckedIndex: number = facetGroup.options.findIndex(facet => facet.value === this.lastFacetSelected.value)
			// console.log(`${l}lastCheckedIndex = `, lastCheckedIndex);
			const thisIndex: number = facetGroup.options.findIndex(facet => facet.value === (<WOption>$event.source).value)
			const min = Math.min(thisIndex, lastCheckedIndex) + 1;
			const max = Math.max(thisIndex, lastCheckedIndex);

			// console.log(`${l}min=${min}, max=${max}`);

			for (let i = min; i < max; i++) {
				// console.log(`${l}Checking facet ${i}`);

				const toPush = facetGroup.options[i].value

				// console.log(`${l}Pushing '${toPush}' to ss.queryParams[${'fc' + facetGroup.variable}]`);

				const paramName: string = 'fc' + facetGroup.variable; // "fcstatus"

				let value: string[] = <string[]>this.qs.getQP(paramName); // ["Registered"] or null

				if (!value) {
					this.qs.rmQP(paramName)
				} else if (!value.includes(toPush)) {
					value.push(toPush);
					this.qs.setQP(paramName, value)
				}
			}
		}

		this.lastFacetSelected = clicked;

		this.qs.rmQP('start')

		// Search debouncing
		if (!this.canSearch) {

			clearTimeout(this.timeoutDebounce);

			this.timeoutDebounce = setTimeout(() => {
				this.canSearch = true;
				this.onFacetGroupChecked($event, facetGroup);
			}, 800);

			// console.log(`${l}Debounced!`)
			return
		}

		this.canSearch = false

		await this.qs.queryParamsObjectToUrl(); // This will automatically refresh search results thanks to QueryParams subscription
	}

}

