import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { WOption } from '@wipo/w-angular/shared';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';
import { OptionsListService } from 'src/app/commons/_services/options-list.service';

interface TheInterface {
	label: string;
	value: any;
}

@Component({
	selector: 'suggest-multiselect',
	templateUrl: './comp-suggest-multiselect.component.html',
	styleUrls: ['./comp-suggest-multiselect.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [ // Part of the dark magic to implement [(ngModel)]
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CompSuggestMultiselectComponent,
			multi: true,
		}
	],
})
export class CompSuggestMultiselectComponent implements OnInit {

	/*
		OK so attributes I need :
		[(ngModel)] to know where to store the selected items, and the other way, where to look to populate the component
		[what] to know what to suggest (optional, we can just call Solr to suggest anything)
	*/

	@Input() what: string
	@ViewChild("sgInput") sgInput: ElementRef;

	public componentId: number = Math.random(); // Little hack to avoid opening all the suggestions drawers of all the same components on the page. I'm giving each one an ID
	public hasBlueOutline: boolean = false  // :focus works only on input elements -_-
	public valueHandler = null;

	constructor(public ols: OptionsListService) {

	}

	ngOnInit(): void {
		const l = `JerSMS ngOnInit() - `

	}

	onKeyup($event): void {
		const l = `JerSMS onKeyup() - `

		// console.log(`${l}$event.code : `, $event.code)

		switch($event.code || $event.key){
			case "ArrowDown":
				break;
			case "ArrowUp":
				break
		}
		
		this.ols.suggest($event, this.what, this.componentId)
	}

	onSuggestionClicked(suggestion: WOption) {
		const l = `JerSMS onSuggestionClicked() - `
		if (this.valueHandler == null){
			this.valueHandler = this.innerValue[this.what] || this.innerValue
		}

		// console.log(`${l}suggestion=`, suggestion, `this.innerValue=`, this.innerValue)
		if (!this.valueHandler.find(obj => obj.value === suggestion.value)) {
			// delete suggestion.label2 // Nope, on't delete it, it is needed when hovering a label. When we hover "10" we want to see the full description of the Nice class
			delete suggestion.info // This field is only used as an index filter, for faster filtering. No need to carry this around either
			this.valueHandler.push(suggestion) // This actually pushes things into ngModel :D
		}
		this.sgInput.nativeElement.value = "";
		this.sgInput.nativeElement.focus();

		if (this.innerValue._shadowUpdate !== undefined)
			this.innerValue._shadowUpdate ++;
		this.ols.suggestions = [] // Closing the suggestion list
		this.onChange(this.innerValue);

	}

	remove(obj: WOption) {
		if (this.valueHandler == null){
			this.valueHandler = this.innerValue[this.what] || this.innerValue
		}
		this.valueHandler = this.valueHandler.filter(o => o.value !== obj.value)
		if(this.innerValue[this.what])
			this.innerValue[this.what] = this.valueHandler
		else
			this.innerValue = this.valueHandler
		if (this.innerValue._shadowUpdate !== undefined)
			this.innerValue._shadowUpdate ++;
		this.onChange(this.innerValue);

	}








	/*
		Below : all the dark magic required to implement [(ngModel)] - Don't touch anything
		https://stackblitz.com/edit/angular-custom-comp-ngmodel
	*/

	private innerValue: any;
	private onChange: (_) => void;
	private onTouched: () => void;

	get value() {
		return this.innerValue;
	}

	set value(v) {
		if (this.innerValue != v) {
			this.innerValue = v;
			this.onChange(v);
		}
	}

	onBlur() {
		this.onTouched();
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	writeValue(v) {
		const l = `jerSuggest writeValue() - `
		if (v && this.innerValue != v) {
			// console.log(`${l}setting this.innerValue=`, deepClone(v))
			this.innerValue = v;
			if (this.valueHandler == null){
				this.valueHandler = this.innerValue[this.what] || this.innerValue
			}
		}
	}

	/*
		END [(ngModel)]
	*/
}
