import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';

import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { instanceType } from 'src/app/commons/utils';

@Component({
	selector: 'nav-bar',
	templateUrl: './comp-nav-bar.component.html',
	styleUrls: ['./comp-nav-bar.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CompMenuBar implements OnInit {

	public navigationItems: any[] = []
	public hide_title = false
	constructor(private ar: ActivatedRoute,
		public ms: MechanicsService,
		public qs: QueryParamsService,
		private router: Router
	) { }

	ngOnInit() {
		this.hide_title = window.location.href.includes('results') || window.location.href.includes('brand/') || window.location.href.includes('design/')
		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC
		this.buildNavigation()
	}

	// ugliest hack ever to keep Explore menu selected
	// when we browser to /explore/results
	//	-- this is happening cause Explore menu endpoint is /explore/visu
	//	-- rather than /explore
	ngAfterViewChecked() {
		try {
			document.getElementById(`nav-${this.ms.endpoint}`).firstElementChild.className = "b-navigation__shortcut-item--is-selected"
		}
		catch (err) { } // well, we did our best
	}

	doReset(): void {
		const l = `pageCoverage doReset() - `
		this.ms.officeCC = null

		// back to vanilla QP
		this.qs.resetQP('*', l)
		this.router.navigate([this.ms.makeRoute({ path: 'quicksearch', caller: l })])
	}

	get title(){
		if(instanceType() != 'gbd'){
			return 'Global Design Database'
		}
		return 'Global Brand Database'
	}

	public makeRouterLink(endpoint: string, includeOffice: boolean = true): string {

		const l = `MenuBar makeRouterLink() - `

		const route = this.ms.makeRoute({ path: endpoint, subpath: '', includeOffice, caller: l })

		// console.log(`${l}route for '${endpoint}' = `, route)

		return route
	}


	async buildNavigation(): Promise<void> {

		const l: string = `app buildNavigation() - `
		// console.log(`${l}`);

		while (!this.ms.translations) {
			await new Promise(r => setTimeout(r, 200))
		}
		
		this.navigationItems = [
			{
				text: this.ms.translate("page_similarname.find_by_name"),
				link: this.makeRouterLink('similarname'),
				endpoint: 'similarname',
				visible: true
			},
			{
				text: this.ms.translate("page_similarlogo.find_by_logo"),
				link: this.makeRouterLink('similarlogo'),
				endpoint: 'similarlogo',
				visible: true
			},
			 {
				text: this.ms.translate("page_advanced_search.page_name"),
				link: this.makeRouterLink('advancedsearch'),
				endpoint: 'advancedsearch',
				visible: !this.ms.isMobileView
			},
			{
				text: this.ms.translate("page_explore.explore"),
				link: this.makeRouterLink('explore/visu'),
				endpoint: 'explore',
				visible: !this.ms.isMobileView
			},
			{
				text: "Vienna assistant",
				endpoint:  'vienna',
				link:this.makeRouterLink('vienna'),
				visible: !this.ms.isMobileView
			},
			{
				text: "G&S Explorer",
				link:this.makeRouterLink('gs'),
				endpoint: 'gs',
				visible: !this.ms.isMobileView
			},
			{
				text: this.ms.translate("page_reports.reports"),
				link: this.makeRouterLink('reports'),
				endpoint: 'reports',
				visible: !this.ms.isMobileView
			},

		]
		
		if(this.ms.officeCC == 'WHO'){
			this.navigationItems.splice(1, 3,);
		}
		
		if(instanceType() != 'gbd'){
			this.navigationItems.splice(4, 2,);
		}

	}
}

