

/*

	This configuration loads the WIPO navbar, which produces errors in the console and breaks the Angular app when served locally. It needs to be run on WIPO servers for being able to load all files correctly

	So, I have created in package.json alternative build scripts (buildProdLocal and serveProdLocal) that build with --prod, but do not swap environment.ts with environment.prod.ts, so it's a production that can be run locally

*/
export const devInstanceType = undefined

export const environment = {
	gbd: {
		env: "awsDev",
		production : false,
		wipoComponentsPath: "https://cdn.ipp-navbar.ipportal.dev.web1.wipo.int", // PRD components
		appUrl: "https://website.gbd.globaldb.dev.web1.wipo.int", // used in WIPO Navbar
		backendUrl: "https://api.gbd.globaldb.dev.web1.wipo.int",
		imgBaseUrl: "https://images.gbd.globaldb.dev.web1.wipo.int", // no trailing '/'
		legacyWebsiteUrl : "https://legacy.branddb.wipo.int/branddb/en/index.jsp",
		FAQUrl : "https://www.wipo.int/reference/{lang}/branddb/faqs_branddb.html",
		ssoUseridUrl: 'https://www5.wipo.int/sso/userid',	
	},
	gdd: {
		env: "awsDev",
		production : false,
		wipoComponentsPath: "https://cdn.ipp-navbar.ipportal.dev.web1.wipo.int", // PRD components
		appUrl: "https://website.gdd.globaldb.dev.web1.wipo.int", // used in WIPO Navbar
		backendUrl: "https://api.gdd.globaldb.dev.web1.wipo.int",
		imgBaseUrl: "https://images.gbd.globaldb.dev.web1.wipo.int", // no trailing '/'
		legacyWebsiteUrl : "https://legacy.desgindb.wipo.int/desgindb/en/index.jsp",
		FAQUrl : "https://www.wipo.int/reference/{lang}/branddb/faqs_branddb.html",
		ssoUseridUrl: 'https://www5.wipo.int/sso/userid',
	}
};

import { AuthConfig } from "angular-oauth2-oidc"

const authConfigGBD: AuthConfig = {
	clientId: "oidcGBD",
	oidc: true,
	timeoutFactor: .75, // Refreshes the token after 75% of its lifetime is over

	//oidc.discovery.url=https://logindev.wipo.int/am/oauth2/.well-known/openid-configuration
	issuer: "https://logindev.wipo.int:443/am/oauth2", // Url of the Identity Provider
	redirectUri: `${environment.gbd.appUrl}`, // URL of the SPA to redirect the user to after login

	// loginUrl:	`${environment.appUrl}`,
	// logoutUrl:	`${environment.appUrl}/logout`,
	silentRefreshRedirectUri: `${environment.gbd.appUrl}/silent-refresh.html`,

	responseType: 'id_token token',
	postLogoutRedirectUri: `${environment.gbd.appUrl}/logout`, // URL of the SPA to redirect the user to after logout

	sessionChecksEnabled: true,
	showDebugInformation: true,
	skipIssuerCheck: true,
	strictDiscoveryDocumentValidation: false,

	// set the scope for the permissions the client should request
	// The first four are defined by OIDC.
	// Important: Request offline_access to get a refresh token
	scope: 'openid profile email',
}

const authConfigGDD: AuthConfig = {
	clientId: "oidcGBD",
	oidc: true,
	timeoutFactor: .75, // Refreshes the token after 75% of its lifetime is over

	//oidc.discovery.url=https://logindev.wipo.int/am/oauth2/.well-known/openid-configuration
	issuer: "https://logindev.wipo.int:443/am/oauth2", // Url of the Identity Provider
	redirectUri: `${environment.gdd.appUrl}`, // URL of the SPA to redirect the user to after login

	// loginUrl:	`${environment.appUrl}`,
	// logoutUrl:	`${environment.appUrl}/logout`,
	silentRefreshRedirectUri: `${environment.gdd.appUrl}/silent-refresh.html`,

	responseType: 'id_token token',
	postLogoutRedirectUri: `${environment.gdd.appUrl}/logout`, // URL of the SPA to redirect the user to after logout

	sessionChecksEnabled: true,
	showDebugInformation: true,
	skipIssuerCheck: true,
	strictDiscoveryDocumentValidation: false,

	// set the scope for the permissions the client should request
	// The first four are defined by OIDC.
	// Important: Request offline_access to get a refresh token
	scope: 'openid profile email',
}

export const authConfig = {
	gbd: authConfigGBD,
	gdd: authConfigGDD
}
