import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { GBDExportComponent } from '../../../gbd/components/comp-export/export.component';
import { GDDExportComponent } from '../../../gdd/components/comp-export/export.component';
import { instanceType } from '../../utils';
import { MechanicsService } from '../../_services/mechanics.service';

@Component({
	selector: 'page-export',
	templateUrl: './page-export.component.html',
	styleUrls: ['./page-export.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageExportComponent implements OnInit {

	contentComponent: any;


	constructor(
		public ms: MechanicsService) {

	}
	ngOnInit() {
		if(instanceType()=='gbd')
			this.contentComponent = GBDExportComponent;
		else
			this.contentComponent = GDDExportComponent
	}
}
