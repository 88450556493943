
declare const CryptoJS: any;
const orig_key = "8?)i_~Nk6qv0IX;2";

let l = `utils() - `

import path from "path";
import { GBDDocument } from "./interfaces";
import ObjectID from "bson-objectid"
import { devInstanceType, environment } from "src/environments/environment";


export const instanceType = ():string => {
	const paths = window.location.href.split('/')
	const domain = paths[2]
	const first_path = paths[3] || ''
	for( let s of ['gbd', 'brand']){
		if ((domain +'/'+ first_path).includes(s)){
			return 'gbd'
		}
	}
	if(devInstanceType){
		return devInstanceType
	}
	return 'gdd'
}

export const setPrevEnpoint = (v:string):void=> {
	sessionStorage.setItem(`${instanceType()}.prev_enpoint`, v)
}

export const prevEndpoint = ():string => {
	return sessionStorage.getItem(`${instanceType()}.prev_enpoint`)
}


export const canSearch = (v: any): boolean => {

	const l = `canSearch() - `
	/*
		Function developed here : https://codepen.io/jeremythille/pen/abjWaaz
	*/

	if (v === undefined) return false;
	
	if(typeof(v)==="boolean") return true; // Users can't type booleans in input fields (they're always strings) but these can be checkboxes

	if (Array.isArray(v)) {
		if (!v.length) return false;
		return v.every(canSearch);
	}
	
	if (v.label && v.label.length) {
		// WOption
		// console.log(`${l}That's a WOption, returning true`)
		return true
	}

	if(v.includes("**")) return false;

	if ((v.match(/\*/g) || []).length > 2) return false;

	v = v
		.trim()
		.toLowerCase()
		.replace(/ *\* */g, "*"); // Replacing * surrounded with spaces with only "*";

	if (!v.length) return false;

	const splitted: string[] = v.split(" ");

	if (splitted.some(word => word.startsWith("*") && word.endsWith("*"))) {
		return false;
	}

	return splitted.every(word => !word.includes("*") || (word.length > 2 && /[^*]{2}/.test(word)));
};


export const tooltipKey = (wrongKey:string): string => ({
	// Tooltips's i18n was created by Aida, with arbitrary named keys, that don't match the variable names we use. And I can't rename the keys because of Smartling. This adds one layer of complexity, we have to match tooltip's keys names with actual keys names... -_-
	"Fuzzy": "search_strategy_fuzzy",
	"Phonetic": "search_strategy_phonetic",
	"Simple" : "search_strategy_embedded",
	"Stemming": "search_strategy_stemming",
	"Terms":"search_strategy_exact_match",
	"Exact": "search_strategy_embedded",
	"appDate": "application_date",
	"applicant": "owner",
	"designation": "designation_country",
	"expDate":"termination_date" ,
	"office": "ip_office",
	"feature" : "markFeature",
	"regDate": "registration_date",
	"termDate": "expiry_date",
	"usDesignClass": "us_design_classification",
	"viennaClass": "vienna_classification",
})[wrongKey] || wrongKey;

const strategiesMappings = {
	applicant: "Exact",
	brandName: "Exact", // "Exact" actually acts as "contains" ... until it is fixed in Solr
	designer: "Exact",
	class_ca: "Exact",
	class_jp: "Exact",
	class_us: "Exact",
	class_locarno: "Exact",
	productIndication: "Exact", // "Exact" actually acts as "contains" ... until it is fixed in Solr
	description: "Exact",
	goodsServices: "", // "" or "Terms" for exact match
	niceClass: "all_of",
	usDesignClass: "all_of",
	viennaClass: "all_of",
	office: "any_of",
	designation: "any_of",
	regDate: "Min",
	number: "Either",
	representative: "Exact"
};

export const bricks2AsStructure = (bricks: any, preprocessor:any = null): any => {

	const l = `utils.bricks2AsStructure() - `

	// console.log(`${l}got bricks = `, bricks)

	/*

		New 2023-04-19 : I want to drop the linear queryParams and use asStructure (that I've developed for AdvancedSearch) everywhere. I need a utility that outputs an asStructure.

		receiving bricks :
		_______________________

		{
			"brandName": "apple",
			"brandNameStrategy": "Fuzzy",  // <-- This is not a search field. It must not be sent as a separate tkObject by the backend, but must be used to specify which strategy to use for the brandName field, then disappear
			"applicant": "Steve Jobs",
			"niceClass": [
				{
					"value": "10",
					"label": "10",
					"label2": "10 - Surgical, medical, dental and veterinary apparatus and instruments, artificial limbs, eyes and teeth; orthopedic articles; suture materials"
				},
				{
					"value": "20",
					"label": "20",
					"label2": "20 - Furniture, mirrors, picture frames; goods (not included in other classes) of wood, cork, reed, cane, wicker, horn, bone, ivory, whalebone, shell, amber, mother-of-pearl, meerschaum and substitutes for all these materials, or of plastics"
				}
			],
			"goodsServices": "computers"
		}

		returning asStructure :
		_______________________

		{
			"_id": "3c76",
			"boolean": "AND",
			"bricks": [
				{
					"_id": "4904",
					"key": "brandName",
					"value": "apple",
					"strategy": "Fuzzy"
				},
				{
					"_id": "a6aa",
					"key": "applicant",
					"value": "Steve Jobs",
					"strategy": "Exact"
				},
				{
					"_id": "8184",
					"key": "niceClass",
					"value": [
						{
							"value": "10",
							"label": "10",
							"label2": "10 - Surgical, medical, dental and veterinary apparatus and instruments, artificial limbs, eyes and teeth; orthopedic articles; suture materials"
						},
						{
							"value": "20",
							"label": "20",
							"label2": "20 - Furniture, mirrors, picture frames; goods (not included in other classes) of wood, cork, reed, cane, wicker, horn, bone, ivory, whalebone, shell, amber, mother-of-pearl, meerschaum and substitutes for all these materials, or of plastics"
						}
					],
					"strategy": "all_of"
				},
				{
					"_id": "3672",
					"key": "goodsServices",
					"value": "computers",
					"strategy": "Embedded" }
			]
		}
	*/
	if(preprocessor){
		bricks = preprocessor(bricks)
	}
	let asStructure = {
		_id: generateId(),
		boolean: "AND",
		bricks: []
	};

	// Keys used only in the UI, not used in nor sent to the server
	const keysToIgnore = ["reportName", "format", "isExactMatch", "isMatchExact", "isYoungerThan", "maxAgeMonths", "v", "searchBy", "rows","start", "sort", "_"];

	for (let key of Object.keys(bricks)) { // key = "brandName", "brandNameStrategy", "applicant", "niceClass"
		if(typeof bricks[key] === "object" && Object.keys(bricks[key]).includes('_id')){
			asStructure.bricks.push(bricks[key])
			continue
		}
		// console.log(`${l}treating key '${key}'`)

		if (key.endsWith && /strategy$/i.test(key) || keysToIgnore.includes(key)) {
			// fields that end in -Strategy are not a search field, it is used to specify/overwrite the default strategy used for the field it references (brandName), then disappear. Not pushing it to the bricks
			continue;
		}

		let brick = {
			_id: generateId(),
			key,
			value: bricks[key],
			strategy : (typeof(strategiesMappings[key]) !== "undefined") ? strategiesMappings[key] : bricks.strategy
		}

		if(key==="by"){
			// by="brandName", v="apple"
			brick.key = bricks.by // "brandName"
			brick.value = bricks.v // apple
			brick.strategy = strategiesMappings[bricks.by]
		}

		if (typeof (brick["strategy"]) === "undefined") {
			throw new Error(`${l}Dunno what strategy to use with '${bricks.by || key}'!`)
		}

		if (bricks[`${key}Strategy`]) {
			// a bit hacky, but the "brandNameStrategy" field is not a search field in Solr. It must not be sent as a brick. It must be used to specify/overwrite the default strategy used for the field it references (brandName), then disappear.
			brick["strategy"] = bricks[`${key}Strategy`]; // setting "Fuzzy" instead of the default "Exact"
		}

		if (!brick.value || !brick.value.length) {
			// console.log(`${l}brick.value==='${brick.value}', skipping`)
			continue;	// just skipping empty optional fields
		
		}

		// console.log(`${l}Pushing brick to asStructure : `, brick)
		asStructure.bricks.push(brick)
	}

	// console.log(`${l}returning asStructure = `, asStructure)

	return asStructure
}


export const asStructure2bricks = (asStructure: any): any => {

	const l = `asStructure2bricks - `

	// console.log(`${l}received asStructure = `,deepClone(asStructure))

	try {
		asStructure = JSON.parse(asStructure)
	} catch (err) {
		// Already parsed
	}

	let bricks: any = {};

	for (let brick of asStructure.bricks) {
		bricks[brick.key] = brick.value

		if (brick["strategy"]) {
			// This was lost info. The "brandNameStrategy" is voluntarily lost in bricks2AsStructure, because it is not a Solr field. Instead, it is integrated inside the "brandName" brick, then disappears. Here I'm doing the reverse operation and recreating "brandNameStrategy" (when we click on "Edit query", I need this information back)
			// Downside : this also creates "applicantStrategy", "niceClassStrategy", etc. which are useless, but nevermind, I guess
			bricks[`${brick.key}Strategy`] = brick["strategy"]
			// console.log(`${l}Have restored lost info : bricks['${brick.key}Strategy']=='${brick["strategy"]}'.`)

			// Same thing for the "isMatchExact" checkbox, whose value is lost when convertingt to asStructure. If it's checked, then the strategy is "Terms". If it's unchecked, the strategy is "". I'm reversing this process here so as to get the "isMatchExact" info back.
			if(bricks[`${brick.key}Strategy`]==="Terms"){
				bricks[`isMatchExact`] = true;
				// console.log(`${l}Have restored lost info : bricks['isMatchExact']==true.`)
			}
		}
	}

	// console.log(`${l}returning bricks = `, bricks)

	return bricks
}

export const deduplicateStringArray = (input: string[]): string[] => input.filter((item, pos, self) => self.indexOf(item) === pos);

export const decrypt = (crypted: any): any => {

	const l = `decrypt() - `

	/*
		Passed data should be a string.
		Will attempt to decrypt it.
		If can't decrypt, sends back the data as is.
		This makes the Angular app accept encrypted and non-encrypted responses from the server.
	*/

	// console.log(`${l}Received data to decrypt (type='${typeof(crypted)}') = `, deepClone(crypted))

	if (typeof (crypted) !== "string") {
		// console.log(`${l}Data to decrypt = `, crypted)
		// console.log(`${l} - Data to decrypt is not a string, skipping`)
		return crypted
	}

	// On Localhost, the data may not be encrypted, but the ResponseType is still "string", so we'll get a stringified JSON anyway. I need to simply parse it, without decryption

	let decrypted;

	try {
		decrypted = JSON.parse(crypted);
		console.log(decrypted)
		return decrypted;
	} catch (err) {
		// console.log(`${l}Could not parse response directly as JSON, attempting to decrypt it.`)
	}

	// OK so now, I very likely have an encrypted string.

	// console.time(`${l}decryption took`)

	let originalStringified: string;

	try {
		let key = CryptoJS.enc.Utf8.parse(orig_key + localStorage.getItem(`${instanceType()}.hashSearches`) || "" )
		const bytes: any = CryptoJS.AES.decrypt(crypted, key, {mode:CryptoJS.mode.ECB});

		originalStringified = bytes.toString(CryptoJS.enc.Utf8);

		// console.log(`${l}originalStringified=`, originalStringified)

		decrypted = JSON.parse(originalStringified);

	} catch (err) {
		// console.log(`${l}Could not JSON.parse decrypted content. Probably not an object. Sending back decrypted string as is.`)
	}

	// console.timeEnd(`${l}decryption took`)

	return decrypted || originalStringified
}

export const deepClone = (obj, maxArrayLength = Infinity) => { // https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object

	const l = `utils.deepClone() - `
	// console.log(`${l}deepcloning : `, obj)

	if(typeof(structuredClone)!=="undefined"){
		return structuredClone(obj) // Native JS method, available in Node 17+
	}

	let copy;
	// console.log(`Deepcloning (reduce ${reduce})`);

	// Handle strings
	if (typeof (obj) === "string") {
		return "" + obj;
	}

	// Handle the 3 simple types, and null or undefined
	if (!obj || (`object` != typeof obj)) {

		// console.log(`${l}passed object is not an object! typeof(obj)='${typeof(obj)}'`)

		return obj;
	}

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = [];

		const maxElems: number = Math.min(maxArrayLength, obj.length)

		for (let i = 0; i < maxElems; i++) {
			copy[i] = deepClone(obj[i], maxArrayLength);
		}

		if (maxArrayLength && obj.length > maxElems) copy.push(`(${obj.length - maxElems} more elements)`);

		return copy;
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {};
		const totalKeysCount = Object.keys(obj).length
		const maxKeys: number = Math.min(maxArrayLength, totalKeysCount)
		let keysCount: number = 0;

		for (let attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = deepClone(obj[attr], maxArrayLength);
			keysCount++;
			if (maxArrayLength && keysCount > maxKeys) break;
		}

		if (maxArrayLength && totalKeysCount > maxKeys) copy["(Unshown keys)"] = totalKeysCount - maxKeys

		return copy;
	}

	throw new Error(`Unable to copy obj! Its type isn't supported.`);

}

export const generateId = (): string => ObjectID().toHexString().substring(20, 24); // The beginning of the 24 characters don't change. Only the last letter is incremented. I'm only keeping the last few characters so as to have shorter _id in the AdvancedSearch tree.

export const getImageDimensions = (src: string): Promise<{ w: number, h: number }> => {
	return new Promise((resolved, rejected) => {
		const i = new Image()
		i.onload = () => {
			resolved({ w: i.width, h: i.height })
		};
		i.onerror = rejected;
		i.onabort = rejected;
		i.src = src; // can be a URL or base64
	})
}

const resizeCanvas = document.createElement('canvas');

export const resizeImage = async (image: HTMLImageElement | string, maxSideLength: number = Infinity): Promise<string> => {

	const l = `utils resizeImage() - `

	/*
		Accepts an HTMLImageElement or base64 as input
		Returns base64

		Resizing is optional, you can pass an HTMLImageElement and it will simply return its base64 without resizing
	*/

	if (typeof (image) === "string") { // Converting Base64 into HTMLImageElement

		const base64Input = "" + image;

		image = <HTMLImageElement>new Image();

		await new Promise((resolve, reject) => {
			image = image as HTMLImageElement;
			image.onload = resolve;
			image.src = base64Input;
		})
	}

	if ((image.height > maxSideLength) || (image.width > maxSideLength)) {

		// Image is too big, it needs to be resized down

		resizeCanvas.width = resizeCanvas.height = 0 + maxSideLength;

		// set size proportional to image
		if (image.width > image.height) {
			;
			resizeCanvas.height = resizeCanvas.width * (image.height / image.width)
		} else {
			resizeCanvas.width = resizeCanvas.height * (image.height / image.width);
		}

	} else {
		// I don't want to enlarge the image. If the image is smaller than the maximum set dimension, then I reduce the resizeCanvas to match the image.
		resizeCanvas.height = image.height;
		resizeCanvas.width = image.width;
	}

	// console.log(`\nresizeCanvas dimensions for resize : width = ${resizeCanvas.width}, height=${resizeCanvas.height}`);

	const ctx = resizeCanvas.getContext('2d', { willReadFrequently: true });
	ctx.drawImage(image, 0, 0, resizeCanvas.width, resizeCanvas.height);

	const resizedBase64: string = resizeCanvas.toDataURL('image/png')

	// console.log(`${l} Resized Base64 length = `, resizedBase64.length)

	return resizedBase64
}
