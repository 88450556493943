import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WOption } from '@wipo/w-angular/shared';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { canSearch, instanceType, tooltipKey } from 'src/app/commons/utils';


@Component({
	selector: 'page-advancedSearch',
	templateUrl: './page-advancedSearch.component.html',
	styleUrls: ['./page-advancedSearch.component.css'],
	encapsulation: ViewEncapsulation.None

})
export class PageAdvancedSearchComponent implements OnInit {

	public structure
	public structure2 
	public ev1 = false
	public sortOptions: WOption[] = []
	public strategies: WOption[] = []

	constructor(public ms: MechanicsService,
		public qs: QueryParamsService) {

		const l = `advancedSearch component constructor - `
		this.ms.setEndpoint("advancedsearch")
	}

	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem(`${instanceType()}.hashSearches`)
	}

	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}	

	ngOnInit(): void {
		const l = `pageAdvancedSearch ngOnInit() - `
		this.buildStuff()
	}

	public is_delimiter_visible: boolean = false

	toggle_is_delimiter_visible() {
		this.is_delimiter_visible = true
	}

	removeImageChangeSort() {
		this.qs.setQP('sort', 'score desc')
	}

	onChangeSort(event) {
		if (this.qs.getQP('sort') != 'image_similarity') {
			this.ms.bases64 = null
			sessionStorage.removeItem(`${instanceType()}.ms.bases64`)
		}
	}

	buildStuff() {
		const l = `buildStuff() - `
		if (this.ms.bases64.length > 0) {
			this.qs.setQP('sort', 'image_similarity')
		}
		this.buildStructure()
		this.buildOptions()

		if(instanceType()!= 'gbd'){
			this.qs.queryParams[ this.ms.endpoint ].strategy = 'concept'
		}
	}

	get parent() {
		return this
	}

	buildStructure() {

		const l = `page-advancedSearch buildStructure() - `

		// console.log(`${l}this.qs.queryparams = `, deepClone(this.qs.queryParams))

		this.structure = this.qs.getQP("asStructure")

		// console.log(`${l}this.structure = `, deepClone(this.structure))

		// if coming back to this page from results page
		// remove facets
		this.qs.keepOnlySearchParams();

		const asStructure = this.qs.getQP("asStructure");
		// console.log(`${l}found asStructure in queryParams = `, asStructure)

		try {
			this.structure = JSON.parse(asStructure)
			// console.log(`${l}Parsed structure = `, this.structure)
		} catch (err) {
			console.log(`${l}Could no parse structure - caught error : `, err)
		}

	}

	buildOptions() {

		const l = `buildOptions() - `

		this.sortOptions = [
			"image_similarity",
			"score desc",
			"score asc",
			"applicationDate desc",
			"applicationDate asc",
			"st13 desc",
			"st13 asc",
		].map((value => ({
			value,
			label: this.ms.translate(("search_results_menu.sort_" + value))
		})))
		if(instanceType() == 'gbd'){
			this.strategies = [
				{ value: "concept", label: "", tip: "" },
				{ value: "shape", label: "", tip: "" },
				{ value: "color", label: "", tip: "" },
				{ value: "composite", label: "", tip: "" },
			].map(obj => {
				obj.label = this.ms.translate(`page_similarlogo.${obj.value}`);
				obj.tip = this.ms.translate(`tooltips.${tooltipKey(obj.value)}`);
				return obj
			})
		}
		else{
			this.strategies = [
				{ value: "concept", label: "", tip: "" },
			].map(obj => {
				obj.label = this.ms.translate(`page_similarlogo.${obj.value}`);
				obj.tip = this.ms.translate(`tooltips.${tooltipKey(obj.value)}`);
				return obj
			})
		}

	}

	onEnterKey() {
		const l = `onEnterKey() - `

		// console.log(`${l}`)

		if (!this.isSearchButtonActive) {
			// console.log(`${l}Prevented search - Structure is not valid`)
			return
		}

		this.search();
	}

	async search() {
		const l: string = `PageQueryBuilder search() - `;
		delete this.structure['needle']
		this.qs.setQP("asStructure", JSON.stringify(this.structure)) // Then, toPayload() will take the whole structure and pass it to ss.search(payload)
		this.qs.setQP("fg", "_void_")

		const route: string = this.ms.makeRoute({ subpath: 'results', caller: l })

		// Updates the URL accordingly without reloading the page.
		// Changing the query params in the URL will trigger a subscription in HOCSearchComponent ,
		// which in turn will automatically call SearchService.search() and refresh the results.
		await this.qs.queryParamsObjectToUrl(route)
	}

	get isSearchButtonActive(): boolean { // "canSearch" is already taken
		const l = `isSearchButtonActive - `
		return this.isBrickValid(this.structure)
	}

	canSearchWithException(brick: any) {
		if (brick.key == 'brandName' && brick.strategy == 'Terms') {
			return true
		}
		return canSearch(brick.value)
	}

	isBrickValid(brick): boolean {
		const l = `isBrickValid - `

		const isValid: boolean = brick.bricks ?
			brick.bricks.every(b => this.isBrickValid(b)) : // every() will stop evaluating as soon as one of the bricks returns false
			this.canSearchWithException(brick)

		// console.log(`${l}brick=`, brick)
		// console.log(`${l}canSearch("${brick.value}") = ${canSearch(brick.value)}`)
		// console.log(`${l}isValid? ${isValid}`)

		return isValid
	}
}
