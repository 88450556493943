/*

  this service does just one thing. If you call buildFields(doc), it will extract and transform all kind of info from the doc and store them in this.fieds, in an easily accessible way. Then, this.fields is accessible in templates or as public JS object
*/



import { Injectable } from '@angular/core';
import { Entity, LangTextField, SuggestQueryParams, SuggestSolrResponse } from '../../commons/interfaces';
import { MechanicsService } from '../../commons/_services/mechanics.service';
import { SearchService } from '../../commons/_services/search.service';

const woMapping: any = {
	'WO50': 'wotm',
	'WO81': 'woao',
	'WO80': 'wo6ter'
}

const woNiceMapping:any ={
	1963:'1',
	1971:'2',
	1981:'3',
	1983:'4',
	1987:'5',
	1992:'6',
	1997:'7',
	2002:'8',
	2007:'9',
	2012:'10',
	2017:'11',
	2023:'12'
}

const woViennaMapping:any ={
	1973:'1',
	1988:'2',
	1993:'3',
	1998:'4',
	2003:'5',
	2008:'6',
	2013:'7',
	2018:'8',
	2023:'9'
}

const defaultMapping: any = {
	'applicationNumber': { inid: '210', handler: 'text' },
	'registrationNumber': { inid: '111', handler: 'text' },
	'kind': { inid: '551', handler: 'list' },
	'markFeature': { inid: '550', handler: 'text' },
	'filingPlace': { inid: '250', handler: 'text' },
	'designatedCountries': { code: 'designation', handler: 'listcountries' },
	'officeStatus': { code: 'office_status', handler: 'text' },

	'applicationLanguageCode': { inid: '270', handler: 'text' },

	'statusDate': { code: 'status_date', handler: 'date' },
	'applicationDate': { inid: '220', handler: 'date' },
	'publicationDate': { inid: '450', handler: 'date' },
	'registrationDate': { inid: '151', handler: 'date' },
	'expiryDate': { inid: '180', handler: 'date' },
	'terminationDate': { inid: '141', handler: 'date' },

	'markDisclaimerDetails': { inid: '526', handler: 'langtext' },
	'markDescriptionDetails': { inid: '571', handler: 'langtext' },

	// complex type mapping
	'applicants': { handler: 'applicants' },
	'representatives': { handler: 'representatives' },
	'correspondence': { handler: 'correspondence' },
	'priorities': { handler: 'priorities' },
	'reference': { handler: 'reference' },
	'markImageDetails': { handler: 'markimagedetails' },
	'wordMarkSpecification': { handler: 'wordmarkspecification' },

	// goods and services
	'goodsServicesClassification': { handler: 'gsclassification' },
	'nationalGoodsServicesClassification': { handler: 'gsnationalclassification' },
	'goodsServicesUnclassified': { inid: '510', handler: 'gsunclassifed' },
	'logo':  { inid: '540', handler: 'logo' },
	// Special cases
	'wotm': { handler: 'wotm' },
	'wo6ter': { handler: 'wo6ter' },
	'woao': { handler: 'woao' }
}


@Injectable({
	providedIn: 'root'
})
export class GBDDoc2fieldsService {

	public doc: any;
	public fields: any;

	classificationsCache = {};

	constructor(public ms: MechanicsService,
		public ss: SearchService) {

	}

	buildFields(doc: any = this.doc): void {// Not sure what the actual type is

		const l = `pageDetails buildFields() - `

		if (!doc) {
			// console.log(`${l}No doc (yet?)`); // The "change language" event triggers too early, as the doc isn't loaded yet. I'm just skipping while there's no doc yet
			return
		}

		this.doc = doc;

		this.fields = this._toFields(doc, defaultMapping); // Recursive method that builds this.fields, which is then accessible in templates or as public JS object

		// console.log(`${l}end - this.fields=`, this.fields)
	}


	/*
	async classification2human(word: string): Promise<string> {

		const l = `classification2human - `;

		let params: SuggestQueryParams = {
			what: "vienna",
			word
		}

		const suggestSolrResponse: SuggestSolrResponse = await lastValueFrom(this.ols.suggest(params));

		const suggestions = suggestSolrResponse.suggestions;

		return suggestions[0].term
	}
	*/

	// One Entity to fields
	_toFields(entity: Entity, mappings: any): any {

		const l = `pageDetails _toFields - `

		/* arguments : 

			entity = {
				"markVerbalElement": [
					{
						"text": "FRAWEI FourmisRobot",
						"languageCode": "fr"
					},
					{
						"text": "HUAWEI AntRobot",
						"languageCode": "en"
					}
				]
			}


			mappings = {
				"markVerbalElement": {
					"inid": "540",
					"handler": "langtext",
					"multi": true
				},
				"markSignificantVerbalElement": {
					"inid": "541",
					"handler": "langtext"
				},
				"markTranslation": {
					"inid": "566",
					"handler": "langtext"
				},
				"markTransliteration": {
					"inid": "561",
					"handler": "text"
				}
			}
		*/

		// console.log(`${l}entity = `, entity, `mapping = `, mappings);

		let fields: any = {}
		// iterate over this.doc.
		// for every key, call _key (if found) and append lines to this.lines
		Object.entries(mappings).forEach(([key, mapping]) => {

			// key = 'fullAddress'
			// mapping = { code: 'address', handler: 'langtext' }
			try {
				// fields['fullAddress'] = this._text_handler( doc['fullAddress'] ,{ code: 'address', handler: 'langtext' })
				fields[key] = this[`_${mapping['handler']}_handler`](entity[key], mapping) // entity[key] : string | LangTextField[] | Object
			} catch (err) {
				console.error(`${l}`, err);
				// console.log(`${l}key=`, key)
				// console.log(`${l}mapping=`, mapping)
			}
		})

		/*
			returning = {
				"fullName": {
					"code": "731",
					"label": "Applicant information",
					"content": "GUERNET COMPRESSEURS, SARL"
				},
				"kind": {
					"label": "Kind"
				},
				"identifier": {
					"label": "Identifier"
				},
				"fullAddress": {
					"label": "Address",
					"content": "51 route de Montargis, 89300 JOIGNY, FR"
				},
				"countryCode": {
					"label": "Country",
					"content": "France"
				}
			}
		*/

		return fields
	}

	_applicants_handler(entities: Entity[] = []): any {
		// applicants: #inid (730)
		//   - identifier:
		//     kind: #(Natural Person|Legal Entity|Other)
		//     fullName:
		//       - languageCode:
		//         text:
		//     fullAddress:
		//       - languageCode:
		//         text:
		//         rasterized :
		//     countryCode:

		const l = `_applicants_handler - `

		let mapping = {
			'fullName': {
				code: 'name',
				handler: 'langtext'
			},
			'kind': { code: 'kind', handler: 'text' },
			'identifier': { code: 'identifier', handler: 'text' },
			'fullAddress': { code: 'address', handler: 'langtext' },
			'countryCode': { code: 'country', handler: 'country' }
		}

		return entities.map(entity => this._toFields(entity, mapping))
	}


	_logo_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: ' '
		}
	}

	_representatives_handler(entities: Entity[] = []): any {
		//   representatives: #inid (740)
		//      - identifier:
		//        kind: #(Natural Person|Legal Entity|Other)
		//        fullName:
		//          - languageCode:
		//            text:
		//        fullAddress:
		//          - languageCode:
		//            text:
		//            rasterized:
		//        countryCode:
		let mapping = {
			'fullName': { code: 'name', handler: 'langtext' },
			'kind': { code: 'kind', handler: 'text' },
			'identifier': { code: 'identifier', handler: 'text' },
			'fullAddress': { code: 'address', handler: 'langtext' },
			'countryCode': { code: 'country', handler: 'country' }
		}

		return entities.map(entity => this._toFields(entity, mapping))
	}

	_correspondence_handler(entity: Entity): any {
		// correspondence: #inid (750)
		//   fullName:
		//     - languageCode:
		//       text:
		//   fullAddress:
		//     - languageCode:
		//       text:
		//   countryCode:
		let mapping = {
			'fullName': { code: 'name', handler: 'langtext' },
			'fullAddress': { code: 'address', handler: 'langtext' },
			'countryCode': { code: 'country', handler: 'country' }
		}

		return this._toFields(entity || {}, mapping)
	}

	_woao_handler(not_used: Entity = {}): any {
		let entity = this.doc
		if (woMapping[entity['st13'].substring(0, 4)] != 'woao') {
			entity['woao'] = {}
			return entity
		}
		entity['events_woao'] = entity['events']

		try {
			let extra = entity['extra']
			entity['pdf'] = extra['pdf']
		}
		catch (err) {
		}

		let mapping = {
			'events_woao': { handler: 'events' },
			'pdf': { handler: 'link' }

		}
		return this._toFields(entity, mapping)
	}

	_events_handler(entities: Entity[] = []): any {
		return entities.map(item => this._event_handler(item))
	}

	_event_handler(entity: Entity = {}): any {
		let content = entity['date']
		if (entity['country']) {
			content = entity['country'] + ' - ' + content
		}
		if (entity['doc']) {
			content = content + ' <a href="' + entity['doc'] + '" target="_blanc">PDF</a>'
		}
		let toReturn = {
			label: entity['officeKind'],
			content: content
		}

		return toReturn
	}


	_wo6ter_handler(not_used: Entity = {}): any {
		let entity = this.doc
		if (woMapping[entity['st13'].substring(0, 4)] != 'wo6ter') {
			entity['wo6ter'] = {}
			return []
		}


		try {
			let extra = entity['extra']
			entity['circulars'] = extra['circulars']
		}
		catch (err) {
		}

		return entity['circulars'].map(item => this._circular_handler(item))
	}



	_circular_handler(entity: Entity = {}): any {
		let content = entity['number']
		if (entity['country']) {
			content = entity['country'] + ' - ' + content
		}
		if (entity['pdf']) {
			content = '<a href="' + entity['pdf'] + '" target="_blanc">' + content + '</a>'
		}
		if (entity['date']) {
			content = content + ' - ' + this.__dateValue(entity['date'])
		}
		let toReturn = {
			label: this.__inidToLabel("circular"),
			content: content
		}

		return toReturn
	}
	_wotm_handler(not_used: Entity = {}): any {
		let entity = this.doc
		if (woMapping[entity['st13'].substring(0, 4)] != 'wotm') {
			entity['wotm'] = {}
			return entity
		}

		if(entity.gbdStatus == 'Registered'){
			entity.gbdStatus = 'RegisteredMadrid'
		}

		try {
			let ref = entity['reference']
			entity['basic_application'] = ref['office'] + ', ' + ref['application'][0]['date'] + ', ' + ref['application'][0]['number']
			entity['date_priority'] = ref['office'] + ', ' + ref['application'][0]['date'] + ', ' + ref['application'][0]['number']
		}
		catch (err) { }

		try {
			let extra = entity['extra']
			entity['markUseIntentCountries'] = (extra['markUseIntentCountries'] || []).join(", ")
			entity['madridDesignatedCountrie'] = (extra['madridDesignatedCountrie'] || []).join(", ")
			entity['madridDesignatedCountrie96'] = (extra['madridDesignatedCountrie9.6'] || []).join(", ")
			entity['holderEstablishmentCC'] = extra['holderEstablishmentCC']
			entity['holderNationtalityCC'] = extra['holderNationtalityCC']
			entity['legalNature'] = extra['legalNature'][0]['type'] + ', ' + extra['legalNature'][0]['state']
		}
		catch (err) {
		}

		let mapping = {
			'basic_application': {
				inid: '821',
				handler: 'text'
			},
			'date_priority': {
				inid: '300',
				code: '300_Madrid',
				handler: 'text',
			},
			'markUseIntentCountries': {
				inid: '527',
				handler: 'text',
			},
			'madridDesignatedCountrie96': {
				inid: '834',
				handler: 'text',
			},
			'madridDesignatedCountrie': {
				inid: '832',
				handler: 'text',
			},
			'holderEstablishmentCC': {
				inid: '812',
				handler: 'text',
			},
			'holderNationtalityCC': {
				inid: '811',
				handler: 'text',
			},
			'legalNature': {
				inid: '842',
				handler: 'text',
			}
		}
		return this._toFields(entity, mapping)
	}

	_reference_handler(entity: Entity = {}): any {
		// referece:
		//   office:
		//   application:
		//     - number:
		//       date:
		//   registration:
		//     - number:
		//       date:


		let mapping = {
			'office': { code: 'office_of_authority', handler: 'text' },
			'application': { handler: 'reference_doc' },
			'registration': { handler: 'reference_doc' },
		}
		return this._toFields(entity, mapping)
	}


	_reference_doc_handler(entities: Entity[] = []): any {
		let mapping = {
			'number': { code: 'number', handler: 'text' },
			'date': { code: 'date', handler: 'date' }
		}

		return entities.map(item => this._toFields(item, mapping))
	}

	_gsclassification_handler(entity: Entity = {}): any {
		// goodsServicesClassification:
		//   kind: Nice #inid (511)
		//   version:
		//   class:
		//     - code:
		//       terms:
		//         - {{ languageCode  }}:
		//           - str

		const classes = {
			code: '511',
			label: this.__inidToLabel('511') + " - NCL" + (entity['version'] !== undefined ? '('+entity['version']+')': ''), 
			content: this.__joinArray((entity['class'] || []).map(item => item.code))
		}
		const terms = (entity['class'] || []).map(item => ({
			code: '-',
			label: item.code + '',
			content: this.__termsValue(item.terms || [])
		}));
		if(this.doc.registrationOfficeCode == 'WO'){
			let computed_version: string
			try{
				let year = (this.doc.registrationDate || '-').split('-')[0]
				for(let year_classification in woNiceMapping){
					if (year_classification <= year){
						computed_version = woNiceMapping[year_classification]
						continue
					}
					break
				}
			}
			catch(_err){
				// console.log(_err)

			}
			if (!entity['version']){
				entity['version'] = computed_version
			}
		}
		const version = entity['version'] || ''
		return { classes, terms, version }
	}


	_gsnationalclassification_handler(entity: Entity = {}): any {
		// nationalGoodsServicesClassification:
		//   kind: KIPO # for eg.
		//   version:
		//   class:
		//     - code:
		//       terms:
		//         - {{ languageCode }}:
		//           - str

		const classes = {
			label: this.__inidToLabel('national_classification'),
			content: this.__joinArray((entity['class'] || []).map(item => item.code))
		};

		const terms = (entity['class'] || []).map(item => ({
			label: item.code + '',
			content: this.__termsValue(item.terms || [])
		}));
		return { classes, terms };
	}

	_gsunclassifed_handler(content: [], mapping: any): any {
		return  {
			content:  this.__termsValue(content, mapping['separator'])
		};
	}

	_markimagedetails_handler(entities: Entity[] = []): any {
		// markImageDetails:
		//   - name:
		//     colourIndicator: #inid (000) Color Claimed
		//     colourClaimed: # inid (591)
		//       - languageCode:
		//         text:
		//     description: # inid (539)
		//       - languageCode:
		//         text:
		//     classification: #inid (531)
		//       kind:
		//       version:
		//       code:
		//         - string
		let mapping = {
			'description': { inid: '539', handler: 'langtext' },
			'colourClaimed': { inid: '591', handler: 'langtext' },
			'colourIndicator': { code: 'color_claimed', handler: 'text' },
			'classification': { handler: 'markimagedetails_classification' }
		};

		return entities.map(item => this._toFields(item, mapping))[0] || {};
	}

	_markimagedetails_classification_handler(entry: { code?: string[], kind?: string, version?: string, classes_human?: any[] } = {}): any {

		const l = `_markimagedetails_classification_handler - `

		// console.log(`${l}entry = `, entry)

		/*
			LOG entry = {
				"code": [
					"04.05.14",	"27.05.09"
				],
				"kind": "Vienna",
				"version": "7",
				"classes_human": [ // added server-side in docsgbs.ts
					{
						"code": "04.05.14",
						"description_fr": "04.05.14 - Robots ayant l'aspect d'un animal",
						"kind": "Vienna7",
						"description_en": "04.05.14 - Robots having the appearance of animals",
						"id": "Vienna_7-04.05.14",
						"version": "7",
						"_version_": "1750746039684431882"
					},
					{
						"code": "27.05.09",
						"description_fr": "27.05.09 - Suites de lettres présentant des graphismes différents",
						"kind": "Vienna7",
						"description_en": "27.05.09 - Series of letters presenting different forms of writing",
						"id": "Vienna_7-27.05.09",
						"version": "7",
						"_version_": "1750746039894147082"
					}
				]
			}
		*/

		let inid = entry?.kind === 'Vienna' ? '531' : '532';

		entry.classes_human = entry?.classes_human || [];

		entry.classes_human.sort((a, b) => a.code < b.code ? -1 : a.code > b.code ? 1 : 0)
		let content = entry.classes_human.map(e => {
			let content = e[`description_${this.ms.lang}`] || e.description_en;

			content = content.replace(`${e.code} - `, "");

			const field = {
				label: e.code,
				content
			}

			return field
		})
		const kind = entry.kind === 'Vienna' ? 'VCL' : "US DESIGN CODES";
		if(kind == 'VCL' && this.doc.registrationOfficeCode == 'WO'){
			let computed_version: string
			try{
				let year = (this.doc.registrationDate || '-').split('-')[0]
				for(let year_classification in woViennaMapping){
					if (year_classification <= year){
						computed_version = woViennaMapping[year_classification]
						continue
					}
					break
				}
			}
			catch(_err){
				// console.log(_err)

			}
			if (!entry.version){
				entry.version = computed_version
			}
		}
		const version = kind + (entry?.version ? ' (' + entry?.version + ')': '');
		const toReturn = {
			code: inid,
			label: this.__inidToLabel(inid),
			content,
			version
		}

		// console.log(`${l}toReturn = `, toReturn)

		return toReturn
	}

	_wordmarkspecification_handler(entity: Entity = {}): any {
		// wordMarkSpecification:
		//    markVerbalElement: #inid (540)
		//      - languageCode:
		//        text:
		//    markSignificantVerbalElement: #inid (541)
		//      - languageCode:
		//        text:
		//    markTranslation: #inid (566)
		//      - languageCode:
		//        text:
		//    markTransliteration: #inid (561)

		let mapping = {
			'markVerbalElement': { inid: '541', handler: 'langtext', multi: true },
			'markSignificantVerbalElement': { inid: '541', handler: 'langtext', multi: true },
			'markTranslation': { inid: '566', handler: 'langtext', multi: true },
			'markTransliteration': { inid: '561', handler: 'text' }
		}

		if(entity['markVerbalElement'] && entity['markSignificantVerbalElement']){
			if (entity['markVerbalElement'].length == entity['markSignificantVerbalElement'].length){
				let is_identical = true
				for(let el of entity['markVerbalElement']){
					is_identical = is_identical && this._contains_LangTextField(el, entity['markSignificantVerbalElement'])
					if(!is_identical)
						break
				}
				if(is_identical){
					delete mapping['markSignificantVerbalElement']
				}
			}
		}
		return this._toFields(entity, mapping)
	}

	_contains_LangTextField(needle: LangTextField, haystack:LangTextField[]){
		for(let el of haystack){
			if(needle.languageCode == el.languageCode && needle.text == el.text){
				return true
			}
		}
		return false
	}


	_priorities_handler(entities: Entity[] = []): any[] {
		// priorities: #inid (300)
		//      - countryCode:
		//        countryName:
		//        number:
		//        date:
		//        comment:
		let mapping = {
			'countryCode': { inid: '330', handler: 'text' },
			'countryName': { inid: '330', handler: 'text' },
			'number': { inid: '310', handler: 'text' },
			'date': { inid: '320', handler: 'date' },
			'comment': { inid: '390', handler: 'text' }
		}

		return entities.map(item => this._toFields(item, mapping))
	}


	// ----------------------------------------------------
	// generic handlers here
	// ----------------------------------------------------
	_langtext_handler(content: LangTextField[], mapping: any): any {

		const l = `_langtext_handler - `

		/*
			content = [
				{
					"text": "51 route de Montargis, 89300 JOIGNY, FR",
					"languageCode": "fr",
					"rasterized": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARQAAAAOCAYAAADuSuMwAAAABmJLR0QA/wD/AP+gvaeTAAAIGUlEQVRoge2aeYzWxRnHP8uyF7uLsLtaomJZlcPGVmyx9SKKaAgeoH+I1VpNK63xijViW4lt2lSjrRYlxjuIF6HGemCpBbUtao9URURKRGhFa+1SxEiXBVcLrn98Z3xn5v3N79jdtxr7fpNf4J1n5pmZZ+Y5Z+H/G30f9wI+YajKo4pBwcPoMrnffxP6fR3Y9T9cl4vHK8BzIArUB8yM0I4aIO8kVGL/lcRn0b1623xLgM6gz5nAv4AVwJcTxi8BtgLvAMuA8UGf2cAb5jsnYQ1Z9CS4OhCiAfgu8CKw3XzPAadF+CThVGAN0Au8hfZ+QkK/XcDUlDW6+B1wXtA227TnQaj7STJIou0A/gbMA0aEC/p8xqQnA/fz8Xmx1yrAcyB7eR9d+p2Jsxh0Y688mbz3Ytf1Nzf8O9F0cSsYHwnKi5uQanSIvwIE+BrKDXfSPIfl2XRLRqRwXvLfAvQE3uIBuQE3D9sW4mMXV3QN6YfZ6ICfS/a1wr0R3dp9TMX0+lfypN2VgMxKCBnWSQdruJTglZ8TzIK1X/6g/2QQajik4/qWVVREaxCdYAWZEyuRKlXf/AL4PhBWlcVlUX1rKqoCCagGkAPKlIvoNjfRlRRRSo+BIk3eB8dltFVAAAAAElFTkSuQmCC"
				}
			]
	
			mapping = {
				"inid": "731",
				"handler": "_langtext_handler",
				multi? : boolean --> Defaults to false. If true, concatenates all found values in one string, separated with commas
			}
		*/

		if (!content || !content.map) {
			// console.log(`${l}No "content", skipping`)
			return null
		}

		// console.log(`${l}content = `, content)
		// console.log(`${l}mapping = `, mapping)

		let text: string = ""

		if (mapping.multi && content.length > 1) {
			text = content.map(obj => `(${obj.languageCode}) ${obj.text}`).join(", ")
		} else {
			text = this.__langTextValue(content) // returns : "51 route de Montargis, 89300 JOIGNY, FR"
		}
		let santize = (text || "").replace(/,+/g, ",") 
		santize = santize.replace("None", "").replace(/^[\.,\-_!\?]*/g, "")
		let toReturn = {
			code: mapping['inid'], // "731"
			label: this.__inidToLabel(mapping['inid'] || mapping['code']), // returns: string (translates)
			content: santize
		}

		const rasterized: string = this._extractRasterized(content);

		if (rasterized) {
			toReturn["rasterized"] = rasterized;
		}

		//console.log(`${l}Returning toReturn = `, toReturn)

		return toReturn
	}
	_country_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__countryValue(content)
		}
	}
	_office_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__officeValue(content)
		}
	}
	_text_handler(content: string, mapping: any): any {
		let label_code = mapping['inid'] || mapping['code']
		if (mapping['code'] && label_code != mapping['code']) {
			label_code = mapping['code']
		}
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(label_code),
			content: content
		}
	}

	_date_handler(content: string, mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__dateValue(content)
		}
	}

	_link_handler(content: string, mapping: any): any {
		return {
			label: this.__inidToLabel('view_document'),
			content: '<a href="' + content + '" target="_blanc">PDF</a>'
		}
	}

	_list_handler(content: [], mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__joinArray(content, mapping['separator'])
		}
	}
	_terms_handler(content: [], mapping: any): any {
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__termsValue(content, mapping['separator'])
		}
	}
	_listcountries_handler(content: [], mapping: any): any {
		if(mapping['code'] == 'designation') {
			let entity = this.doc
			let tmp_code = mapping['code']
			if(entity.registrationOfficeCode.toUpperCase() == 'WO' || entity.registrationOfficeCode.toUpperCase() == 'WHO')
				tmp_code = 'designation'
			else if(entity.registrationOfficeCode.toUpperCase() == 'EM')
				tmp_code = 'designation_regional'
			else 
				tmp_code = 'designation_national'
			return {
				code: mapping['inid'],
				label: this.__inidToLabel(tmp_code),
				content: this.__listCountryValue(content)
			}
		}
		return {
			code: mapping['inid'],
			label: this.__inidToLabel(mapping['inid'] || mapping['code']),
			content: this.__listCountryValue(content)
		}
	}


	// ----------------
	// value helpers
	// ----------------

	// getting value of content based on type
	__textValue(content: string): string {
		return content
	}
	__joinArray(content: any[], separator: string = ', '): string {
		return (content || []).join(separator)
	}
	__dateValue(content: string): any {
		return this.ms.dateToHuman(content)
	}
	__termsValue(content: any, separator: string = ' \u2022 '): string {

		const l = `doc2fields __termsValue() - `

		for (let key in content) {

			// console.log(`${l}content = `, content, `key = `, key)

			const terms = content[key]
			return terms.join(separator)
		}
	}
	__countryValue(content: string): any {
		if (content !== undefined) {
			return this.ms.translate(`designation.${content}`)
		}
	}
	__listCountryValue(content: any[], separator: string = ', '): string {
		return (content || []).map(country => this.__countryValue(country)).join(separator)
	}
	__officeValue(content: string): any {
		if (content !== undefined) {
			return this.ms.translate(`office.${content}`)
		}
	}

	__langTextValue(langTextList: LangTextField[]): string { // returning : "51 route de Montargis, 89300 JOIGNY, FR" in the preferred language of the user if available

		const l = `__langTextValue`

		/*
			langTextList = [
				{
					"text": "51 route de Montargis, 89300 JOIGNY, FR",
					"languageCode": "fr",
					"rasterized": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQIAAAANCAYAAABCbmAxAAAABmJLR0QA/wD/AP+gvaeTAAAFpElEQVRoge3aZ4hdRRQH8N/GsrtplsSCDSsRrKCCRiREEVHEgmJFEWwfFAu2T4KioGKviGDFigRFExsWLPhBjV2MBQUVrGA0msRojB/OfeS+u3PLeze7Qd0/XHhzT5lzZs6cO3PmMY46rBgj+Sn4ADe37K9ftPVzHP8hnIDlJbRT8HX2nDxmFpVjReH5A1/3iDroJ+omntvQqGyZkckeKbfmjuhdEnvdacezaRhwhbhS+59G5eXheJOGJ2bOXGNdndO/SUuOxsSh2po7CVfPQbyIgbjtuk7jtqCs6HI8f8aX0HyXqOp8piiNLxFZstrga65xf18TVmf7fxODu3GMfKcwWxbHFopo+AxdbOeh1do12Iqjzu06+zr+U/DQ8aeX14aFZ34M5nqrjyHLpeMnvbNrGU1t6HkU7fsPr4o9IVX8oGsLtWT8/4k7xv4kiBnGB7j8Uzcc5Rm7By+LkQOmbm6p5aJMIiI/OFQ10jON/im3w6uo2Yhzj8zCO1YuHcdDqNmIcYzcP/wBY1De4XzknGwAAAABJRU5ErkJggg=="
				}
			]
	
		*/

		if (langTextList === undefined || langTextList.length === 0) return "";

		for (let pair of langTextList) {
			if (pair.languageCode === this.ms.lang) {
				return pair.text
			}
		}

		// fall back to first item in list
		return langTextList[0].text || null
	}

	_extractRasterized(langTextList: LangTextField[]): string { // returning Base64 rasterized address, or null

		const l = `_extractRasterized`

		/*
			langTextList = [
				{
					"text": "51 route de Montargis, 89300 JOIGNY, FR",
					"languageCode": "fr",
					"rasterized": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQIAAAANCAYAAABCbmAxAAAABmJLR0QA/wD/AP+gvaeTAAAFpElEQVRoge3aZ4hdRRQH8N/GsrtplsSCDSsRrKCCRiREEVHEgmJFEWwfFAu2T4KioGKviGDFigRFExsWLPhBjV2MBQUVrGA0msRojB/OfeS+u3PLeze7Qd0/XHhzT5lzZs6cO3PmMY46rBgj+Sn4ADe37K9ftPVzHP8hnIDlJbRT8HX2nDxmFpVjReH5A1/3iDroJ+omntvQqGyZkckeKbfmjuhdEnvdacezaRhwhbhS+59G5eXheJOGJ2bOXGNdndO/SUuOxsSh2po7CVfPQbyIgbjtuk7jtqCs6HI8f8aX0HyXqOp8piiNLxFZstrga65xf18TVmf7fxODu3GMfKcwWxbHFopo+AxdbOeh1do12Iqjzu06+zr+U/DQ8aeX14aFZ34M5nqrjyHLpeMnvbNrGU1t6HkU7fsPr4o9IVX8oGsLtWT8/4k7xv4kiBnGB7j8Uzcc5Rm7By+LkQOmbm6p5aJMIiI/OFQ10jON/im3w6uo2Yhzj8zCO1YuHcdDqNmIcYzcP/wBY1De4XzknGwAAAABJRU5ErkJggg=="
				}
			]
	
		*/

		if (langTextList === undefined || langTextList.length === 0) return "";

		for (let pair of langTextList) {
			if (pair.languageCode === this.ms.lang) {
				return pair.rasterized || null
			}
		}

		// fall back to first item in list
		return langTextList[0].rasterized || null
	}

	__inidToLabel(inid: string): string {

		// first try to find the translation specific to the office
		const inidTranslationKey = `inids_${this.doc.registrationOfficeCode}.${inid}`
		let inidLabel: string = this.ms.translate(inidTranslationKey)

		// translation returns value in ? => no translation found
		// failed to find office specific => go for default
		if (inidLabel === inidTranslationKey) {
			inidLabel = this.ms.translate(`inids.${inid}`)
		}

		return inidLabel
	}

}
