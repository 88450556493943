import { Component, DoCheck, KeyValueDiffer, KeyValueDiffers, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WOption } from '@wipo/w-angular/shared';

import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { SearchService } from 'src/app/commons/_services/search.service';
import { PreferencesService } from 'src/app/commons/_services/preferences.service';
import { asStructure2bricks, bricks2AsStructure, canSearch, deepClone } from 'src/app/commons/utils';
import { OptionsListService } from 'src/app/commons/_services/options-list.service';

@Component({
	selector: 'page-similarlogo',
	templateUrl: './page-similarlogo.component.html',
	styleUrls: ['./page-similarlogo.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class GDDPageSimilarLogoComponent implements OnInit, DoCheck {

	public strategies: WOption[] = []
	public design_classes: WOption[] = []

	public bricks;
	public classLabel:String = ""
	public canSerachClass:Boolean = false

	public structure
	public show_info
	differ: KeyValueDiffer<string, any>;
	public ev1 = false
	
	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem("gdd.hashSearches")
	}


	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}	
	

	constructor(private ar: ActivatedRoute,
		public ms: MechanicsService,
		public ss: SearchService,
		public qs: QueryParamsService,
		public ps: PreferencesService,
		public ols: OptionsListService,
		private differs: KeyValueDiffers) {

		const l = `page-similarName constructor - `

		this.ms.setEndpoint("similarlogo")
		this.bricks = this.defaultBricks;
		this.differ = this.differs.find({bricks:this.bricks}).create();
	}

	ngDoCheck() {
		const change = this.differ.diff(this.bricks);
		if (change) {
			this.structure.bricks= bricks2AsStructure(this.bricks, this.preprocessBricks).bricks
		}
	  }

	ngOnInit(): void {

		const l = `pageSimilarLogo ngOnInit() - `

		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC

		// Mona : if coming back to this page from results page, remove facets --> Jer : But how do you know you're coming back from the search page and not just reloading the page
		this.qs.keepOnlySearchParams();

		this.buildStuff()
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	get defaultBricks() {
		return {
			// Image strategy and bases64 are directly in the queryParams, not in asStructure. They're not search fields, they're sort options
			productIndication: "",
			description: "",
			applicant: "",
			designer: "",
			designation: [],
			designClassType: "",
			designClassValue: ""
		}
	}

	classChange(){
		this.classLabel = this.ms.translate(`page_similarname.${this.bricks.designClassType}`) + " " + this.ms.translate(`page_similarname.value`) ;
		this.canSerachClass = true
	}

	buildStuff() {

		const l = `page-similarLogo buildStuff() - `

		this.strategies = [
			{ value: "concept", label: "" }
		].map(obj => {
			obj.label = this.ms.translate(`page_similarlogo.${obj.value}`);
			return obj
		})
		this.design_classes = [
			{ value: "locarno", label: "" }, // Displayed as "Exact similarity" but in fact it acts as a "contains". Leaving the value "Exact", but changing the displayed text to "Contains"
			{ value: "ca", label: "" },
			{ value: "jp", label: "" },
			{ value: "us", label: "" },
		].map(obj => {
			obj.label = this.ms.translate(`page_similarname.${obj.value}`);
			return obj
		})

		this.buildBricksFromQP()
		if(this.bricks.designClassType){
			this.classLabel = this.ms.translate(`page_similarname.${this.bricks.designClassType}`) + " " + this.ms.translate(`page_similarname.value`) ;
			this.canSerachClass = true
		}
		else{
			this.classLabel = this.ms.translate(`page_similarname.search_class_value`) + " " + this.ms.translate(`page_similarname.value`) ;
		}
		this.qs.queryParams[ this.ms.endpoint ].strategy = 'concept'
		this.structure = bricks2AsStructure(this.bricks,  this.preprocessBricks);
	}

	get parent(){
		return this
	}


	preprocessBricks(orig_bricks){
		let bricks = deepClone(orig_bricks);
		if (bricks.designClassType && bricks.designClassValue){
			bricks['class_'+bricks.designClassType] = bricks.designClassValue
		}
		delete bricks.designClassType
		delete bricks.designClassValue
		
		return bricks
	}
	

	buildBricksFromQP() {
		const l = `page-similarName buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		let asStructure
		try{
			asStructure = JSON.parse(qpAsStructureString)
		}
		catch(err){
			return
		}
		// PostProcessing 
		// console.log(`${l}type=${typeof qpAsStructure } - qpAsStructure = `, qpAsStructure)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			this.bricks = Object.assign(this.bricks, asStructure2bricks(asStructure))
		}
	}

	get canSearch(): boolean {

		// either image is selected or niceClass or viennaClass or USDesignClass

		if (this.ms.bases64?.length && this.ms.editingImageIndex === null) {
			return true
		}

		return false
	}

	// Updates the URL accordingly without reloading the page.
	// Changing the query params in the URL will trigger a subscription in HOCSearchComponent,
	// which in turn will automatically call SearchService.search() and refresh the results.
	async search() {
		const l: string = `PageSimilarLogo search() - `
		if (!this.canSearch) return;

		this.qs.setQP("asStructure", JSON.stringify(bricks2AsStructure(this.bricks,  this.preprocessBricks)));
		this.qs.setQP("fg", "_void_")
		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })
		this.ps.setPref("sort", "score desc"); // Christophe wants to reset the sort order to the default every time we search for a logo
		await this.qs.queryParamsObjectToUrl(route)
	}

	doReset() {

		const l = `page-similarLogo doReset - `

		this.ms.editingImageIndex = null
		this.ms.bases64 = []
		this.ms.bases64resized = []
		sessionStorage.setItem("gdd.ms.bases64", null)
		
		this.bricks = this.defaultBricks;
	}

	// -----------------------
	// autocompletion on names
	// -----------------------


	//- Suggestions have been turned off for now, using a simple text field
	/*
		onSuggestSelect(value, what: string) {
			const l: string = `PageExploreComponent onSuggestSelect() - `

			// remove the highlight tag <b></b>
			value = value.replace(/<[^>]*>?/gm, '');

			this.qs.queryParams[this.ms.endpoint][what] = value
		}
	*/

	onModelChange($event, which: string) { // classifications - which="viennaClass", "usDesignClass"

		const l = `onModelChange() - `

		// I admit my ignorance about how to add a [(ngModel)] to a custom component (classification-suggest in this case), so I make my component emit a (modelChange) event, I catch it here, and I set it manually

		// console.log(`${l}$event = `, $event) // $event = "<b>05</b>.03.<b>05</b> - Leaves of chestnut trees"

		this.qs.setQP(which, $event);
	}


	onSuggestSelect($event, which: string) { // classifications - which="viennaClass", "usDesignClass"

		const l = `onSuggestSelect() - `

		// console.log(`${l}$event = `, $event) // $event = "<b>05</b>.03.<b>05</b> - Leaves of chestnut trees"

		let value = $event;

		// Removing the highlight tag <b></b>
		value = value.replace(/<[^>]*>/gm, ''); // "02.03.07 - Women wearing an evening dress"
		let number = value.split(" - ")[0]; // Extracting the number at the beginning

		// console.log(`${l}QP*=`, deepClone(this.qs.getQP("*")))
		// console.log(`${l}getting QP '${which}'`)

		let fullInputValue = this.qs.getQP(which) || "";

		// console.log(`${l}fullInputValue 162 = '${fullInputValue}'`)

		// Removing the last thing the user has typed ("5" or " wom") to replace it with the classification number
		fullInputValue = fullInputValue.replace(/ ?[^ ]+$/, "")
		fullInputValue += ` ${number}`;
		// Cleanup
		fullInputValue = fullInputValue.trim().replace(/ +/g, " ");

		// console.log(`${l}setting pq.${which} = ${fullInputValue}`)

		this.qs.setQP(which, fullInputValue);

	}

	doSuggestFocus($event) {
		$event.target.select()
	}


}
