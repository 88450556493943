import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { WOption } from '@wipo/w-angular/shared';
import dayjs from 'dayjs'
import { DBInfoSolrResponse } from 'src/app/commons/interfaces';
import { deepClone, generateId, instanceType, tooltipKey } from 'src/app/commons/utils';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { PreferencesService } from 'src/app/commons/_services/preferences.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { SearchService } from 'src/app/commons/_services/search.service';

@Component({
	selector: 'brick',
	templateUrl: './brick.component.html',
	styleUrls: ['./brick.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class BrickComponent implements OnInit {

	@ViewChild('brick', { static: false }) brick: ElementRef;
	@Input() parent: any
	@Input() structure: any
	@Input() level: number // So far, only used to avoid drawing an arc on the left at level 0

	@Output() actionEvent = new EventEmitter<any>();

	// public bricks: any[] // == structure.bricks
	public andOrs: number[] = [];

	private copy_paste_limit: number = 30

	// transformed into { label, value } in buildKeysDropdown()
	// must be named "general_words" for translation : "general_words.applicant"
	public general_words: WOption[] = []


	private dataToBuildKeysDropdownGBD: any = {
		/*
			transformed into { label, value } in buildKeysDropdown()

			IMPORTANT : if you add new entries to this list, make sure to also update accordingly :
			- get strategies()
			- valueTypeOf

			All of these words will be translated from general_words

		*/
		"free_text": [
			"brandName",
			"representative",
			"applicant",
			"goodsServices"
		],
		"misc": [
			"status",
			"markFeature",
			"type",
		],
		"classification": [
			"niceClass",
			"viennaClass",
			"usDesignClass",
		],
		"country": [
			"designation",
			"office",
			"applicant_cc",
			"refOffice",
		],
		"date": [
			"regDate",
			"appDate",
			"expDate",
			"termDate",
		],
		"number": [
			"regNum",
			"appNum",
			"prioNum"
		]
	}

	private dataToBuildKeysDropdownGDD: any = {
		/*
			transformed into { label, value } in buildKeysDropdown()

			IMPORTANT : if you add new entries to this list, make sure to also update accordingly :
			- get strategies()
			- valueTypeOf

			All of these words will be translated from general_words

		*/
		"free_text": [
			"productIndication",
			"representative",
			"applicant",
			"designer",
			"description"
		],
		"misc": [
			"status",
		],
		"classification": [
			"locarno",
			"ca",
			"jp",
			'us'
		],
		"country": [
			"designation",
			"office",
			"applicant_cc",
			"refOffice",
		],
		"date": [
			"regDate",
			"appDate",
			"expDate",
			"termDate",
		],
		"number": [
			"regNum",
			"appNum",
			"prioNum"
		]
	}

	private dataToBuildKeysDropdown: any = instanceType()=='gbd' ? this.dataToBuildKeysDropdownGBD: this.dataToBuildKeysDropdownGDD;

	// transformed into { label, value } in ngOnInit.
	// must be named "status" for translation : "status.Pending"
	public status: string[] = [
		"Pending",
		"Registered",
		"Expired",
		"Ended",
		"Unknown"
	]
	public status_value = {}
	public type_value = {}
	public markFeature_value = {}

	// transformed into { label, value } in ngOnInit
	// must be named "type" for translation : "type.EMBLEM"
	public type: string[] = [
		"AO",
		"EMBLEM",
		"GI",
		"INN",
		"TRADEMARK"
	]

	// transformed into { label, value } in ngOnInit
	// must be named "markFeature" for translation : "markFeature.Combined"
	public markFeature: string[] = [
		"Colour",
		"Combined",
		"Figurative",
		"Hologram",
		"Motion",
		"Multimedia",
		"Olfactory",
		"Other",
		"Pattern",
		"Position",
		"Sound",
		"Stylized characters",
		"Three dimensional",
		"Tracer",
		"Undefined",
		"Word",
	]

	public full_stategies = {}
	public optionsCountries: WOption[] = []


	public datesFormatsMapping = { // mapping PrimeNG <--> Us
		"yy-mm-dd": "YYYY-MM-DD",
		"dd/mm/yy": "DD/MM/YYYY",
		"mm/dd/yy": "MM/DD/YYYY"
	}

	private previousKey: string = ""; // Used when switching to a different key (brandName to niceClass, for instance). I memorize the previous key to deduce if structure.value should be reset
	private previousStrategy: string = "" // Same reason

	public tooltipKey = tooltipKey; // makes utils.tooltipKey available in the view directly

	constructor(public qs: QueryParamsService,
		public ss: SearchService,
		public ms: MechanicsService,
		public ps: PreferencesService) {


	}

	ngOnInit(): void {

		const l = `brick ngOnInit() - `

		if (this.structure.boolean) { // structure is an $and/$or array

			// console.log(`147 ${l}this.structure = `, deepClone(this.structure))

			if (!this.structure?.bricks?.length) { // Failsafe, in the unlikely case something's undefined or invalid
				console.warn(`${l}structure.bricks is invalid! Should be an array with min length of 1. Failsafe using default - structure=`, deepClone(this.structure))
				// console.log(`${l}Defaulting structure`)
				this.structure = this.qs._advancedsearch_default.asStructure; // dirty default to avoid undefined...  
			}

			// console.log(`153 ${l}this.structure = `, deepClone(this.structure))

			this.andOrs = Array.from(Array(this.structure.bricks.length - 1).keys())
		}

		// Sorting the list of possibilities alphabetically BY LANGUAGE (translated), not in English
		for (let what of ["status", "type", "markFeature"]) {
			for (let el of this[what]) {
				this[what + "_value"][el] = this.isChecked(el)
			}
			this[what] = this[what]
				.map(w => ({
					label: this.ms.translate(`${what}.${w}`),
					value: w
				}))
				.sort((a, b) => a.label.localeCompare(b.label))
		}
		this.buildKeysDropdown()

		this.previousKey = "" + this.structure.key
		this.previousStrategy = "" + this.structure.strategy
	}

	async onKey(event: KeyboardEvent) {
		if (event.ctrlKey || event.metaKey) {
			let should_paste = false
			if (event.key == ';') {
				this.parent.needle = ';'
				should_paste = true
			}
			if (event.key == ',') {
				this.parent.needle = ','
				should_paste = true
			}
			if (event.key == ' ') {
				this.parent.needle = ' '
				should_paste = true
			}
			if (event.key == 'n') {
				this.parent.needle = '\n'
				should_paste = true
			}

			if (should_paste) {
				try {
					const text = await navigator.clipboard.readText();
					this.onModelChange(text)
				}
				catch { }
			}
		}
	}

	buildKeysDropdown() {

		const l = `buildKeysDropdown() - `

		this.general_words = [];

		for (let entry of Object.entries(this.dataToBuildKeysDropdown)) {

			let label = this.ms.translate(`general_words.${entry[0]}`) + " :";

			// Disabled option
			this.general_words.push({
				label,
				value: null, // Will disable the <option>
				disabled: true
			});

			// selectable options
			let a = (entry[1] as string[]).map((obj: any): WOption => ({
				label: '&nbsp;&nbsp;&nbsp;' + this.ms.translate(`general_words.${obj}`),
				value: obj,
				tip: this.ms.translate(`tooltips.${tooltipKey(obj)}`)
			}))
				.sort((a, b) => a.label.localeCompare(b.label));

			(entry[1] as string[]).forEach((w:any) => this.full_stategies[w] = this.buildStrategies(w));

			this.general_words = this.general_words.concat(a);
			

		}
		// console.log(`${l}`, this.general_words)
	}


	getAndOrHeight(): string {

		const l = `getAndOrHeight() - `

		if (!this.brick) {
			// console.log(`${l}View not yet init, no brick, waiting a bit`)
			return `0px`
		}

		try {
			const brick: HTMLElement = this.brick.nativeElement;
			const brickHeight = brick.getBoundingClientRect().height * 0.9 - 35 // -35 = removing the height of the "ADD A ROW" button
			return `${brickHeight}px`

		} catch (err) {
			// Can fail while the view is being rebuilt, immediately after adding a new brick to the list. It lasts only one frame. The next attempt will be succeessful.
			return `0px`
		}
	}

	get strategyName(): string {
		return this.general_words.filter(r => r.value == this.structure.key)[0].label.replace('&nbsp;&nbsp;&nbsp;', '')
	}


	buildStrategies(key, no_process = false): WOption[] | string[] {

		let strategies: string[] = [];

		switch (key) {
			case "brandName":

				strategies = [
					"Simple", // --> displayed as 'equals' but works as a 'contains'. Leaving it here, but changing 'equals' to 'contains' in the JSON i18n files.
					"Terms",
					"Fuzzy",
					"Phonetic",
					// "Embedded", --> Doesn't work (displayed as 'contains'), removing it for now
					"Stemming",
					// "Solr" // is renamed to "Simple" in brick2tk in the back-end. "Simple" strategies support Solr queries
				]

				break;

			case "goodsServices":
				strategies = [
					"Exact",
					// "Simple",
					"Fuzzy",
					// "Embedded", --> Doesn't work (displayed as 'contains'), removing it for now
					"Terms",  // --> works as a 'equals'. Leaving it here, but changing 'Terms' to 'Equals' in the JSON i18n files.
					// "Solr" // is renamed to "Simple" in brick2tk in the back-end. "Simple" strategies support Solr queries
				]
				break;

			// 2023-01-16 Nico : il n'y a pas (et n'y aura pas pour un bon moment) de strategie phonetic pour applicant
			case "applicant":
			case "representative":
			case "designer":
				strategies = [
					// "Exact",
					"Simple",
					"Fuzzy",
					"Embedded", // --> Doesn't work (displayed as 'contains'), removing it for now --> Or not? uuuuh...
					// "Terms",  // --> works as a 'equals'. Leaving it here, but changing 'Terms' to 'Equals' in the JSON i18n files.
					// "Solr" // is renamed to "Simple" in brick2tk in the back-end. "Simple" strategies support Solr queries
				]
				break;

			case "office":
				strategies = [
					"any_of",
					"none_of"
				]
				break;

			case "designation":
			case "applicant_cc":
			case "refOffice":
			case "niceClass":
			case "viennaClass":
			case "usDesignClass":
			case "locarno":
			case "ca":
			case "jp":
			case "us":
				strategies = [
					"all_of",
					"any_of",
					"none_of"
				]
				break;

			case "appDate":
			case "regDate":
			case "expDate":
			case "termDate":
				strategies = [
					"Min", // younger than
					"Max", // older than
					"Range",
					"Day",
				]
				break;
			case "productIndication":
			case "description":
				strategies = [
					"Exact",
					"Simple",
					"Fuzzy",
					"Stem",
					"Embedded",
					"Terms",  // --> works as a 'equals'. Leaving it here, but changing 'Terms' to 'Equals' in the JSON i18n files.
					// "Solr" // is renamed to "Simple" in brick2tk in the back-end. "Simple" strategies support Solr queries
				]
				break;
			default: // Don't display the strategies dropdown
				strategies = [];
		}

		if (no_process) {
			return strategies
		}

		return strategies.map(w => ({
			label: this.ms.translate(`page_advanced_search.${w}`),
			value: w,
			tip: this.ms.translate(`tooltips.${tooltipKey(w)}`)
		}))
	}

	get strategies_select(): WOption[] {

		const l = `get strategies - `
		return this.full_stategies[this.structure.key] as WOption[]
	}

	get strategies(): string[] {

		const l = `get strategies - `
		return this.buildStrategies(this.structure.key, true) as string[];
	}

	addBoolean() {

		const l = `brick addBoolean()`

		// Adding one more AND/OR selector. Since this acts on the current structure of this brick, it can be done directly here, without emitting an order to the parent

		this.structure.bricks.push({
			_id: generateId(),
			key: "brandName",
			strategy: "equals",
			value: ""
		})
		this.ngOnInit() // to refresh andOrs
	}

	isChecked(value: string): boolean {

		return this.structure.value?.includes(value)

	}

	// EVENT EMITTERS

	emitDelete() {
		const l = `brick emitDelete() level ${this.level} - `

		// console.log(`${l}Emitting _id=`, this.structure._id)

		const toEmit = {
			handler: "deleteBrick",
			payload: this.structure._id
		}

		this.actionEvent.emit(toEmit) // This event will be caught in the parent brick's onDeleteBrick(), which is just below
	}

	emitBooleanize() {

		const l = `brick emitBooleanize() level ${this.level} - `

		// converting a simple key:value brick into a 2-row boolean one. This must be done in the parent, to update the structure, so I'm emitting an event that will be caught in the parent brick

		const toEmit = {
			handler: "convertToBoolean",
			payload: this.structure._id
		}

		this.actionEvent.emit(toEmit) // This event will be caught in the parent brick's onDeleteBrick(), which is just below
	}

	emitPrune() {
		const l = `brick emitPrune() - `

		const toEmit = {
			handler: "prune"
		}

		this.actionEvent.emit(toEmit)
	}



	// EVENT HANDLERS


	actionHandler($event) {

		const l = `brick actionHandler() level ${this.level} - `

		/*
			Bricks are recursive, so in the code below, some strategies emit an event, and some other execute these events. All events are handled by this actionHandler() strategy, which then calls the requested event handler.

			$event received is the one emitted from one of the event emitters above :
			{
				handler : "deleteBrick",
				payload : this.structure._id
			}
		*/

		// console.log(`${l}Handling action`)

		this[$event.handler]($event.payload); // calls this.deleteBrick(_id)
	}

	deleteBrick(_id: string) { // $event == _id
		const l = `BrickComponent deleteBrick() level ${this.level} - `
		// console.log(`${l}Removing brick with _id='${_id}'`)
		this.structure.bricks = this.structure.bricks.filter(b => b._id != _id)

		// console.log(`${l}From child (${this.structure._id.slice(-2)}) : this.structure = `, deepClone(this.structure))

		// Pruning has to be done by the parent, a brick can't replace its own structure, as it's a, @Input()
		this.emitPrune()

		this.ngOnInit() // to refresh andOrs
	}

	convertToBoolean(_id: string) {

		const l = `brick convertToBoolean() level ${this.level} - `

		// console.log(`${l}this.structure=`, this.structure)

		let index = -1;

		let brickToconvert = this.structure.bricks.find((b, i) => {
			index = i;
			return b._id === _id
		});

		// console.log(`${l}found brickToConvert at index = ${index}`)

		/*
			Current : 

			{ _id: generateId(), key: "brandName", strategy: "equals", value: "star" }

			Must convert to :
			{
				_id: generateId(),
				boolean: "OR",
				bricks: [
					{ _id: generateId(), key: "brandName", strategy: "equals", value: "star" },
					{ _id: generateId(), key: "brandName", strategy: "fuzzy", value: "sar" }
				]
			}
		*/

		brickToconvert = {
			_id: generateId(),
			boolean: this.structure.boolean === "OR" ? "AND" : "OR",
			bricks: [
				brickToconvert,
				{ _id: generateId(), key: "brandName", strategy: "equals", value: "star" }
			]
		}

		this.structure.bricks.splice(index, 1, brickToconvert);
	}

	onCheckboxChecked($event): void {
		const l = `brick onCheckboxChecked - `
		const isChecked = $event.target.checked
		const value = $event.target.value

		if (!Array.isArray(this.structure.value)) {
			this.structure.value = [];
		}

		if (isChecked) {
			this.structure.value.push(value)
		} else {
			this.structure.value = this.structure.value.filter(v => v != value)
		}

	}

	onDateFormatChange($event) {

		const l = `onDateFormatChange() - `

		const format = $event.target.value

		// console.log(`${l}format = `, format)

		this.ps.setPref("dateFormat", format, "all")

	}

	onModelChange(newInputValue: string) {

		const l = `brick onModelChange - `
		//console.log(`${l}setting structure.value = `, newInputValue)
		if (this.parent.needle && newInputValue.includes(this.parent.needle)) {
			let multipleNewValueInput = newInputValue.split(this.parent.needle)
			this.structure.value = multipleNewValueInput[0]
			for (let idx = 1; idx < multipleNewValueInput.length && idx < this.copy_paste_limit; idx++)
				this.parent.bricks.push({
					_id: generateId(),
					key: this.structure.key,
					strategy: this.structure.strategy,
					value: multipleNewValueInput[idx]
				})
			this.parent.boolean = "OR"
			this.parent.needle = undefined
			this.parent.ngOnInit()
		}
		else {
			this.structure.value = newInputValue
		}
	}

	onWupdated($event) {
		const l = `onWChange - `
		this.structure.value = $event
		// console.log(`${l}setting structure.value = `, $event);

	}

	onSelectChange($event) {

		const l = `onSelectChange() - `

		// console.log(`${l}nativeElement = `, this.brick.nativeElement)

		try {
			setTimeout(() => {
				this.brick.nativeElement.querySelector("input").focus()
			})
		} catch (err) {
			// console.log(`${l}caugth error = `, err)
		}

		if (!this.strategies.includes(this.structure.strategy)) {
			// console.log(`${l}strategies do not include '${this.structure.strategy}'. Defaulting to '${this.strategies[0]}'`)
			this.structure.strategy = this.strategies[0]
		}

		/*
			RESET RULES

			I think we need to reset the input field content when we switch content type.

			There are several fields types : freeForm (plain input text), countries list, image_class, static list, Date....

			1. When we switch from type:freeForm to type:freeForm, no reset.
			2. When we stay on type:image_class and change the strategy only, no reset.
			3. When we stay on type:image_class but change the key (Nice to Vienna, or US to Nice), reset.
			4. Otherwise (switching from Date to List, etc) I want to reset it.
		*/

		const oldType: string = this.valueTypeOf(this.previousKey); // "country list"
		const newType: string = this.valueTypeOf(this.structure.key); // "date"
		let shouldReset: boolean = false;
		if (oldType !== newType
			||
			(newType === 'checkbox' && this.previousKey != this.structure.key)
			||
			(newType === "date" && [this.structure.strategy, this.previousStrategy].includes("Range") && this.structure.strategy !== this.previousStrategy) // in a Date, changing the strategy to or from a Range
			||
			(newType === "image_class" && newType === "image_class" && this.structure.key !== this.previousKey)) { // Changing from niceClass to viennaClass
			shouldReset = true;
		}

		// console.log(`${l}previousKey='${this.previousKey}', new key='${this.structure.key}'`)
		// console.log(`${l}previousStrategy='${this.previousStrategy}', new strategy='${this.structure.strategy}'`)
		// console.log(`${l}shouldReset=${shouldReset}`)

		this.previousKey = "" + this.structure.key
		this.previousStrategy = "" + this.structure.strategy
		if (!shouldReset) return;

		for (let what of ["status", "type", "markFeature"]) {
			for (let el of this[what]) {
				this[what + "_value"][el.value] = false
			}
		}

		//console.log(`${l}newType='${newType}'`)
		//console.log(`${l}this.structure.strategy='${this.structure.strategy}'`)

		switch (newType) {
			case "freeField":
				this.structure.value = "";
				return

			case "date":
				if (this.structure.strategy === "Range") {
					this.structure.value = ["", ""]
				} else {
					this.structure.value = ""
				}
				return;

			// countries lists and all other fields are lists, so I can just set the default value as []. It's just by chance, though...
			default:
				this.structure.value = []

		}

		// console.log(`${l}set structure.value to`, this.structure.value)

		/*
			END RESET RULES FOR INPUT FIELD
		*/



	}

	valueTypeOf(key: string): string {

		const l = `brick valueTypeOf() - `

		switch (key) {
			case "productIndication":
			case "designer":
			case "brandName":
			case "goodsServices":
			case "applicant":
			case "regNum":
			case "appNum":
			case "prioNum":
			case "representative":
				return "freeField";

			case "regDate":
			case "appDate":
			case "expDate":
			case "termDate":
				return "date"

			case "applicant_cc":
			case "designation":
			case "refOffice":
			case "office":
				return "countries_list"

			case "niceClass":
			case "viennaClass":
			case "usDesignClass":
				return "image_class"

			case "markFeature":
			case "status":
			case "type":
				//return "" + Math.random() // Why Math.random? Because in any of these cases, I want to reset structure.value. By returning a random number, the type equality won't match, and the value will necessarily be reset.
				return 'checkbox'
			default:
				console.error(`${l}Don't know what to return with '${key}'`)
		}

	}

	onSuggestSelect(clickedLine: string, which: string) { // classifications - which="viennaClass", "usDesignClass"

		const l = `brick onSuggestSelect() - `

		// console.log(`${l}$event = `, clickedLine) // $event = "<b>05</b>.03.<b>05</b> - Leaves of chestnut trees"

		clickedLine;

		// Removing the highlight tag <b></b>
		clickedLine = clickedLine.replace(/<[^>]*>/gm, ''); // "02.03.07 - Women wearing an evening dress"
		let number = clickedLine.split(" - ")[0]; // Extracting the number at the beginning

		let fullInputValue: string = "" + (this.structure.value || "");

		// console.log(`${l}344 old input value = '${fullInputValue}'`) // "2"

		// Removing the last thing the user has typed ("5" or " wom") to replace it with the classification number
		let splitted = fullInputValue.split(" ") // "2 5 wom" --> ["2" ,"5", "wom"]
		splitted.pop() // [ "2", "5" ]
		fullInputValue = splitted.join(" "); // "2 5"

		fullInputValue += ` ${number}`; // "2 5 8"

		// console.log(`${l}350 new input value = '${fullInputValue}'`)

		// Cleanup
		fullInputValue = fullInputValue.trim().replace(/ +/g, " ");

		this.structure.value = "" + fullInputValue;

		this.qs.setQP(which, fullInputValue); // It is read as [ngModel] in <classification-suggest>, so I need it there, although it won't be sent to the back-end when pressing search button
	}

	onDateSelected($event, index = null) {
		const l = `brick dateSelected() - `

		// console.log(`${l}Raw event returned by w-angular = `, $event) // "Wed Feb 01 2023 00:00:00 GMT+0100 (Central European Standard Time)"

		/*
			structure.value cannot be a Date object, because then it is converted to string in URL params.

			I wand an ISO8601 date YYYY-MM-DD
		*/

		const ori = $event // "Wed Feb 01 2023 00:00:00 GMT+0100 (Central European Standard Time)"
		const nDate = dayjs(ori).format("YYYY-MM-DD");

		// console.log(`${l}$event = ` + "date selected :: " + ori + " -- " + nDate);

		if (index != null) {
			this.structure.value[index] = nDate;
		} else {
			this.structure.value = nDate;
		}
	}

	prune() {

		const l = `prune() - `

		/*
			After deleting a brick, if it's alone, we can't do a AND/OR on only one brick, so I'm replacing the whole boolean with its one brick (the opposite of convertToBoolean)
		*/

		// console.log(`${l}From parent (${this.structure._id.slice(-2)}) : this.structure = `, deepClone(this.structure))

		for (let i in this.structure.bricks) {
			const brick = this.structure.bricks[i];

			if (brick.bricks?.length === 1) {
				this.structure.bricks[i] = brick.bricks[0]
			}
		}
	}
}
