import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import html2pdf from "html2pdf.js"
import { wipoImage } from 'src/app/commons/_imports/wipoImage';
import { MechanicsService } from 'src/app/commons/_services/mechanics.service';
import { PreferencesService } from 'src/app/commons/_services/preferences.service';
import { QueryParamsService } from 'src/app/commons/_services/queryParams.service';
import { SearchService } from 'src/app/commons/_services/search.service';
import { GBDSolrResponse, GDDDocument, GDDDocumentDesign, PIClass } from 'src/app/commons/interfaces';
import { decrypt } from 'src/app/commons/utils';
import { environment } from 'src/environments/environment';
import { GDDDoc2fieldsService } from '../../_services/doc2fields.service';

@Component({
	selector: 'page-details',
	templateUrl: './page-details.component.html',
	styleUrls: ['./page-details.component.css'],
	encapsulation: ViewEncapsulation.None,

})


export class GDDPageDetailsComponent implements OnInit {

	public doc: any;
	public fields: any;
	public expandDesigns: boolean;

	public images: any[];	
	public images_blob_th: any = {}
	public images_blob_big: any = {}


	public isMock: boolean
	private urlchangeSubscription: any;
	public ev1:boolean = false;
	public ev2:boolean = false;
	public ev3:boolean = false;
	public ev4:boolean = false;
	public ev5:boolean = false;
	public ev6:boolean = false;
	public ev7:boolean = false;

	public isExporting:boolean = false; // When true, hides nav, expands truncated paragraphs, removes "show more"

	constructor(public ss: SearchService,
		private ar: ActivatedRoute,
		private lc: Location,
		public ms: MechanicsService,
		public ps: PreferencesService,
		public qs: QueryParamsService,
		private d2f: GDDDoc2fieldsService,
	) { }

	async ngOnInit(): Promise<void> {

		const l: string = `PageDetailsComponent ngOnInit() - `
		this.expandDesigns = true

		this.ms.setEndpoint(this.ar.snapshot.params.endpoint)

		this.ms.setLoading(true, l)
		this.urlchangeSubscription = this.ar.queryParams.subscribe(
			this.fetchFullDoc.bind(this)
		)
	}

	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem("gbd.hashSearches")
	}


	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}		

	converBase64toBlob = (base64: string): any => {
		const contentType = "application/pdf";
		const sliceSize = 512;
		const byteCharacters = window.atob(base64); //method which converts base64 to binary
		const byteArrays = [
		];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, {
			type: contentType
		}); //statement which creates the blob
		return blob;
	}

	async exportAsPDF() {

		// path: `:lang/:endpoint/brand/:st13/export`
		// window.open(`/${this.ms.lang}/brand/${this.ar.snapshot.params.st13}/export?format=pdf`)

		const l = `exportAsPDF() - `

		this.ms.isLoading = true;
		this.isExporting = true;
		
		// Grrr I don't know why I need a hack to force booleans to be true. I need it, because it expands collapsed fields (Nice classification...) and removes "Show less" while exporting the PDF
		await new Promise( r => setTimeout(r, 100));
		
		const element = document.getElementById('pdfExport');
		var clonedElement = <Element>element.cloneNode(true);
		clonedElement.setAttribute('class', clonedElement.getAttribute('class') + ' isExporting')

		const opt = {
			margin: [2.4, 1.4, 1.4, 1.4],
			filename: 'GBD-' + this.ar.snapshot.params.st13 + `_${formatDate(new Date(), 'yyyyMMdd', 'en_US')}.pdf`,
			image: { type: 'jpeg', quality: 0.75 },
			html2canvas: { scale: 2, letterRendering: true, useCORS: true },
			pagebreak: { avoid: 'ul' },
			jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' }
		};
		
		// Below : give some time for ms.setLoading to register to true. This will expand all the collapsed fields (super long classifications etc) in the page, so they'll be open during the PDF export
		// await new Promise(r => setTimeout(r, 50000));

		await html2pdf()
			.set(opt)
			.from(clonedElement)
			.toPdf()
			.get('pdf')
			.then(pdf => { // That's a thenable, not a Promise
				let totalPages = pdf.internal.getNumberOfPages();

				for (let i = 1; i <= totalPages; i++) {
					pdf.setPage(i);
					pdf.setFontSize(8);
					pdf.setTextColor(150);
					pdf.text(
						i + '/' + totalPages,
						pdf.internal.pageSize.getWidth() / 2 - 1,
						pdf.internal.pageSize.getHeight() - 0.8
					)
					pdf.setFontSize(10);
					pdf.text(
						'WIPO',
						pdf.internal.pageSize.getWidth() / 2 - 1.5,
						0.8
					)
					// addImage method https://artskydj.github.io/jsPDF/docs/module-addImage.html
					pdf.addImage(wipoImage, 'png', 0.5, 0.25, 4, 2)
				}
				// window.open(pdf.output('bloburl'), '_blank');
			})
			.save();

		this.ms.setLoading(false)
		this.isExporting = false;
		
	}

	async fetchFullDoc(): Promise<void> {
		const l = `pageDetails fetchFullDoc() - `

		// console.log(`${l}`)

		const st13 = this.ar.snapshot.params.st13
		let office = null
		if (this.ar.snapshot.params.office){
			office = this.ar.snapshot.params.office.replace('IPO-', '')
		}

		let res: GBDSolrResponse;
		
		try {
			res = await this.ss.getDocumentDetails(st13, office);

			res = decrypt(res);

			// console.log(`${l}RES = `, res)
		}
		catch (err) {

			this.ms.setSearchError(err)

			switch (err.status) {
				case 404:
					// cannot find the doc
					this.ms.router.navigate([this.ms.lang, "notfound"], { replaceUrl: true })
					break
			}
			return
		}

		this.isMock = !!res.isMock
		this.doc = res.response.docs[0];
		// how does this work? I thought the logo were associated with the individual design?????
		// fix at embedding time 
		this.images = []
		for(let d of this.doc.designs){
			this.images = this.images.concat(d.logos)
		}
		console.log(this.images)
		//this.images = this.doc.logos;
		
		for(let img of this.images){
			this.images_blob_th[img] = await this.ms.getBase64ImageFromUrl(this.get_th_image(img)) as string
			this.images_blob_big[img] = await this.ms.getBase64ImageFromUrl(this.get_big_image(img)) as string
			if (this.images_blob_big[img] == ''){
				this.images_blob_big[img] = this.images_blob_th[img] 
			}
		}

		
		this.buildStuff();

		this.ms.setLoading(false, l)
	}

	get_collection(collection, st13){

	
		return collection
	}

	get_th_image(img:string):string{
		const st13: string = this.doc.st13;
		const collection: string = this.get_collection(this.doc.registrationOfficeCode.toLowerCase() + 'id', st13)
		let imagePath = `/designs/${collection}/${st13}/${img}-th.jpg`
		return environment.gdd.imgBaseUrl + imagePath
	}

	get_big_image(img:number):string {
		const st13: string = this.doc.st13;
		const collection: string = this.get_collection(this.doc.registrationOfficeCode.toLowerCase() + 'id', st13)
		let imagePath = `/designs/${collection}/${st13}/${img}-hi.jpg`
		return environment.gdd.imgBaseUrl + imagePath
	}

	ngOnDestroy(): void {
		this.ms.unsetSearchError()
		this.urlchangeSubscription.unsubscribe();
	}

	buildStuff() {


		const l=``;

		this.d2f.buildFields(this.doc);
		this.expandDesigns = true;

		// console.log(`${l}this.doc = `,this.doc)

		this.fields = this.d2f.fields;
		if (this[`_link_${this.doc.registrationOfficeCode}`]){
			let tmp = this[`_link_${this.doc.registrationOfficeCode}`](this.doc)
			this.doc.link2item = tmp[0]!= '' ? tmp[0] : undefined
			this.doc.link2office = tmp[1]!= '' ? tmp[1] : undefined

		}


		// expand doc with helpers to show/hide content blocks
		// 03.names-addresses
		this.doc.hasApplicants = !!this.fields.applicants.length;
		this.doc.isApplication = this.fields.gbdStatus == 'Pending';
		this.doc.hasRepresentatives = !!this.fields.representatives.length;
		this.doc.hasDesigner = !!this.doc.designer;
		this.doc.hasNamesAddresses = this.doc.hasApplicants || this.doc.hasRepresentatives || this.doc.hasDesigners;
	
		// 04.classification
		// this.doc.productIndicationClasses = !!this.fields.productIndicationClasses.length;
		// this.doc.hasClassification = this.doc.productIndicationClasses
		if (this.doc.designs.length > 1){
			this.expandDesigns = false
		}
		console.log(" Designs: ", this.doc.designs.length);
	}

	openNewTab(): void {
		window.open(`${this.ms.environment.appUrl}/${this.ms.lang}/design/${this.doc.st13}`, "_blank")
	}

	backToResults(): void {
		this.lc.back()
	}

	get windowWidth(): number {
		return window.innerWidth
	}



	async findSimilarImages(url: string) {
		
		const l = `pageDetails findSimilarImages() - `
		let base64 = await this.ms.getBase64ImageFromUrl(url)
		
		sessionStorage.setItem("gdd.ms.bases64", `["${base64}"]`)
		this.ms.bases64 = [base64];
		this.ms.bases64resized = [base64];


		const route = this.ms.makeRoute({ path: 'similarlogo', caller: l })
		this.qs.queryParamsObjectToUrl(route)
	}

	get currentPage(): number {
		// returns 0 for page 1, 15 for page2, etc. Multiples of 15
		return +(this.qs.getQP("start") || 0); // should be 0, 15, 30....
	}

	get isNewTab(): boolean {

		// When opening in a new tab, we strip off all query parameters. Therefore, by looking if there are any query parameters, we can know if we are in a new tab, and hide the "new tab" button.

		return !location.search.length
	}

	async prevDoc(): Promise<void> {

		const l = `pageDetails prevDoc() - `

		let st13 = ''

		// if previous record exitst in resultset
		if (this.ss.searchResult.hasPrevRecord()) {
			// not changing the '_' params, so we need to change something else
			this.qs.setQP('i', +this.qs.getQP('i') - 1)

			// set cursor to previous doc
			st13 = this.ss.searchResult.getPrevRecord()
		}

		// if not, got to previous page
		else if (this.ss.searchResult.hasPrevPage()) {
			// set the start param to go to prev page
			this.qs.setQP("start", this.currentPage - +(this.ps.getPref('rows')))
			this.qs.setQP('i', +this.ps.getPref('rows') - 1)

			let payload: any = this.qs.toPayload()
			let cid: string = this.qs.getQP('_')

			// relaunch the query without cid param
			let results = await lastValueFrom(this.ss.search(payload));

			results = decrypt(results); // Sends back the data as is if can't decrypt

			// reset the searchResult
			this.ss.searchResult.init(results, cid)

			// set cursor to last document
			st13 = this.ss.searchResult.getLastRecord()
		}

		if (st13) {
			const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `design/${st13}`, caller: l })

			return this.qs.queryParamsObjectToUrl(route, true)
		}
	}

	async nextDoc(): Promise<void> {
		const l = `pageDetails nextDoc() - `

		let st13: string = null

		if (this.ss.searchResult.hasNextRecord()) {

			// not changing the '_' params, so we need to change something else
			this.qs.setQP('i', +this.qs.getQP('i') + 1)

			st13 = this.ss.searchResult.getNextRecord()

		} else if (this.ss.searchResult.hasNextPage()) {

			// set the start param to go to next page
			this.qs.setQP("start", this.currentPage + +(this.ps.getPref('rows')))
			this.qs.setQP('i', 0)

			let payload: any = this.qs.toPayload()
			let cid: string = this.qs.getQP('_')

			// relaunch the query without cid param
			try {
				let results = await lastValueFrom(this.ss.search(payload));

				results = decrypt(results); // Sends back the data as is if can't decrypt

				// reset the searchResult
				this.ss.searchResult.init(results, cid)

				// set cursor to first document
				st13 = this.ss.searchResult.getFirstRecord()
			}
			catch (err) {
				
				this.ms.setSearchError(err)
			}
		}

		if (st13) {
			const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `design/${st13}`, caller: l })
			return this.qs.queryParamsObjectToUrl(route, true)
		}
	}

	get_compact(label:string, malcontent:any): any{
		console.log("---- compact ----", malcontent);
		if (malcontent.compact){
			return {"label": label, "content": malcontent.compact}
		}
		return {"label": label, "content": malcontent.content}
	}

	get_first_date(design:any, record:GDDDocument): any{
		console.log("---- first_date ----", design);
		
		return {"label": design.earliest_label, "content":  design.earliest}
	}


	get_compact_date(design:any, record:GDDDocument): any{
		console.log("---- compact date -------", design);
		
		return {"label": design.earliest_label, "content":  design.earliest}
	}

	get_compact_text(design:any): any{
		if (design.designDescription){
			return {"label": "Design Description", "content":  design.designDescription.content}
		}
		if (design.productIndication){
			return {"label": "Product Indication", "content":  design.productIndication.content}
		}
	}

	// Doc2Field is too much of a pain
	__locarnoClassLabel(code: string, version?: string) : string{		
		const locTranslationKey = version? `locarno.${version}_${code}`: `locarno.${code}`
		let locLabel = this.ms.translate(locTranslationKey);
		if (locLabel === locTranslationKey){
			locLabel = this.ms.translate(`locarno.${code}`);
		}
		// console.log("Should translate locarno class from key : ", locTranslationKey, " or ", `locarno.${code}`, "got : ", locLabel );		
		return locLabel;
	}

	// Doc2Field is too much of a pain
	__inidToLabel(inid: string): string {

		// first try to find the translation specific to the office
		const inidTranslationKey = `inids_${this.doc.registrationOfficeCode}.${inid}`
		let inidLabel: string = this.ms.translate(inidTranslationKey)

		// translation returns value in ? => no translation found
		// failed to find office specific => go for default
		if (inidLabel === inidTranslationKey) {
			inidLabel = this.ms.translate(`inids.${inid}`)
		}

		return inidLabel
	}

	toggleExpandDesigns(){
		this.expandDesigns = !this.expandDesigns
	}

	// -------------------------------
	// links to office where available
	// -------------------------------
	_link_AU(doc): string[] {
		return [`https://search.ipaustralia.gov.au/trademarks/search/view/${this.doc.applicationNumber}/`,
			`https://search.ipaustralia.gov.au/trademarks/search/view/${this.doc.applicationNumber}/`]
	}
	_link_CA(doc): string[] {
		let [id, key] = this.doc.applicationNumber.split('-') // 1234 or 1234-01
		return [`http://www.cipo.ic.gc.ca/app/opic-cipo/id/dsgnDtls.do?appNm=${id}&lang=eng&status=OK`,
			`http://www.cipo.ic.gc.ca/app/opic-cipo/id/dsgnDtls.do?appNm=${id}&lang=eng&status=OK`]
	}

	_link_CH(doc): string[] {
		return [`hhttps://www.swissreg.ch/srclient/de/des/${doc.applicationNumber}`,
			`hhttps://www.swissreg.ch/srclient/de/des/${doc.applicationNumber}`]
	}
	_link_CN(doc): string[] {
		return ['',`http://epub.sipo.gov.cn/`]
	}
	_link_CU(doc): string[] {
		let id = this.doc.applicationNumber.split('/').join('') // 1234 or 1234-01
		return [`https://wiposearch.ocpi.cu/wopublish-search/public/detail/designs?id=${id}`,
			`https://wiposearch.ocpi.cu/wopublish-search/public/detail/designs?id=${id}`]
	}

	_link_DE(doc): string[] {
		let [id, key] = this.doc.applicationNumber.split('.') // 1234 or 1234-01
		while (key.length < 4) id = "0" + id;
		return [`https://register.dpma.de/DPMAregister/gsm/register?DNR=${id}-${key}`,
			`https://register.dpma.de/DPMAregister/gsm/register?DNR=${id}-${key}`]
	}
	_link_EM(doc): string[] {
		return [`https://www.tmdn.org/tmdsview-web/welcome#/dsview/detail/${doc.st13}`,
			`https://www.tmdn.org/tmdsview-web/welcome#/dsview/detail/${doc.st13}`]
	}

	_link_ES(doc): string[] {
		return ['',`http://consultas2.oepm.es/ceo/jsp/busqueda/busqRapida.xhtml`]
	}
	_link_FR(doc): string[] {
		return [`https://data.inpi.fr/dessins_modeles/FR${this.doc.applicationNumber}-${this.doc.desgNumber}`,
			`https://data.inpi.fr/dessins_modeles/FR${this.doc.applicationNumber}-${this.doc.desgNumber}`]
	}
	_link_IL(doc): string[] {
		return [`hhttps://designsearch.justice.gov.il/DesignFile/ViewReportPDF?submissionNo=${this.doc.applicationNumber}`,
			`hhttps://designsearch.justice.gov.il/DesignFile/ViewReportPDF?submissionNo=${this.doc.applicationNumber}`]
	}

	_link_KR(doc): string[] {
		return [`http://link.kipris.or.kr/link/main/Biblio.jsp?reg_key=qSyPqyw0POQy30NoItNS5Q%3D%3D&APPLNO=${this.doc.applicationNumber}`,
			`http://link.kipris.or.kr/link/main/Biblio.jsp?reg_key=qSyPqyw0POQy30NoItNS5Q%3D%3D&APPLNO=${this.doc.applicationNumber}`]
	}
	_link_KZ(doc): string[] {
		return [`https://gosreestr.kazpatent.kz/Trademark/Details?docNumber=${this.doc.extra.id}`,
				`https://gosreestr.kazpatent.kz/Trademark/Details?docNumber=${this.doc.extra.id}`]
	}
	_link_MD(doc): string[] {
		return [`http://www.db.agepi.md/DMI/Details?id=${this.doc.applicationNumber}`,
				`http://www.db.agepi.md/DMI/Details?id=${this.doc.applicationNumber}`]
	}

	_link_NZ(doc): string[] {
		return [`https://app.iponz.govt.nz/app/Extra/Default.aspx?op=EXTRA_ds_qbe&fcoOp=EXTRA__Default&directAccess=true`,
			`https://app.iponz.govt.nz/app/Extra/Default.aspx?op=EXTRA_ds_qbe&fcoOp=EXTRA__Default&directAccess=true`]
	}
	
	_link_WO(doc): string[] {
		if(this.doc.gbdStatus != 'Pending') {
			return [`https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${this.doc.registrationNumber}`,
					`https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${this.doc.registrationNumber}`]
		}
		return ['', 'https://www3.wipo.int/madrid/monitor']

	}
}
