

// ANGULAR CORE

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {instanceType} from './commons/utils'

// ENVIRONMENT

import { environment } from "../environments/environment"

// CACHE BUSTING

import cacheBusting from '../../assets-cache-busting.json';

// TRANSLATION

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// https://github.com/ngx-translate/core
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/'+ instanceType() + '/i18n/' , '.json?_=' + cacheBusting['i18n']);
}


// AUTH

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
export function oAuthStorageFactory(): OAuthStorage { return localStorage; } // use localStorage instead of sessionStorage


// PLUGINS

import { AngularDraggableModule } from 'angular2-draggable';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxEchartsModule } from 'ngx-echarts';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import 'altcha'

// MAIN TABS


import { PageAdvancedSearchComponent } from './commons/pages/page-advancedSearch/page-advancedSearch.component';
import { CompFacetsExplore } from './commons/components/comp-facets-explore/comp-facets-explore.component';
import { VisuComponent } from './commons/components/comp-visu/visu.component';
import { PageVisuComponent } from './commons/pages/page-visu/page-visu.component';
import { PageReportsComponent } from './commons/pages/page-reports/page-reports.component';
import { PageCoverageComponent } from './commons/pages/page-coverage/page-coverage.component';


// COMMON COMPONENTS

import { CompFacetsSearch } from './commons/components/comp-facets-search/facets-search.component';
import { ImagedropComponent } from './commons/components/comp-imagedrop/imagedrop.component';
import { ImageEditorComponent } from './commons/components/comp-imageeditor/image-editor.component';
import { QueryParams2humanComponent } from './commons/components/comp-queryparams2human/queryParams2human.component';
import { CompResultsInfo } from './commons/components/comp-results-info/comp-results-info.component';
import { CompSuggestMultiselectComponent } from './commons/components/comp-suggest-multiselect/comp-suggest-multiselect.component';
import { ChatbotComponent } from './commons/components/comp-chatbot/chatbot.component';
import { RecentSearchesComponent } from './commons/components/comp-recent-searches/recent-searches.component';

// PAGES COMPONENTS

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HOCSearchComponent } from './commons/hoc-search/hoc-search.component';
import { FieldComponent } from './commons/components/comp-doc-field/doc-field.component';
import { AddressListComponent } from './commons/components/comp-address-list/address-list.component';
import { FieldCompactComponent  } from './commons/components/comp-doc-field-compact/doc-field-compact.component';
import { FieldArrayComponent  } from './commons/components/comp-doc-field-array/doc-field-array.component';

import { PageRedirectComponent } from './commons/pages/page-redirect/page-redirect.component';
import { PageExportComponent } from './commons/pages/page-export/page-export.component';
import { PageHelpComponent } from './commons/pages/page-help/page-help.component';
import { PageNewsComponent } from './commons/pages/page-news/page-news.component';
import { PageNotFoundComponent } from './commons/pages/page-notfound/page-notfound.component';
import { PaginatorComponent } from './commons/components/comp-paginator/paginator.component';
import { PageResultsComponent } from './commons/pages/page-results/page-results.component';
import { ResultsHolderComponent } from './commons/components/comp-results-holder/results-holder.component';
import { SearchResultsMenuComponent } from './commons/components/comp-search-results-menu/search-results-menu.component';
import { BrickComponent } from './commons/components/comp-brick/brick.component';
import { CompMenuBar } from './commons/components/comp-nav-bar/comp-nav-bar.component';
import { ImageSliderComponent } from './commons/components/comp-imageslider/image-slider.component';


// GBD/ GDD


import { GBDPageSimilarNameComponent } from './gbd/pages/1-byName/page-similarname.component';
import { GDDPageSimilarNameComponent } from './gdd/pages/1-byName/page-similarname.component';


import { GBDPageSimilarLogoComponent } from './gbd/pages/2-byLogo/page-similarlogo.component';
import { GDDPageSimilarLogoComponent } from './gdd/pages/2-byLogo/page-similarlogo.component';


import { GBDResultsListComponent } from './gbd/components/comp-results-list/results-list.component';
import { GDDResultsListComponent } from './gdd/components/comp-results-list/results-list.component';

import { GBDExportComponent } from './gbd/components/comp-export/export.component';
import { GDDExportComponent } from './gdd/components/comp-export/export.component';

import { GBDPageDetailsComponent } from './gbd/pages/page-details/page-details.component';
import { GDDPageDetailsComponent } from './gdd/pages/page-details/page-details.component';



// HTTP INTERCEPTOR (mainly for Giacomo... Not used?)

//import { AwsInterceptInterceptor } from './_services/aws-intercept.interceptor';



// W-ANGULAR AND PRIMENG

import {
	FFacetModule,
	WActionBarModule,
	WBlockUiModule,
	WButtonGroupModule,
	WButtonModule,
	WDrawerModule,
	WEditPanelModule,
	WFieldModule,
	WFilterModule,
	WInputAutocompleteModule,
	WInputBadgeModule,
	WInputCheckboxManyModule,
	WInputCheckboxOneModule,
	WInputRadioModule,
	WInputTextModule,
	WNotificationBarModule,
	WPageHeaderModule,
	WPageSectionModule,
	WSectionModule,
	WSelectManyModule,
	WSelectOneModule,
	WSidebarModule,
	WSlotModule,
	WStepModule,
	WStickyBarModule,
	WTableCommandsModule,
	WValidationPanelModule,
	WViewPanelModule,
	WDialogModule,
} from '@wipo/w-angular';

import { DataViewModule } from 'primeng/dataview';
import { WInputDateModule } from '@wipo/w-angular/primeng';
import { TableSortPipe } from './commons/_pipes/table-sort.pipe';
import { AddHeaderInterceptor } from './commons/_services/http-client';

@NgModule({
	declarations: [
		AppComponent,
		CompFacetsExplore,
		CompFacetsSearch,
		CompMenuBar,
		CompResultsInfo,
		GBDExportComponent,
		GDDExportComponent,
		AddressListComponent,
		FieldArrayComponent,
		FieldCompactComponent,
		FieldComponent,
		VisuComponent,
		HOCSearchComponent,
		ImageEditorComponent,
		ImagedropComponent,
		PaginatorComponent,
		PageCoverageComponent,
		PageNewsComponent,
		PageRedirectComponent,
		GBDPageDetailsComponent,
		GDDPageDetailsComponent,
		PageExportComponent,
		PageVisuComponent,
		PageHelpComponent,
		PageNewsComponent,
		PageNotFoundComponent,
		PageAdvancedSearchComponent,
		PageReportsComponent,
		PageResultsComponent,
		GBDPageSimilarLogoComponent,
		GDDPageSimilarLogoComponent,
		GBDPageSimilarNameComponent,
		GDDPageSimilarNameComponent,
		QueryParams2humanComponent,
		ChatbotComponent,
		ResultsHolderComponent,
		RecentSearchesComponent,
		GBDResultsListComponent,
		GDDResultsListComponent,
		SearchResultsMenuComponent,
		ImageSliderComponent,
		BrickComponent,
		CompSuggestMultiselectComponent,
		TableSortPipe,
		
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		AngularDraggableModule,

		FFacetModule,
		WActionBarModule,
		WBlockUiModule,
		WButtonGroupModule,
		WButtonModule,
		WDrawerModule,
		WEditPanelModule,
		WFieldModule,
		WFilterModule,
		WInputAutocompleteModule,
		WInputBadgeModule,
		WInputCheckboxManyModule,
		WInputCheckboxOneModule,
		WInputDateModule,
		WInputRadioModule,
		WInputTextModule,
		WNotificationBarModule,
		WPageHeaderModule,
		WPageSectionModule,
		WSectionModule,
		WSelectManyModule,
		WSelectOneModule,
		WSidebarModule,
		WSlotModule,
		WStepModule,
		WStickyBarModule,
		WTableCommandsModule,
		WValidationPanelModule,
		WViewPanelModule,
		WDialogModule,
		ClickOutsideModule,
		DataViewModule,

		// https://github.com/ngx-translate/core
		TranslateModule.forRoot({

			defaultLanguage: 'en',

			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),

		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),

		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: [
					environment[instanceType()].backendUrl
				],
				sendAccessToken: true
			}
		}),
		TippyModule.forRoot({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: tooltipVariation,
				popper: popperVariation,
			}
		})
	],
	providers: [
		
		/*{
			provide: HTTP_INTERCEPTORS,
			useClass: AwsInterceptInterceptor,
			multi: true // false completely breaks the app, for some reason
		},*/
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AddHeaderInterceptor,
			multi: true,
		  },
		  
		{ provide: OAuthStorage, useFactory: oAuthStorageFactory }

	
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
