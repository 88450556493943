import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MechanicsService } from '../../_services/mechanics.service';
import { PreferencesService } from '../..//_services/preferences.service';
import { QueryParamsService } from '../../_services/queryParams.service';
import { SearchService } from '../../_services/search.service';



interface Handles {
	center: Array<number>, // used only for the ellipse
	outline: Array<Array<number>>
}

@Component({
	selector: 'image-slider',
	templateUrl: './image-slider.component.html',
	styleUrls: ['./image-slider.component.css']
})

export class ImageSliderComponent {

	
	@Input() element: any;
	@Input() idx: any;
	@Input() parent: any;
	@Output() selectedIndexEvent = new EventEmitter<number[]>();

	selectedIndex = 0;
  
	constructor(
		public ms: MechanicsService,
		public ss: SearchService,
		public ps: PreferencesService,
		private qs: QueryParamsService) {
		this.selectedIndex = 0;
	}

	doCallBack():void{
		this.parent.details(this.element, this.idx)
	}
	

	selected(x:number) {
		if (this.element){
			this.selectedIndex = (this.selectedIndex + x) % this.element.logoSmall.length
			if(this.selectedIndex == -1){
				this.selectedIndex = this.element.logoSmall.length -1
			}
			this.selectedIndexEvent.emit([this.selectedIndex, this.idx]);
		}
	}

	
	
}
